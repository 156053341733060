import * as axios from 'axios';
import React, {useEffect, useState, useRef } from 'react'
import {
    Link, useLocation, useNavigate
  } from "react-router-dom";

import Header from "../../../include/SiteAdminHeader";
import FooterInfo from "../../../include/SiteAdminFooterInfo";
import Footer from "../../../include/SiteAdminFooter";
import Paging from "../../../include/Paging";
import queryString from 'query-string';
import PropTypes from 'prop-types';



function SiteAdminManagementMod() {

    const navigate = useNavigate();
    const location = useLocation();

    const qs = queryString.parse(window.location.search);
    const sIdx = qs.idx ? qs.idx : '';

    
    if(sIdx === ''){
        alert("잘못된 접근입니다.");
        navigate(-1);
    }
 
    const ref_corp_addr2 = useRef(null);
    const [corp_class, set_corp_class] = useState("");
    const [contract_status, set_contract_status] = useState("");
    const [login_able, set_login_able] = useState("");
    const [calculate, set_calculate] = useState("");
    const [bank, set_bank] = useState("");
    const [bankList, set_bankList] = useState([]);
    const [branch_name, set_branch_name] = useState("");
    const [user_id, set_user_id] = useState("");
    const [user_pw, set_user_pw] = useState("");
    const [user_email, set_user_email] = useState("");
    const [representative, set_representative] = useState("");
    const [manager, set_manager] = useState("");
    const [manager_phone, set_manager_phone] = useState("");
    const [corp_name, set_corp_name] = useState("");
    const [corp_nun, set_corp_nun] = useState("");
    const [corp_post, set_corp_post] = useState("");
    const [corp_addr, set_corp_addr] = useState("");
    const [corp_addr2, set_corp_addr2] = useState("");
    const [corp_status, set_corp_status] = useState("");
    const [corp_sector, set_corp_sector] = useState("");
    const [commission, set_commission] = useState("");
    const [account_num, set_account_num] = useState("");
    const [account_holder, set_account_holder] = useState("");
    const [id_check, set_id_check] = useState("");
    const [site_logo_file, set_site_logo_file] = useState({ site_logo_file_r: "", site_logo_file: "" });    
    const [up_site_logo_file, set_up_site_logo_file] = useState(null);
    


    useEffect(() => {

        //console.log(sIdx);

        let bodyPage = {
            idx: sIdx
        }
        //console.log("bodyPage : " + bodyPage);

        axios.post('/api/management/mod', bodyPage).then(function(res){
            console.log(res.data.map);
            res.data.map((v, index) => {
                set_branch_name(v.branch_name);
                set_user_id(v.user_id);
                set_corp_class(v.corp_class);
                set_representative(v.representative);
                set_manager(v.manager);
                set_manager_phone(v.manager_phone);
                set_user_email(v.user_email);
                set_corp_name(v.corp_name);
                set_corp_nun(v.corp_nun);
                set_corp_post(v.corp_post);
                set_corp_addr(v.corp_addr);
                set_corp_addr2(v.corp_addr2);
                set_corp_status(v.corp_status);
                set_corp_sector(v.corp_sector);
                set_contract_status(v.contract_status);
                set_login_able(v.login_able);
                set_commission(v.commission);
                set_calculate(v.calculate);
                set_bank(v.bank);
                set_account_num(v.account_num);
                set_account_holder(v.account_holder);                   
                set_site_logo_file({
                    site_logo_file_r: (v.site_logo_file_r == null ? '' : v.site_logo_file_r),
                    site_logo_file: (v.site_logo_file == null ? '' : v.site_logo_file),
                }); 
            })
            
        }) 

        // 계약상태 기본값 대입
        set_contract_status('Y');

        // 로그인
        set_login_able('Y');

        // 제공 수수료
        set_calculate('D')

        // 은행 리스트
        getBankList();

        return () => {
          
        };
    }, []);
  


    const on_corp_class = (event) => {
        set_corp_class(event.currentTarget.value)
    }

    const on_contract_status = (event) => {
        set_contract_status(event.currentTarget.value)
    }

    const on_login_able = (event) => {
        set_login_able(event.currentTarget.value)
    }
    
    const on_calculate = (event) => {
        set_calculate(event.currentTarget.value)
    }
    
    const on_bank = (event) => {
        set_bank(event.currentTarget.value)
    }

    const on_branch_name_handler = (event) => {
        set_branch_name(event.currentTarget.value)
    }

    const on_user_id_handler = (event) => {
        set_user_id(event.currentTarget.value)
        set_id_check('');
    }

    const on_user_pw_handler = (event) => {
        set_user_pw(event.currentTarget.value)
    }
    const on_user_email_handler = (event) => {
        set_user_email(event.currentTarget.value)        
    }
    const on_representative_handler = (event) => {
        set_representative(event.currentTarget.value)
    }

    const on_manager_handler = (event) => {
        set_manager(event.currentTarget.value)
    }

    const on_manager_phone_handler = (event) => {
        set_manager_phone(event.currentTarget.value)
    }

    const on_corp_name_handler = (event) => {
        set_corp_name(event.currentTarget.value)
    }

    const on_corp_nun_handler = (event) => {
        set_corp_nun(event.currentTarget.value)
    }
    const on_corp_post_handler = (event) => {
        set_corp_post(event.currentTarget.value)
    }
    const on_corp_addr_handler = (event) => {
        set_corp_addr(event.currentTarget.value)
    }
    const on_corp_addr2_handler = (event) => {
        set_corp_addr2(event.currentTarget.value)
    }
    const on_corp_status_handler = (event) => {
        set_corp_status(event.currentTarget.value)
    }

    const on_corp_sector_handler = (event) => {
        set_corp_sector(event.currentTarget.value)
    }

    const on_commission_handler = (event) => {
        set_commission(event.currentTarget.value)
    }

    const on_account_num_handler = (event) => {
        set_account_num(event.currentTarget.value)
    }

    const on_account_holder_handler = (event) => {
        set_account_holder(event.currentTarget.value)
    }
  
    const on_up_site_logo_file_handler = (event) => {
        set_up_site_logo_file(event.target.files[0]);        
    }

    const on_id_check = (event) => {
        if(user_id === "")
        {
            alert("아이디를 입력해주세요.");
            return false;
        }
        let body = {
            user_id: user_id,
        }

        axios.post('/api/user/login_chk', body)
        .then(function(response){
            if(response.data === "NO"){
                
                alert("이미 존재하는 아이디입니다.");
                set_id_check('');
                return false;

            }else{
                alert("사용 가능한 아이디입니다.");
                set_id_check('Y');
                return false;
                
            }
            
        },[])
        //set_id_check('Y');

    }
    const onSubmitHandler = (event) => {
        event.preventDefault();

        let body = {
            idx: qs.idx,
            branch_name: branch_name,
            user_pw: user_pw,
            corp_class: corp_class,
            representative: representative,
            manager: manager,
            manager_phone: manager_phone,
            user_email: user_email,
            corp_name: corp_name,
            corp_nun: corp_nun,
            corp_post: corp_post,
            corp_addr: corp_addr,
            corp_addr2: corp_addr2,
            corp_status: corp_status,
            corp_sector: corp_sector,
            contract_status: contract_status,
            login_able: login_able,
            commission: commission,
            calculate: calculate,
            bank: bank,
            account_num: account_num,
            account_holder: account_holder
        }

        axios.post('/api/management/update', body)
        .then(function(response){
                        
            if(response.data === "ModOk"){    
                
                //파일 업로드
                fileUpload(event, user_id);

                window.location.reload();
            
            }else{
                alert("시스템 에러");
                
            }
            
            //console.log(response.data);
            
        },[])        
    }

    function getBankList(){
        axios.post('/api/management/bank_list').then(function(res){
            set_bankList(res.data);
        })
    }

    /********************
     * 파일업로드
     * @param {*} e 
     * @param {*} user_id 
     ********************/
    const fileUpload = async(e, user_id) => {
        e.preventDefault();            
        const formData = new FormData();
        formData.append('user_id', user_id);      
        formData.append('site_logo_file', up_site_logo_file);
        axios.post('/api/fileUpload/storeFileUpload', formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            }).then(function(res){                                
                console.log('res : ', res);
            },[]
        );            
    };

    /******************************
     * 다음 우편번호 서비스 호출 함수
     ******************************/
    const handlePostcode = () => {

        new window.daum.Postcode({
            oncomplete: (data) => {
                set_corp_post(data.zonecode); // 우편번호
                set_corp_addr(data.address); // 전체 주소  
                ref_corp_addr2.current.focus();  
            },
            popupName: "postcodePopup", // 팝업 이름 설정
          }).open({
            // left: (window.innerWidth / 2) - 200, // 중앙에 위치
            // top: (window.innerHeight / 2) - 300,
            // width: 100,
            // height: 600,
          });        
    };   

    return (
        <>
        <div id="wrap">
            <Header/>

        
            <div id="container" data-menu-page="management_list">  
                <form onSubmit={onSubmitHandler}>
                    <input type="hidden" name="id_check" id="id_check" value={id_check} />
                    <h3 className="page_ttl">지사수정</h3>
                    
                    
                    <div className="form_item">
                        <h3 className="label">* 기본정보</h3>

                        <div className="top_table">
                            <table>
                                <colgroup>
                                    <col width="200px" />
                                    <col width="*" />
                                    <col width="200px" />
                                    <col width="*" />
                                </colgroup>

                                <tbody>
                                    <tr>
                                        <th>지사명 <span className="red">＊</span></th>
                                        <td colSpan="3">
                                            <div className="in_box table_cont_1">
                                                <input type="text" name="branch_name" id="branch_name" value={branch_name} onChange={on_branch_name_handler} required />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>로그인아이디 <span className="red">＊</span></th>
                                        <td>
                                            <div className="flex">
                                                <div className="in_box">
                                                    {user_id}
                                                </div>
                                                
                                            </div>
                                        </td>
                                        <th>비밀번호 <span className="red">＊</span></th>
                                        <td>
                                            <div className="in_box">
                                                <input type="password" name="user_pw" id="user_pw" value={user_pw} onChange={on_user_pw_handler}  />
                                                <p>비밀번호 변경 시에만 입력하세요.</p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>사업자 구분 <span className="red">＊</span></th>
                                        <td>
                                            <div className="sel_box">
                                                <select name="corp_class" id="corp_class" required onChange={on_corp_class}  value={corp_class} >
                                                    <option value="">선택</option>
                                                    <option value="corp">법인사업자</option>
                                                    <option value="solo">개인사업자</option>
                                                    <option value="non">비사업자</option>
                                                </select>
                                            </div>
                                        </td>
                                        <th>대표자 <span className="red">＊</span></th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" name="representative" id="representative" value={representative} onChange={on_representative_handler} required />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>담당자 성명 <span className="red">＊</span></th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" name="manager" id="manager" value={manager} onChange={on_manager_handler} required />
                                            </div>
                                        </td>
                                        <th>담당자 연락처 <span className="red">＊</span></th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" name="manager_phone" id="manager_phone" value={manager_phone} onChange={on_manager_phone_handler} required />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>이메일</th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" id="user_email" value={user_email} onChange={on_user_email_handler} />
                                            </div>
                                        </td>
                                        <th></th>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                    </div>


                    <div className="form_item">
                        <h3 className="label">* 사업자 정보 (사업자의 경우 등록, 세금계산서 발행을 위한 정보 등록)</h3>

                        <div className="top_table">
                            <table>
                                <colgroup>
                                    <col width="200px" />
                                    <col width="*" />
                                    <col width="200px" />
                                    <col width="*" />
                                </colgroup>

                                <tbody>
                                    <tr>
                                        <th>사업자명   <span className="red">＊</span></th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" name="corp_name" id="corp_name" value={corp_name} onChange={on_corp_name_handler} required />
                                            </div>
                                        </td>
                                        <th>사업자등록번호   <span className="red">＊</span></th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" name="corp_nun" id="corp_nun" value={corp_nun} onChange={on_corp_nun_handler} required />
                                            </div>
                                        </td>
                                    </tr>                                   
                                    <tr>
                                        <th>업태  </th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" name="corp_status" id="corp_status" value={corp_status} onChange={on_corp_status_handler}  required />
                                            </div>
                                        </td>
                                        <th>종목  </th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" name="corp_sector" id="corp_sector" value={corp_sector} onChange={on_corp_sector_handler} required />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                    <th>사업자주소<span className="red">＊</span></th>
                                    <td colSpan="3">
                                        <div className="in_box">   
                                            <div style={{marginBottom: '5px', display: 'flex'}}>
                                                <input type="text" name="post" id="corp_post" value={corp_post} style={{width:'200px'}} onChange={on_corp_post_handler} readOnly={true}  />
                                                <button type="button" className="btn_st_01" style={{margin:'8px 0 0 8px'}} onClick={handlePostcode}>우편번호검색</button>                                              
                                            </div>
                                            <div style={{marginBottom: '5px'}}><input type="text" id="corp_addr" value={corp_addr} onChange={on_corp_addr_handler} readOnly={true} /></div>
                                            <div style={{marginBottom: '5px'}}><input type="text" id="corp_addr2" value={corp_addr2} ref={ref_corp_addr2} onChange={on_corp_addr2_handler}  /></div>                                            
                                        </div>                                                                                                         
                                    </td>
                                </tr>

                                </tbody>
                            </table>

                        </div>

                    </div>

                    <div className="form_item">
                        <h3 className="label">* 정산조건</h3>

                        <div className="top_table">
                            <table>
                                <colgroup>
                                    <col width="200px" />
                                    <col width="*" />
                                    <col width="200px" />
                                    <col width="*" />
                                </colgroup>

                                <tbody>
                                    <tr>
                                        <th>계약상태   <span className="red">＊</span></th>
                                        <td>
                                            <div className="sel_box">
                                                <select name="contract_status" id="contract_status" value={contract_status} required onChange={on_contract_status}>
                                                    <option value="Y" >정상</option>
                                                    <option value="F">해지</option>
                                                    <option value="A">신청</option>
                                                    <option value="W">미납</option>
                                                </select>
                                            </div>
                                        </td>
                                        <th>로그인   <span className="red">＊</span></th>
                                        <td>
                                            <div className='sel_box'>
                                                <select name="login_able" id="login_able" required onChange={on_login_able} value={login_able}>
                                                    <option value="Y">허용</option>
                                                    <option value="N">불가</option>
                                                </select>
                                            </div>
                                       
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>제공 수수료 <span className="red">＊</span></th>
                                        <td colSpan ="3">
                                            <div className="flex">
                                                <div className="in_box" style={{width:"200px",marginRight:"10px"}}>
                                                    <input type="text" name="commission" id="commission" value={commission} onChange={on_commission_handler} required />
                                                </div>
                                                %
                                                <span className="red" style={{marginLeft:'10px'}}>(VAT 포함가) 제공되는 수수료</span>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>제공 수수료 <span className="red">＊</span></th>
                                        <td colSpan ="3">
                                            <div className="flex">
                                                <div className="radio_box">
                                                    <input type="radio" name="calculate" id="calculate_01" value="D" checked={calculate==='D'} onChange={on_calculate} />
                                                    <label htmlFor="calculate_01">일정산 (가맹점정산일 기준)</label>
                                                </div>
                                                <div className="radio_box">
                                                    <input type="radio" name="calculate" id="calculate_02" value="W"  checked={calculate==='W'} onChange={on_calculate} />
                                                    <label htmlFor="calculate_02">주정산 (가맹점정산일 다음주 첫 영업일)</label>
                                                </div>
                                                <div className="radio_box">
                                                    <input type="radio" name="calculate" id="calculate_03" value="M"  checked={calculate==='M'} onChange={on_calculate} />
                                                    <label htmlFor="calculate_03">월정산 (가맹점정산일 다음달 첫 영업일)</label>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>

                        </div>

                    </div>


                    <div className="form_item">
                        <h3 className="label">* 정산계좌정보</h3>

                        <div className="top_table">
                            <table>
                            <colgroup>
                                    <col width="200px" />
                                    <col width="*" />
                                    <col width="200px" />
                                    <col width="*" />
                                </colgroup>

                                <tbody>
                                    <tr>
                                        <th>입금은행     <span className="red">＊</span></th>
                                        <td colSpan="3">
                                            <div className="sel_box table_cont_1">
                                                <select name="bank" id="bank" required onChange={on_bank} value={bank}>
                                                    <option value="">선택</option>
                                                    {
                                                        bankList.map((v, index)=> {
                                                            return (
                                                                <option value={v.bank_code} key={index}>{v.bank_name}</option>
                                                            )

                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>계좌번호   <span className="red">＊</span></th>
                                        <td >
                                            <div className="in_box" >
                                                <input type="text" name="account_num" id="account_num" value={account_num} onChange={on_account_num_handler} required />
                                            </div>
                                        </td>
                                        <th>예금주   <span className="red">＊</span></th>
                                        <td >
                                            <div className="in_box" >
                                                <input type="text" name="account_holder" id="account_holder" value={account_holder} onChange={on_account_holder_handler} required />
                                            </div>
                                        </td>
                                    </tr>

                    

                                </tbody>
                            </table>

                        </div>

                    </div>

                    <div className="form_item">
                        <h3 className="label">*  로고 설정 </h3>
                        <div className="top_table">
                            <table>
                                <colgroup>
                                    <col width="200px" />
                                    <col width="*" />
                                    <col width="200px" />
                                    <col width="*" />
                                </colgroup>

                                <tbody>
                                    <tr>                                       
                                        <th>관리자 로고</th>
                                        <td colSpan={3}>
                                            <div className="in_box">
                                                <input type="file"  id="site_logo_File" onChange={on_up_site_logo_file_handler} />                                                                                       
                                                {site_logo_file.site_logo_file == '' ? '' : <img src={'/uploads/'+ site_logo_file.site_logo_file} />}
                                            </div>
                                        </td>
                                    </tr>                                                            
                                </tbody>
                            </table>
                        </div>
                    </div>     
                    
                    <div className="top_table_btn">
                        <button type="button" className=" btn_st_01" onClick={  () => navigate(-1) }>목록</button>
                        <button type="submit" className="blue btn_st_01">등록</button>
                    </div>
                </form>
            </div>
            
        </div>

        <Footer/>
        </>
    )


}
export default SiteAdminManagementMod