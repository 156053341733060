/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import * as axios from 'axios';
import React, { useEffect, useState } from 'react'
import { MaskName, MaskCardNo, PgCardName } from "../../views/include/ConfigSet";


function SalesSlipPopupModal({ popupClose, payment_idx, pay_type }) {

    const [item, setItem] = useState({});

    /* ***************
     * 결제 정보 출력
     * ***************/
    const paymentInfo = () => {


        if (!pay_type) {
            pay_type = 0;
        }

        axios.post('/api/management/pay/transaction_history_view', { idx: payment_idx, pay_type: pay_type }).then(function (res) {

            console.log('res : ', res);

            res.data.map((item, index) => {

                //console.log(item);

                let v_amount = (item.amount < 0 ? item.amount * -1 : item.amount);

                setItem({
                    /*결제 정보*/
                    username: MaskName(item.username),                                          // 구매자
                    productname: item.productname,                                              // 상품명
                    cardno: MaskCardNo(item.cardno),                                            // 카드번호
                    cardName: PgCardName(item.pg_group, item.cardcode),                         // 카드사코드
                    quota: (item.quota == '00' ? '일시불' : item.quota + '개월'),               // 할부개월
                    authno: item.authno,                                                        // 카드승인번호
                    authdate: item.authdate,                                                    // 카드승일날짜
                    /*결제 금액정보*/
                    amount: (v_amount).toLocaleString(),                                        // 결제금액
                    vat: Math.trunc(v_amount / 11).toLocaleString(),                            // 세액
                    supply_price: v_amount - Math.trunc(v_amount / 11).toLocaleString(),        // 공급가액"

                    /*공급자 정보*/
                    St_corp_name: item.St_corp_name,                                            // 가맹점 업체명
                    St_representative: item.St_representative,                                  // 가맹점 대표자
                    St_corp_nun: item.St_corp_nun,                                              // 가맹점 사업자번호
                    St_user_phone: item.St_user_phone,                                          // 가맹점 연락처
                    St_corp_post: item.St_corp_post,                                            // 가맹점 우편번호
                    St_corp_addr: item.St_corp_addr,                                            // 가맹점 주소
                    St_corp_addr2: item.St_corp_addr2,                                          // 가맹점 주소2

                    Adm_site_logo_file: item.Adm_site_logo_file,         
                    Adm_corp_addr: item.Adm_corp_addr,
                    Adm_corp_addr2: item.Adm_corp_addr2,
                    Adm_corp_url: item.Adm_corp_url,
                    Adm_corp_tel: item.Adm_corp_tel,
                    Adm_corp_nun: item.Adm_corp_nun,
                    Adm_corp_ceo: item.Adm_corp_ceo,
                    Adm_corp_name: item.Adm_corp_name,

                    Sugi_corp_name: item.Sugi_corp_name,
                    Sugi_corp_ceo: item.Sugi_corp_ceo,
                    Sugi_corp_nun: item.Sugi_corp_nun,
                    Sugi_corp_tel: item.Sugi_corp_tel,
                    Sugi_corp_addr: item.Sugi_corp_addr,
                    Sugi_corp_addr2: item.Sugi_corp_addr2,
                });

                /*상점 정보*/
                setItem((obj) => ({
                    ...obj,
                    p1: "",     // 상호
                    p2: "",     // 대표자
                    p3: "",     // 사업자등록번호
                    p4: "",     // 전화번호
                    p5: "",     // 주소
                    p6: ""      // URL
                }));

               
            });
            
        });
    }

    useEffect(() => {
        paymentInfo();
    }, [])

   

    return (
        <>
      
            <div className="popupArea_" id="popupArea_">
                <div className="popupContent_" id="popupContent_">
                    <div className="header_">
                        <div className="logo_">

                            {
                                (item.Adm_site_logo_file && (<img src={'/uploads/' + item.Adm_site_logo_file}></img>))
                            }

                   
                            {/*<svg xmlns="http://www.w3.org/2000/svg" width="126px" height="34px" viewBox="0 0 161 34"*/}
                            {/*    fill="none">*/}
                            {/*    <g clip-path="url(#clip0_3327_48912)">*/}
                            {/*        <path d="M57.5698 3.32945C56.0802 2.65783 54.295 2.32202 52.2112 2.32202H42.5359V27.4819H46.9043V19.0494H52.2112C54.295 19.0494 56.0802 18.7135 57.5698 18.0419C59.0594 17.3703 60.2075 16.4059 61.014 15.1517C61.8205 13.8974 62.2224 12.4078 62.2224 10.6828C62.2224 8.95785 61.8205 7.4711 61.014 6.21397C60.2075 4.95971 59.0594 3.9982 57.5698 3.32658V3.32945ZM56.3586 14.1299C55.3856 14.9479 53.9333 15.3555 52.0074 15.3555H46.9014V6.0188H52.0074C53.9333 6.0188 55.3828 6.42062 56.3586 7.22714C57.3316 8.03365 57.8195 9.18746 57.8195 10.6857C57.8195 12.1839 57.3316 13.3119 56.3586 14.1299Z"*/}
                            {/*            fill="#333333"></path>*/}
                            {/*        <path d="M78.0198 18.3806C78.0198 19.566 77.8131 20.5648 77.397 21.3713C76.9808 22.1778 76.4125 22.7834 75.6835 23.1852C74.9544 23.5871 74.0991 23.7908 73.1147 23.7908C71.7714 23.7908 70.7353 23.3919 70.0063 22.5997C69.2773 21.8047 68.9156 20.6021 68.9156 18.9891V9.31372H64.7166V19.5602C64.7166 21.3512 65.0409 22.8351 65.6895 24.0118C66.3382 25.1857 67.2452 26.0611 68.4105 26.6323C69.5757 27.2035 70.919 27.4905 72.443 27.4905C73.8322 27.4905 75.1094 27.1834 76.2718 26.5663C77.0468 26.1559 77.6926 25.622 78.2207 24.9705V27.4905H82.2188V9.31659H78.0198V18.3863V18.3806Z"*/}
                            {/*            fill="#333333"></path>*/}
                            {/*        <path d="M90.2381 2.32202H86.0391V27.4819H90.2381V2.32202Z" fill="#333333"></path>*/}
                            {/*        <path d="M98.2601 2.32202H94.061V27.4819H98.2601V2.32202Z" fill="#333333"></path>*/}
                            {/*        <path d="M118.248 10.9727C116.824 9.72988 114.815 9.10706 112.218 9.10706C110.783 9.10706 109.402 9.29075 108.068 9.661C106.736 10.0312 105.588 10.5852 104.623 11.3228L106.271 14.3796C106.942 13.8199 107.766 13.3836 108.739 13.0679C109.712 12.755 110.705 12.5972 111.713 12.5972C113.214 12.5972 114.333 12.9387 115.071 13.6218C115.808 14.3049 116.179 15.2636 116.179 16.4949V16.7647H111.543C109.729 16.7647 108.274 17 107.175 17.4707C106.079 17.9414 105.289 18.5786 104.807 19.3851C104.325 20.1916 104.084 21.0986 104.084 22.106C104.084 23.1135 104.356 24.0606 104.907 24.8786C105.456 25.6966 106.233 26.3338 107.241 26.793C108.248 27.2523 109.425 27.4819 110.768 27.4819C112.358 27.4819 113.673 27.1891 114.715 26.6093C115.432 26.2075 115.998 25.6909 116.411 25.0652V27.4819H120.375V16.7331C120.375 14.1356 119.663 12.2154 118.242 10.9727H118.248ZM114.416 23.7219C113.598 24.1927 112.674 24.428 111.644 24.428C110.613 24.428 109.729 24.2041 109.124 23.7564C108.518 23.3086 108.217 22.703 108.217 21.9424C108.217 21.2708 108.464 20.7054 108.954 20.2462C109.448 19.7869 110.41 19.5573 111.845 19.5573H116.179V21.6411C115.82 22.5595 115.231 23.2541 114.413 23.7248L114.416 23.7219Z"*/}
                            {/*            fill="#333333"></path>*/}
                            {/*        <path d="M137.082 9.31079L131.402 22.5796L125.759 9.31079H121.391L129.232 27.3067L128.916 28.0243C128.512 28.9198 128.075 29.5569 127.605 29.9387C127.134 30.3204 126.52 30.5098 125.756 30.5098L125.788 34.0057C126.795 34.0057 127.72 33.8478 128.561 33.535C129.402 33.2222 130.162 32.674 130.845 31.8904C131.528 31.1068 132.151 30.042 132.711 28.6988L141.109 9.31653H137.076L137.082 9.31079Z"*/}
                            {/*            fill="#333333"></path>*/}
                            {/*        <path d="M159.005 13.507C158.187 12.1408 157.056 11.0674 155.612 10.2838C154.168 9.50027 152.515 9.10706 150.658 9.10706C148.801 9.10706 147.188 9.50027 145.753 10.2838C144.318 11.0674 143.187 12.1494 142.36 13.5242C141.534 14.8991 141.118 16.4863 141.118 18.2772C141.118 20.0682 141.531 21.6267 142.36 23.0159C143.19 24.405 144.321 25.4957 145.753 26.2907C147.185 27.0858 148.821 27.4819 150.658 27.4819C152.495 27.4819 154.168 27.0829 155.612 26.2907C157.056 25.4957 158.187 24.405 159.005 23.0159C159.823 21.6267 160.23 20.0481 160.23 18.2772C160.23 16.5063 159.82 14.8732 159.005 13.507ZM155.308 21.268C154.849 22.1175 154.217 22.7691 153.411 23.2168C152.604 23.6645 151.686 23.8884 150.655 23.8884C149.625 23.8884 148.747 23.6645 147.952 23.2168C147.156 22.7691 146.525 22.1204 146.054 21.268C145.584 20.4155 145.348 19.4196 145.348 18.2772C145.348 17.1349 145.584 16.1103 146.054 15.2722C146.525 14.4341 147.156 13.7883 147.952 13.3406C148.747 12.8928 149.659 12.6689 150.69 12.6689C151.72 12.6689 152.604 12.8928 153.411 13.3406C154.217 13.7883 154.849 14.4312 155.308 15.2722C155.767 16.1131 155.997 17.1148 155.997 18.2772C155.997 19.4397 155.767 20.4155 155.308 21.268Z"*/}
                            {/*            fill="#333333"></path>*/}
                            {/*        <path d="M24.804 8.4383C24.8098 6.98025 24.8385 5.52221 24.8155 4.06416C24.7782 1.83979 22.9786 0.0373202 20.737 0.0258395C16.3916 0.00287819 12.4365 -0.0200831 8.09104 0.0315799C3.64515 0.0832429 0.0373475 3.74845 0.0143861 8.19147C-0.0114454 13.5415 3.52866e-05 20.3064 0.011516 25.6564C0.0172563 27.9755 1.79389 29.7464 4.12446 29.7866C4.73581 29.7981 5.32706 29.8124 5.9585 29.801C6.63299 29.8124 6.75067 29.3991 6.75067 29.026C6.72197 23.412 6.73345 16.3887 6.73632 10.7746C6.73632 9.33379 7.54857 8.43256 8.89468 8.43256C14.3279 8.42395 19.3679 8.43256 24.8011 8.4383L24.7868 8.45265C24.7925 8.45265 24.7954 8.45265 24.8011 8.45265V8.4383H24.804Z"*/}
                            {/*            fill="#008BFF"></path>*/}
                            {/*        <path d="M31.523 12.2786C31.4599 10.2178 29.8038 8.58755 27.7975 8.44691C26.8045 8.37803 25.8028 8.44691 24.8039 8.45265L24.8183 8.4383H24.8039C24.8039 8.4383 24.8039 8.44691 24.8039 8.45265C24.8039 8.45265 24.804 8.45265 24.8011 8.45265C24.8011 11.4635 24.804 14.4743 24.8011 17.4851C24.8011 19.1986 24.0376 19.962 22.3241 19.9649C18.8943 19.9678 15.4616 19.9735 12.0317 19.9563C11.5667 19.9563 11.3687 20.0539 11.383 20.5676C11.4175 21.8133 11.3802 21.7128 11.4003 22.9556C11.4376 25.1685 13.1884 26.9882 15.3869 27.0198C18.1394 27.0599 20.8948 27.0858 23.6473 27.0112C27.6827 26.9021 31.1499 23.6531 31.4742 19.6434C31.7096 16.7474 31.6091 15.1803 31.523 12.2757V12.2786Z"*/}
                            {/*            fill="#008BFF"></path>*/}
                            {/*    </g>*/}
                            {/*    <defs>*/}
                            {/*        <clipPath id="clip0_3327_48912">*/}
                            {/*            <rect width="160.23" height="34" fill="white"></rect>*/}
                            {/*        </clipPath>*/}
                            {/*    </defs>*/}
                            {/*</svg>*/}
                        </div>
                        <div className="close_" onClick={popupClose} >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                className="bi bi-x-lg" viewBox="0 0 16 16">
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                            </svg>
                        </div>
                    </div>
                    <div className="top_">
                        <button type="button" className="topBtn_">신용카드 매출전표</button>
                    </div>
                    <div className="middle_">
                        <div className="item_">
                            <div className="title_">
                                결제정보
                            </div>
                            <div className="content_">
                                <p className="start_">
                                    구매자
                                </p>
                                <p className="end_">
                                    {item.username}
                                </p>
                            </div>
                            <div className="content_">
                                <p className="start_">
                                    상품명
                                </p>
                                <p className="end_">
                                    {item.productname }
                                </p>
                            </div>
                            <div className="content_">
                                <p className="start_">
                                    카드번호
                                </p>
                                <p className="end_">
                                    {item.cardno}
                                </p>
                            </div>
                            <div className="content_">
                                <p className="start_">
                                    카드종류
                                </p>
                                <p className="end_">
                                    {item.cardName}
                                </p>
                            </div>
                            <div className="content_">
                                <p className="start_">
                                    할부개월
                                </p>
                                <p className="end_">
                                    {item.quota }
                                </p>
                            </div>
                            <div className="content_">
                                <p className="start_">
                                    승인번호
                                </p>
                                <p className="end_">
                                    {item.authno }
                                </p>
                            </div>
                            <div className="content_">
                                <p className="start_">
                                    승인일시
                                </p>
                                <p className="end_">
                                    {item.authdate }
                                </p>
                            </div>
                        </div>

                        <div className="item_">
                            <div className="title_">
                                결제금액정보
                            </div>
                            <div className="content_">
                                <p className="start_">
                                    공급가액
                                </p>
                                <p className="end_">
                                    {item.supply_price} 원
                                </p>
                            </div>
                            <div className="content_">
                                <p className="start_">
                                    세액
                                </p>
                                <p className="end_">
                                    {item.vat} 원
                                </p>
                            </div>
                            <div className="content_">
                                <p className="start_">
                                    합계
                                </p>
                                <p className="end_ red_">
                                    {item.amount} 원
                                </p>
                            </div>
                        </div>

                        <div className="item_">
                            <div className="title_">
                                상점정보
                            </div>
                            <div className="content_">
                                <p className="start_">
                                    상호
                                </p>
                                <p className="end_">
                                    {item.Adm_corp_name}
                                </p>
                            </div>
                            <div className="content_">
                                <p className="start_">
                                    대표자
                                </p>
                                <p className="end_">
                                    {item.Adm_corp_ceo}
                                </p>
                            </div>
                            <div className="content_">
                                <p className="start_">
                                    사업자등록번호
                                </p>
                                <p className="end_">
                                    {item.Adm_corp_nun}
                                </p>
                            </div>
                            <div className="content_">
                                <p className="start_">
                                    전화번호
                                </p>
                                <p className="end_">
                                    {item.Adm_corp_tel}
                                </p>
                            </div>
                            <div className="content_">
                                <p className="start_">
                                    주소
                                </p>
                                <p className="end_">
                                    {item.Adm_corp_addr} {item.Adm_corp_addr2}
                                </p>
                            </div>
                            <div className="content_">
                                <p className="start_">
                                    URL
                                </p>
                                <p className="end_">
                                    <a href={item.Adm_corp_addr2} target="_blank" >{item.Adm_corp_url}</a>
                                </p>
                            </div>
                        </div>

                        <div className="item_">
                            <div className="title_">
                                공급자정보
                            </div>
                            <div className="content_">
                                <p className="start_">
                                    상호
                                </p>
                                <p className="end_">
                                    {item.Sugi_corp_name }
                                </p>
                            </div>
                            <div className="content_">
                                <p className="start_">
                                    대표자
                                </p>
                                <p className="end_">
                                    {item.Sugi_corp_ceo}
                                </p>
                            </div>
                            <div className="content_">
                                <p className="start_">
                                    사업자등록번호
                                </p>
                                <p className="end_">
                                    {item.Sugi_corp_nun}
                                </p>
                            </div>
                            <div className="content_">
                                <p className="start_">
                                    전화번호
                                </p>
                                <p className="end_">
                                    {item.Sugi_corp_tel}
                                </p>
                            </div>
                            <div className="content_">
                                <p className="start_">
                                    주소
                                </p>
                                <p className="end_">
                                    {item.Sugi_corp_addr} {item.Sugi_corp_addr2}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="end_page_">
                        <p>
                            * 부가가치세법 제 46조 3항에 따라 신용카드 매출전표도 매입계산서로 사용할 수
                            있으며 부가가치세법 제 33조 2항에 따라 별도의 세금계산서를 교부하지 않습니다.
                        </p>
                    </div>
                </div>
            </div>


        </>
    );
}




export default SalesSlipPopupModal
