import React, {useState, useRef} from 'react'
import * as axios from 'axios';
import {useNavigate} from "react-router-dom";

import OpenLogComment from "../../views/include/OpenLogComment";

function SettingForm({user_level, user_site}) {
    
    const navigate = useNavigate();
    
    //운영로그 상태
    const [log_set, set_log_set] = useState(false);
    React.useEffect(() => {              
        if(log_set === true){
            window.location.reload();
        }
    }, [log_set])

    
    const [user_info, set_user_info] = useState({
        user_id:'',
        user_name:'',
        user_pw:'',
        user_pw2:'',
        last_login:null,

    });  
    
    const [login_logo_file, set_login_logo_file] = useState({ login_logo_file_r: "", login_logo_file: "" });
    const [up_login_logo_file, set_up_login_logo_file] = useState(null);
    const [site_logo_file, set_site_logo_file] = useState({ site_logo_file_r: "", site_logo_file: "" });
    const [up_site_logo_file, set_up_site_logo_file] = useState(null);

    const on_pw = (event) => {               
        set_user_info({...user_info, user_pw:event.currentTarget.value})
    } 
    const on_pw2 = (event) => {               
        set_user_info({...user_info, user_pw2:event.currentTarget.value})
    } 
    
    const on_up_site_logo_file_handler = (event) => {
        set_up_site_logo_file(event.target.files[0]);        
    }    
    const on_up_login_logo_file_handler = (event) => {
        set_up_login_logo_file(event.target.files[0]);
    } 
    
    const [corp_name, set_corp_name] = useState("");
    const on_corp_name = (event) => {
        set_corp_name(event.currentTarget.value)
    }

    const [corp_nun, set_corp_nun] = useState("");
    const on_corp_nun = (event) => {
        set_corp_nun(event.currentTarget.value)
    }


    const [corp_nun1, set_corp_nun1] = useState("");
    
    const on_corp_nun1 = (event) => {
        set_corp_nun1(event.currentTarget.value)
    }
    

    const [corp_nun2, set_corp_nun2] = useState("");
    const on_corp_nun2 = (event) => {
        set_corp_nun2(event.currentTarget.value)
    }


    const [corp_nun3, set_corp_nun3] = useState("");
    const on_corp_nun3 = (event) => {
        set_corp_nun3(event.currentTarget.value)
    }






    const [corp_type1, set_corp_type1] = useState("");
    const on_corp_type1 = (event) => {
        set_corp_type1(event.currentTarget.value)
    }

    const [corp_type2, set_corp_type2] = useState("");
    const on_corp_type2 = (event) => {
        set_corp_type2(event.currentTarget.value)
    }

    const [corp_ceo, set_corp_ceo] = useState("");
    const on_corp_ceo = (event) => {
        set_corp_ceo(event.currentTarget.value)
    }


    const [corp_tel, set_corp_tel] = useState("");
    const on_corp_tel = (event) => {
        set_corp_tel(event.currentTarget.value)
    }

    const [corp_tel1, set_corp_tel1] = useState("");
    const on_corp_tel1 = (event) => {
        set_corp_tel1(event.currentTarget.value)
    }

    const [corp_tel2, set_corp_tel2] = useState("");
    const on_corp_tel2 = (event) => {
        set_corp_tel2(event.currentTarget.value)
    }

    const [corp_tel3, set_corp_tel3] = useState("");
    const on_corp_tel3 = (event) => {
        set_corp_tel3(event.currentTarget.value)
    }



    const [corp_post, set_corp_post] = useState(""); 
    const on_corp_post = (event) => {
        set_corp_post(event.currentTarget.value)
    }

    const [corp_addr, set_corp_addr] = useState("");
    const on_corp_addr = (event) => {
        set_corp_addr(event.currentTarget.value)
    }

    const ref_corp_addr2 = useRef(null);

    const [corp_addr2, set_corp_addr2] = useState("");
    const on_corp_addr2 = (event) => {
        set_corp_addr2(event.currentTarget.value)
    }

    const [corp_url, set_corp_url] = useState("");
    const on_corp_url = (event) => {
        set_corp_url(event.currentTarget.value)
    }



    const onSubmitHandler = (event) => {

        event.preventDefault();

        if(user_info.user_pw !== user_info.user_pw2){
            alert('비밀번호가 일치하지 않습니다.');
            return;
        }

        let body = {
            user_id:sessionStorage.getItem('loginId'),
            user_pw:user_info.user_pw,            
            corp_name: corp_name,
            corp_nun: corp_nun1 + "-"+ corp_nun2 + "-"+ corp_nun3,
            corp_type1: corp_type1,
            corp_type2: corp_type2,
            corp_ceo: corp_ceo,
            /*corp_tel: corp_tel,*/
            corp_tel: corp_tel1 + "-" + corp_tel2 + "-" + corp_tel3,
            corp_post: corp_post,
            corp_addr: corp_addr,
            corp_addr2: corp_addr2,
            corp_url: corp_url,

        }
        axios.post('/api/admin/SettingAdd', body)
        .then(function(response){
            if(response.data === "AddOk"){
                alert("정상적으로 등록되었습니다.");

                //파일 업로드
                fileUpload(event, body.user_id);

                set_log_set(true);   

            }else{
                alert("시스템 에러");                
            }
        },[])
    }
    
    const Fn_User_Info=()=>{

        let bodyPage = {
            loginId: sessionStorage.getItem('loginId')
        }
        
        axios.post('/api/manage/userInfo', bodyPage).then(function(res){                              
            res.data.map((v, index) => {                
                set_user_info({...user_info
                    , user_id:v.user_id
                    , user_name:v.user_name
                    , last_login:v.last_login});                                    
                set_site_logo_file({
                    site_logo_file_r: (v.site_logo_file_r == null ? '' : v.site_logo_file_r),
                    site_logo_file: (v.site_logo_file == null ? '' : v.site_logo_file),
                }); 
                set_login_logo_file({
                    login_logo_file_r: (v.login_logo_file_r == null ? '' : v.login_logo_file_r),
                    login_logo_file: (v.login_logo_file == null ? '' : v.login_logo_file),
                }); 

                set_corp_name(v.corp_name);
                set_corp_nun(v.corp_nun);

                const parts = v.corp_nun.split('-'); // '-'로 문자열을 분리합니다.

                set_corp_nun1(parts[0]);
                set_corp_nun2(parts[1]);
                set_corp_nun3(parts[2]);

                set_corp_type1(v.corp_type1);
                set_corp_type2(v.corp_type2);
                set_corp_ceo(v.corp_ceo);
                set_corp_tel(v.corp_tel);

                const part_tel = v.corp_tel.split('-'); // '-'로 문자열을 분리합니다.

                set_corp_tel1(part_tel[0]);
                set_corp_tel2(part_tel[1]);
                set_corp_tel3(part_tel[2]);


                set_corp_post(v.corp_post);
                set_corp_addr(v.corp_addr);
                set_corp_addr2(v.corp_addr2);
                set_corp_url(v.corp_url);
                                        
                return ''
            });                       
        })         
    }
    
    React.useEffect(() => {              
        Fn_User_Info();      
    }, [])

     
    // 로그인 날짜
    function converToLocalTime(serverDate) {
        var date = new Date(Date.parse(serverDate));
        const TIME_ZONE = 3240 * 10000;
        return new Date(+date + TIME_ZONE).toISOString().replace('T', ' ').replace(/\..*/, '').substring(0, 16);
    }

    /********************
     * 파일업로드
     * @param {*} e 
     * @param {*} user_id 
     ********************/
    const fileUpload = async(e, user_id) => {
        e.preventDefault();            
        const formData = new FormData();
        formData.append('user_id', user_id);        
        formData.append('site_logo_file', up_site_logo_file); 
        formData.append('login_logo_file', up_login_logo_file); 
        axios.post('/api/fileUpload/storeFileUpload', formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            }).then(function(res){                                
                console.log('res : ', res);
            },[]
        );            
    };


    /******************************
     * 다음 우편번호 서비스 호출 함수
     ******************************/
    const handlePostcode = () => {

        new window.daum.Postcode({
            oncomplete: (data) => {
                set_corp_post(data.zonecode); // 우편번호
                set_corp_addr(data.address); // 전체 주소  
                ref_corp_addr2.current.focus();  
            },
            popupName: "postcodePopup", // 팝업 이름 설정
          }).open({
            // left: (window.innerWidth / 2) - 200, // 중앙에 위치
            // top: (window.innerHeight / 2) - 300,
            // width: 100,
            // height: 600,
          });        
    };

    return (
        <>
            {/* 운영로그 기록 */}
            {(log_set === true) && ( <OpenLogComment pageMode={'정보변경 > 정보수정'} /> )}
            
            <div id="container" data-menu-page="distributor_list">
                <form onSubmit={onSubmitHandler}>
                                     
                    <h3 className="page_ttl">정보변경</h3>

                    <div className="form_item">
                        
                        <div className="top_table">
                            <table>
                                <colgroup>
                                    <col width="200px" />
                                    <col width="*" />
                                    <col width="200px" />
                                    <col width="*" />
                                </colgroup>

                                <tbody>
                                    <tr>
                                        <th>ID   </th>
                                        <td>{user_info.user_id}</td>
                                        <th>관리자명</th>
                                        <td>{user_info.user_name} </td>
                                    </tr>
                                    <tr>
                                        <th>신규 비밀번호 입력</th>
                                        <td>
                                            <div className="flex">
                                                <div className="in_box mr_10" >
                                                    <input type="password" name="user_pw" id="user_pw" maxLength={15} onChange={on_pw} required />
                                                        
                                                </div>
                                                <span>(15자 이내)</span>
                                            </div>
                                        </td>
                                        <th>신규 비밀번호 재입력</th>
                                        <td>
                                            <div className="flex">
                                                <div className="in_box mr_10">
                                                    <input type="password" name="user_pw2" id="user_pw2" maxLength={15} onChange={on_pw2} required />
                                                </div>
                                                <span>(15자 이내)</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Last update </th>
                                        <td colSpan={3}>{(user_info.last_login !== null) && (converToLocalTime(user_info.last_login)) }</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                    </div>
                    


                    <div className="form_item">
                        <h3 className="label">*  사업자 정보 </h3>
                        <div className="top_table">
                            <table>
                                <colgroup>
                                    <col width="200px" />
                                    <col width="*" />
                                    <col width="200px" />
                                    <col width="*" />
                                </colgroup>

                                <tbody>
                                    <tr>                                       
                                        <th>사업자명</th>
                                        <td>
                                            <input type="text" name="corp_name" id="corp_name" value={corp_name} onChange={on_corp_name}  />
                                        </td>
                                        <th>사업자등록번호</th>
                                        <td>
                                            <input type="text" name="corp_nun" id="corp_nun"  value={corp_nun1}
                                            onChange={(event) => {
                                                const value = event.currentTarget.value; // 현재 입력값
                                                // 숫자만 허용하고 빈 문자열도 허용
                                                if (/^[0-9]*$/.test(value)) {
                                                    set_corp_nun1(value); // 상태 업데이트
                                                }
                                            }} 
                                            style={{ width: '80px', display: 'inline' }} 
                                            maxLength={3}
                                            /> 
                                            <span> - </span><input type="text" name="corp_nun" id="corp_nun" value={corp_nun2} 
                                            onChange={(event) => {
                                                const value = event.currentTarget.value; // 현재 입력값
                                                // 숫자만 허용하고 빈 문자열도 허용
                                                if (/^[0-9]*$/.test(value)) {
                                                    set_corp_nun2(value); // 상태 업데이트
                                                }
                                            }} 
                                            style={{ width: '50px', display: 'inline' }} 
                                            maxLength={2}  
                                            /> 

                                            <span> - </span><input type="text" name="corp_nun" id="corp_nun" value={corp_nun3} 
                                            onChange={(event) => {
                                                const value = event.currentTarget.value; // 현재 입력값
                                                // 숫자만 허용하고 빈 문자열도 허용
                                                if (/^[0-9]*$/.test(value)) {
                                                    set_corp_nun3(value); // 상태 업데이트
                                                }
                                            }} 
                                            style={{ width: '100px', display: 'inline' }} 
                                            maxLength={5}  
                                            />
                                        </td>
                                    </tr>

                                    <tr>                                       
                                        <th>업태</th>
                                        <td>
                                            <input type="text" name="corp_type1" id="corp_type1" value={corp_type1} onChange={on_corp_type1} />
                                        </td>
                                        <th>종목</th>
                                        <td>
                                            <input type="text" name="corp_type2" id="corp_type2" value={corp_type2} onChange={on_corp_type2} />
                                        </td>
                                    </tr>

                                    <tr>                                       
                                        <th>대표자</th>
                                        <td>
                                            <input type="text" name="corp_ceo" id="corp_ceo" value={corp_ceo} onChange={on_corp_ceo} />
                                        </td>
                                        <th>전화번호</th>
                                        <td>
                                            <input type="text" name="corp_tel1" id="corp_tel1"  value={corp_tel1}
                                            onChange={(event) => {
                                                const value = event.currentTarget.value; // 현재 입력값
                                                // 숫자만 허용하고 빈 문자열도 허용
                                                if (/^[0-9]*$/.test(value)) {
                                                    set_corp_tel1(value); // 상태 업데이트
                                                }
                                            }} 
                                            style={{ width: '70px', display: 'inline' }} 
                                            maxLength={4}
                                            />
                                            <span> - </span>
                                            <input type="text" name="corp_tel2" id="corp_tel2"  value={corp_tel2}
                                            onChange={(event) => {
                                                const value = event.currentTarget.value; // 현재 입력값
                                                // 숫자만 허용하고 빈 문자열도 허용
                                                if (/^[0-9]*$/.test(value)) {
                                                    set_corp_tel2(value); // 상태 업데이트
                                                }
                                            }} 
                                            style={{ width: '70px', display: 'inline' }} 
                                            maxLength={4}
                                            />
                                            <span> - </span>
                                            <input type="text" name="corp_tel3" id="corp_tel3"  value={corp_tel3}
                                            onChange={(event) => {
                                                const value = event.currentTarget.value; // 현재 입력값
                                                // 숫자만 허용하고 빈 문자열도 허용
                                                if (/^[0-9]*$/.test(value)) {
                                                    set_corp_tel3(value); // 상태 업데이트
                                                }
                                            }} 
                                            style={{ width: '70px', display: 'inline' }} 
                                            maxLength={4}
                                            />

                                        </td>
                                    </tr>

                                    <tr>                                       
                                        <th>주소</th>
                                        <td colSpan={3}>
                                            <div className="in_box">   
                                                <div style={{marginBottom: '5px', display: 'flex'}}>
                                                    <input type="text" name="post" id="corp_post" value={corp_post} style={{width:'200px'}} onChange={on_corp_post} readOnly={true}  />
                                                    <button type="button" className="btn_st_01" style={{margin:'8px 0 0 8px'}} onClick={handlePostcode}>우편번호검색</button>                                              
                                                </div>
                                                <div style={{marginBottom: '5px'}}><input type="text" id="corp_addr" value={corp_addr} onChange={on_corp_addr} readOnly={true} /></div>
                                                <div style={{marginBottom: '5px'}}><input type="text" id="corp_addr2" value={corp_addr2} ref={ref_corp_addr2} onChange={on_corp_addr2}  /></div>                                            
                                            </div>  
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>URL</th>
                                        <td colSpan={3}>
                                            <input type="text" name="corp_url" id="corp_url" value={corp_url} onChange={on_corp_url} />
                                        </td>
                                    </tr>


                                </tbody>
                            </table>
                        </div>
                    </div>





                    <div className="form_item">
                        <h3 className="label">*  로고 설정 </h3>
                        <div className="top_table">
                            <table>
                                <colgroup>
                                    <col width="200px" />
                                    <col width="*" />
                                    <col width="200px" />
                                    <col width="*" />
                                </colgroup>

                                <tbody>
                                    <tr>                                       
                                        <th>로그인 로고</th>
                                        <td>
                                            <div className="in_box">
                                                <input type="file" id="login_logo_File" onChange={on_up_login_logo_file_handler} />                                                                                       
                                                {login_logo_file.login_logo_file === '' ? '' : <img src={'/uploads/' + login_logo_file.login_logo_file} />}
                                            </div>
                                        </td>
                                        <th>관리자 로고</th>
                                        <td>
                                            <div className="in_box">
                                                <input type="file" id="site_logo_File" onChange={on_up_site_logo_file_handler} />
                                                {site_logo_file.site_logo_file === '' ? '' : <img src={'/uploads/' + site_logo_file.site_logo_file} />}
                                            </div>
                                        </td>
                                    </tr>    
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="top_table_btn">
                        <button className=" blue btn_st_01">등록</button>                       
                    </div>

                </form>

            </div>                   
        </>
    )
}

export default SettingForm