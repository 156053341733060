import React, {useEffect, useState, useRef } from 'react'
import * as axios from 'axios';
import {Link, useLocation, useNavigate} from "react-router-dom";
import queryString from 'query-string';
import Paging from "../../../include/Paging";
import Header from "../../../include/SiteAdminHeader";
import Footer from "../../../include/SiteAdminFooter";


function SiteAdminPGForm() {

    const navigate = useNavigate();

    const qs = queryString.parse(window.location.search);
    const sIdx = qs.idx ? qs.idx : '';
        
    const [page_state, set_page_state] = useState(true);    
    const [idx, set_idx] = useState(sIdx);

    const [sname, set_sname] = useState("");
    const [pg, set_pg] = useState("");
    const [cerify, set_cerify] = useState("");
    const [store, set_store] = useState("");
    const [income, set_income] = useState("");
    const [fee, set_fee] = useState("");
    const [mode, set_mode] = useState("Y");
    

    const on_sname_handler = (event) => {
        set_sname(event.currentTarget.value)
    }
    const on_pg_handler = (event) => {
        set_pg(event.currentTarget.value)
    }
    const on_cerify_handler = (event) => {
        set_cerify(event.currentTarget.value)
    }    
    const on_store_handler = (event) => {
        set_store(event.currentTarget.value)
    }  
    const on_income_handler = (event) => {
        set_income(event.currentTarget.value)
    }  
    const on_fee_handler = (event) => {       
        set_fee(event.currentTarget.value)
    }            
    const on_mode_handler = (event) => {       
        set_mode(event.currentTarget.value)
    }       


    const [corp_name, set_corp_name] = useState("");
    const on_corp_name = (event) => {
        set_corp_name(event.currentTarget.value)
    }

    const [corp_nun, set_corp_nun] = useState("");
    const on_corp_nun = (event) => {
        set_corp_nun(event.currentTarget.value)
    }


    const [corp_nun1, set_corp_nun1] = useState("");
    
    const on_corp_nun1 = (event) => {
        set_corp_nun1(event.currentTarget.value)
    }
    

    const [corp_nun2, set_corp_nun2] = useState("");
    const on_corp_nun2 = (event) => {
        set_corp_nun2(event.currentTarget.value)
    }


    const [corp_nun3, set_corp_nun3] = useState("");
    const on_corp_nun3 = (event) => {
        set_corp_nun3(event.currentTarget.value)
    }


    const [corp_ceo, set_corp_ceo] = useState("");
    const on_corp_ceo = (event) => {
        set_corp_ceo(event.currentTarget.value)
    }


    const [corp_tel, set_corp_tel] = useState("");
    const on_corp_tel = (event) => {
        set_corp_tel(event.currentTarget.value)
    }

    const [corp_tel1, set_corp_tel1] = useState("");
    const on_corp_tel1 = (event) => {
        set_corp_tel1(event.currentTarget.value)
    }

    const [corp_tel2, set_corp_tel2] = useState("");
    const on_corp_tel2 = (event) => {
        set_corp_tel2(event.currentTarget.value)
    }

    const [corp_tel3, set_corp_tel3] = useState("");
    const on_corp_tel3 = (event) => {
        set_corp_tel3(event.currentTarget.value)
    }



    const [corp_post, set_corp_post] = useState(""); 
    const on_corp_post = (event) => {
        set_corp_post(event.currentTarget.value)
    }

    const [corp_addr, set_corp_addr] = useState("");
    const on_corp_addr = (event) => {
        set_corp_addr(event.currentTarget.value)
    }

    const ref_corp_addr2 = useRef(null);

    const [corp_addr2, set_corp_addr2] = useState("");
    const on_corp_addr2 = (event) => {
        set_corp_addr2(event.currentTarget.value)
    }


    const onSubmitHandler = (event) => {
        event.preventDefault();

        let body = {
            idx: idx,
            sname: sname,
            pg: pg,
            cerify: cerify,           
            store: store,
            income: income,
            fee: fee,
            
            corp_name: corp_name,
            corp_nun: corp_nun1 + "-"+ corp_nun2 + "-"+ corp_nun3,
            corp_ceo: corp_ceo,
            corp_tel: corp_tel1 + "-" + corp_tel2 + "-" + corp_tel3,
            corp_post: corp_post,
            corp_addr: corp_addr,
            corp_addr2: corp_addr2,
            
            mode: (mode === '' ? 'Y' : mode)
        }

      
        axios.post('/api/manage/pg/pg_pay_add', body)
        .then(function(response){

            if(response.data === "AddOk"){
                if(idx !==''){
                    alert('정상적으로 수정되었습니다.')
                }
                navigate('/SiteAdmin/_manage/_pg/list');

            }else{
                alert("시스템 에러");
                
            }

        },[])
    }

    //상세정보
    const Fn_View=(sIdx)=>{

        let bodyPage = {
            idx: sIdx
        }
        
        axios.post('/api/manage/pg/pg_pay_view', bodyPage).then(function(res){                        
            res.data.map((v, index) => {
                set_sname(v.sname);
                set_pg(v.pg);
                set_store(v.store);
                set_income(v.income);
                set_fee(v.fee);
                set_mode(v.mode);              


                set_corp_name(v.corp_name);
                set_corp_nun(v.corp_nun);

                const parts = v.corp_nun.split('-'); // '-'로 문자열을 분리합니다.

                set_corp_nun1(parts[0]);
                set_corp_nun2(parts[1]);
                set_corp_nun3(parts[2]);

                set_corp_ceo(v.corp_ceo);
                set_corp_tel(v.corp_tel);

                const part_tel = v.corp_tel.split('-'); // '-'로 문자열을 분리합니다.

                set_corp_tel1(part_tel[0]);
                set_corp_tel2(part_tel[1]);
                set_corp_tel3(part_tel[2]);


                set_corp_post(v.corp_post);
                set_corp_addr(v.corp_addr);
                set_corp_addr2(v.corp_addr2);


                return ''
            }); 
            
            if(res.data.length === 0){                
                set_page_state(false)                
            }
        }) 


        
    }
    
    React.useEffect(() => {      
        if(sIdx !== ''){
            Fn_View(sIdx);
        }
    }, [sIdx])


    //페이지 정상 접근인지 체크
    React.useEffect(() => {
        if(page_state === false){
            alert("잘못된 접근입니다.");
            navigate(-1);
        }
    }, [page_state])

    
    /******************************
     * 다음 우편번호 서비스 호출 함수
     ******************************/
    const handlePostcode = () => {

        new window.daum.Postcode({
            oncomplete: (data) => {
                set_corp_post(data.zonecode); // 우편번호
                set_corp_addr(data.address); // 전체 주소  
                ref_corp_addr2.current.focus();  
            },
            popupName: "postcodePopup", // 팝업 이름 설정
          }).open({
            // left: (window.innerWidth / 2) - 200, // 중앙에 위치
            // top: (window.innerHeight / 2) - 300,
            // width: 100,
            // height: 600,
          });        
    };


    return (
        <>
        <div id="wrap">
            <Header/>        
            <div id="container" data-menu-page="distributor_list">
                <form onSubmit={onSubmitHandler}>
                 
                    <input type="hidden" name="idx" id="idx" value={idx} />
                    <h3 className="page_ttl">수기 PG 관리 설정</h3>

                    <div className="form_item">
                        
                        <div className="top_table">
                            <table>
                                <colgroup>
                                    <col width="200px" />
                                    <col width="*" />
                                    <col width="200px" />
                                    <col width="*" />
                                </colgroup>

                                <tbody>

                                    <tr>
                                        <th>관리명칭 <span className="red">＊</span></th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" id="sname" name="sname" value={sname} onChange={on_sname_handler} required style={{width: '250px', marginRight:'5px'}}  />
                                            </div>
                                        </td>   
                                        <th>pg사  <span className="red">＊</span></th>
                                        <td>
                                            <div className="in_box">
                                            <input type="text" id="pg" name="pg" value={pg} onChange={on_pg_handler} required style={{width: '150px', marginRight:'5px'}}  />
                                            </div>
                                        </td>                                        
                                    </tr>
                                   
                                    <tr>
                                        <th style={{'display':'none'}}>pg상점 코드  <span className="red">＊</span></th>
                                        <td style={{'display':'none'}}>
                                            <div className="in_box">
                                            <input type="text" id="store" name="store" value={store} onChange={on_store_handler} required style={{width: '150px', marginRight:'5px'}}  />
                                            </div>
                                        </td>
                                        <th>입금주기  <span className="red">＊</span></th>
                                        <td colSpan={3}>
                                            <div className="in_box">
                                            <input type='number' id="income" name="income" value={income} onChange={on_income_handler} required style={{width: '150px', marginRight:'5px'}}  />
                                            </div>
                                        </td>                                        
                                    </tr>
                                 
                                    <tr>
                                        <th>수수료율  <span className="red">＊</span></th>
                                        <td>
                                            <div className="in_box">
                                            <input type='number' id="fee" name="fee" value={fee} onChange={on_fee_handler} required style={{width: '150px', marginRight:'5px'}}  />
                                            </div>
                                        </td>
                                        <th>사용여부  <span className="red">＊</span></th>
                                        <td>
                                            <div className="in_box">                                           
                                            <select name='mode' defaultValue={mode} onChange={on_mode_handler} value={mode} style={{width: '150px', marginRight:'5px'}} >
                                                <option value={'Y'} >사용</option>
                                                <option value={'N'}>미사용</option>
                                            </select>
                                            </div>
                                        </td>                                        
                                    </tr>
                                  
                                
                                </tbody>
                            </table>
                        </div>

                    </div>



                    <div className="form_item">
                        <h3 className="label">*  사업자 정보 </h3>
                        <div className="top_table">
                            <table>
                                <colgroup>
                                    <col width="200px" />
                                    <col width="*" />
                                    <col width="200px" />
                                    <col width="*" />
                                </colgroup>

                                <tbody>
                                    <tr>                                       
                                        <th>상호</th>
                                        <td>
                                            <input type="text" name="corp_name" id="corp_name" value={corp_name} onChange={on_corp_name}  />
                                        </td>
                                        <th>사업자등록번호</th>
                                        <td>
                                            <input type="text" name="corp_nun" id="corp_nun"  value={corp_nun1}
                                            onChange={(event) => {
                                                const value = event.currentTarget.value; // 현재 입력값
                                                // 숫자만 허용하고 빈 문자열도 허용
                                                if (/^[0-9]*$/.test(value)) {
                                                    set_corp_nun1(value); // 상태 업데이트
                                                }
                                            }} 
                                            style={{ width: '80px', display: 'inline' }} 
                                            maxLength={3}
                                            /> 
                                            <span> - </span><input type="text" name="corp_nun" id="corp_nun" value={corp_nun2} 
                                            onChange={(event) => {
                                                const value = event.currentTarget.value; // 현재 입력값
                                                // 숫자만 허용하고 빈 문자열도 허용
                                                if (/^[0-9]*$/.test(value)) {
                                                    set_corp_nun2(value); // 상태 업데이트
                                                }
                                            }} 
                                            style={{ width: '50px', display: 'inline' }} 
                                            maxLength={2}  
                                            /> 

                                            <span> - </span><input type="text" name="corp_nun" id="corp_nun" value={corp_nun3} 
                                            onChange={(event) => {
                                                const value = event.currentTarget.value; // 현재 입력값
                                                // 숫자만 허용하고 빈 문자열도 허용
                                                if (/^[0-9]*$/.test(value)) {
                                                    set_corp_nun3(value); // 상태 업데이트
                                                }
                                            }} 
                                            style={{ width: '100px', display: 'inline' }} 
                                            maxLength={5}  
                                            />
                                        </td>
                                    </tr>

                                    <tr>                                       
                                        <th>대표자</th>
                                        <td>
                                            <input type="text" name="corp_ceo" id="corp_ceo" value={corp_ceo} onChange={on_corp_ceo} />
                                        </td>
                                        <th>전화번호</th>
                                        <td>
                                            <input type="text" name="corp_tel1" id="corp_tel1"  value={corp_tel1}
                                            onChange={(event) => {
                                                const value = event.currentTarget.value; // 현재 입력값
                                                // 숫자만 허용하고 빈 문자열도 허용
                                                if (/^[0-9]*$/.test(value)) {
                                                    set_corp_tel1(value); // 상태 업데이트
                                                }
                                            }} 
                                            style={{ width: '70px', display: 'inline' }} 
                                            maxLength={4}
                                            />
                                            <span> - </span>
                                            <input type="text" name="corp_tel2" id="corp_tel2"  value={corp_tel2}
                                            onChange={(event) => {
                                                const value = event.currentTarget.value; // 현재 입력값
                                                // 숫자만 허용하고 빈 문자열도 허용
                                                if (/^[0-9]*$/.test(value)) {
                                                    set_corp_tel2(value); // 상태 업데이트
                                                }
                                            }} 
                                            style={{ width: '70px', display: 'inline' }} 
                                            maxLength={4}
                                            />
                                            <span> - </span>
                                            <input type="text" name="corp_tel3" id="corp_tel3"  value={corp_tel3}
                                            onChange={(event) => {
                                                const value = event.currentTarget.value; // 현재 입력값
                                                // 숫자만 허용하고 빈 문자열도 허용
                                                if (/^[0-9]*$/.test(value)) {
                                                    set_corp_tel3(value); // 상태 업데이트
                                                }
                                            }} 
                                            style={{ width: '70px', display: 'inline' }} 
                                            maxLength={4}
                                            />

                                        </td>
                                    </tr>

                                    <tr>                                       
                                        <th>주소</th>
                                        <td colSpan={3}>
                                            <div className="in_box">   
                                                <div style={{marginBottom: '5px', display: 'flex'}}>
                                                    <input type="text" name="post" id="corp_post" value={corp_post} style={{width:'200px'}} onChange={on_corp_post} readOnly={true}  />
                                                    <button type="button" className="btn_st_01" style={{margin:'8px 0 0 8px'}} onClick={handlePostcode}>우편번호검색</button>                                              
                                                </div>
                                                <div style={{marginBottom: '5px'}}><input type="text" id="corp_addr" value={corp_addr} onChange={on_corp_addr} readOnly={true} /></div>
                                                <div style={{marginBottom: '5px'}}><input type="text" id="corp_addr2" value={corp_addr2} ref={ref_corp_addr2} onChange={on_corp_addr2}  /></div>                                            
                                            </div>  
                                        </td>
                                    </tr>
                                    

                                </tbody>
                            </table>
                        </div>
                    </div>


                    <div className="top_table_btn">
               
                        <button type="button" className=" btn_st_01" onClick={  () => navigate(-1) }>목록</button>
                        <button className=" blue btn_st_01">등록</button>
                    </div>

                </form>

            </div>
            
        </div>

        <Footer/>
        </>
    )
}
export default SiteAdminPGForm