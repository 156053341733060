/* eslint-disable react-hooks/exhaustive-deps */
import * as axios from 'axios';
import * as XLSX from 'xlsx'
import $ from 'jquery';
import React, { useEffect, useState, useRef } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Table, Checkbox } from "antd";
import queryString from 'query-string';
import Header from "../../include/SiteAdminHeader";
import Footer from "../../include/SiteAdminFooter";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";
import DatePicker from "react-datepicker";
/*import PagingNew from "../../include/PagingNew";*/
import { maskingFunc } from "../../include/ConfigSet_masking";
import { PgCardName, PgName, GetPayStatus, GetPayGubun, ChkDelayAble, ChgDelay, ChgDelayOff, TodayCnt } from "../../include/ConfigSet";
//import OpenLogComment from "../../include/OpenLogComment";
//import SalesSlipPopupModal from "../../include/SalesSlipPopupModal";
import "./styles.css"; // CSS 스타일 추가
/**
 * 통합 거래조회 > 거래내역
 * @returns
 */
function SiteAdminPayAllTransactionHistoryList() {

    const qs = queryString.parse(window.location.search);
    const sWord = qs.searchWord ? qs.searchWord : '';
    $("#searchWord").val(sWord);

    const sCate = qs.searchCates ? qs.searchCates : 'authno';
    $("#searchCates").val(sCate);

    const pg_group = qs.pg_group ? qs.pg_group : '';
    $("#pg_group").val(pg_group);

    const paymenttype = qs.paymenttype ? qs.paymenttype : '';
    $("#paymenttype").val(paymenttype);

    const ordertype = qs.ordertype ? qs.ordertype : '';
    $("#ordertype").val(ordertype);

    const chkdate = qs.chkdate ? qs.chkdate : 'authdate';
    $("#chkdate").val(chkdate);

    const sdate = qs.sdate ? qs.sdate : '';
    $("#sdate").val(sdate);

    const edate = qs.edate ? qs.edate : '';
    $("#edate").val(edate);

    const q_parent_idx1 = qs.parent_idx1 ? qs.parent_idx1 : '';
    const q_parent_idx2 = qs.parent_idx2 ? qs.parent_idx2 : '';

    const status = qs.status ? qs.status : '';
    $("#status").val(status);

    const page = qs.pg ? qs.pg : 1;

    const loginId = sessionStorage.getItem("loginId");
    const [sugiList, setSugiList] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [managerList1, setManagerList1] = useState([]);
    const [managerList2, setManagerList2] = useState([]);
    const [parent_idx1, set_parent_idx1] = useState("");
    const [parent_idx2, set_parent_idx2] = useState("");

    const on_set_parent_idx1_handler = (event) => {
        set_parent_idx1(event.currentTarget.value);
        getManagerList2(event.currentTarget.value);
    }
    const on_set_parent_idx2_handler = (event) => {
        set_parent_idx2(event.currentTarget.value);
    }

    // ****************************************************
    // 그리드 영역
    // ****************************************************
    const contextMenuRef = useRef(null);
    const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0, record: null });
    const [selectedRowKeys, setSelectedRowKeys] = useState([]); // 체크된 행    
    const [data, setData] = useState([]);                       // 테이블 데이터
    const [loading, setLoading] = useState(false);              // 로딩 상태
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [sorter, setSorter] = useState({ field: "", order: "descend" });

    const columns = [
        { title: "NO", dataIndex: "num", key: "num" },
        {
            title: "거래일자", dataIndex: "created_datetime",
            key: "created_datetime",
            sorter: true,
            defaultSortOrder: 'descend',
            sortOrder: sorter.field === "created_datetime" ? sorter.order : null,
            render: (text, record) => {                
                let cancelColor = '';
                if (record.status === 'C' || record.status === 'BC' || record.status === 'EC') {
                    cancelColor = 'red';
                } else {
                    cancelColor = '';
                }
                return <>
                    <span className={`${cancelColor}`} >
                        {text}
                    </span>
                </>
            },
        },
        {
            title: "구분", dataIndex: "status", key: "status",
            render: (text, record) => {
                let cancelColor = '';                              
                if (record.status === 'C' || record.status === 'BC' || record.status === 'EC') {
                    cancelColor = 'red';
                } else { 
                    cancelColor = '';
                }
                return <>
                    <span className={`blue_txt ${cancelColor}`} >
                        {GetPayGubun(text)}
                    </span>
                </>
            },
        },
        { title: "가맹점", dataIndex: "St_branch_name", key: "St_branch_name", },
        {
            title: "매체", dataIndex: "paymenttype", key: "paymenttype",
            render: (text, record) => {
                let paymentTypeString = '';
                if (text === 1) {
                    paymentTypeString = 'Key-in';
                } else if (text === 2) {
                    paymentTypeString = 'SMS';
                } else if (text === 3) {
                    paymentTypeString = 'EMAIL';
                }
                return <>{paymentTypeString}</>
            },        },
        { title: "TID", dataIndex: "cpid", key: "cpid" },
        {
            title: "정산", dataIndex: "status", key: "status",
            render: (text, record) => {
                let cancelColor = '';
                if (record.status === 'C' || record.status === 'BC' || record.status === 'EC') {
                    cancelColor = 'red';
                } else {
                    cancelColor = '';
                }
                return <>
                    <span className={`getpaystatus_td ${cancelColor}`} >
                        {GetPayStatus(text)}
                    </span>
                </>
            },
        },
        {
            title: "카드사", dataIndex: "cardcode", key: "cardcode",
            render: (text, record) => {              
                return <>
                    <span className={`pgcard_td2`} >
                        {PgCardName(record.pg_group, text)}
                    </span>
                </>
            },
        },
        {
            title: "카드번호", dataIndex: "cardno", key: "cardno",
            render: (text, record) => {
                let cancelColor = '';
                if (record.status === 'C' || record.status === 'BC' || record.status === 'EC') {
                    cancelColor = 'red';
                } else {
                    cancelColor = '';
                }
                return <>
                    <span className={`card_out_no_td ${cancelColor}`} >
                        {maskingFunc.card(text)}
                    </span>
                </>
            },
        },
        { title: "승인번호", dataIndex: "authno", key: "authno" },
        {
            title: "취소", dataIndex: "cancel_date", key: "cancel_date",
            render: (text, record) => {
                if (text) {
                    if (text.substr(0, 10) === "0000-00-00") {
                        text = "";
                    }
                } else {
                    text = '';
                }

                let cancelColor = '';
                if (record.status === 'C' || record.status === 'BC' || record.status === 'EC') {
                    cancelColor = 'red';
                } else {
                    cancelColor = '';
                }
                return <>
                    <span className={`card_out_no_td ${cancelColor}`} >
                        {text}
                    </span>
                </>
            },
        },
        {
            title: "PG", dataIndex: "pg_group", key: "pg_group",
            render: (text, record) => {
                return <>
                    <span className={`pg_name_td`} >
                        {PgName(text)}
                    </span>
                </>
            },
        },
        { title: "PG정산", dataIndex: "calculate_date_re", key: "calculate_date_re" },
        {
            title: "정산예정", dataIndex: "income", key: "income",
            render: (text, record) => {
                return <>
                    {record.calculate_date_re} <span className="red">(D+{record.income})</span>
                </>
            },
        },
        {
            title: "거래금액", dataIndex: "amount", key: "amount",
            render: (text, record) => {
                let cancelColor = '';
                if (record.status === 'C' || record.status === 'BC' || record.status === 'EC') {
                    cancelColor = 'red';
                } else {
                    cancelColor = '';
                }

                return <>
                    <span className={`${cancelColor}`}>{record.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </>
            },
        },
        {
            title: "할부", dataIndex: "quota", key: "quota",
            render: (text, record) => {               
                return <>
                    <span className={`quota_td`}>{(record.quota === "00" ? '일시불' : record.quota)}</span>
                </>
            },
        },
        {
            title: "중복구분", dataIndex: "doubleChk", key: "doubleChk",
            render: (text, record) => {
                return <>
                    {text > 1 ? "중복" : ""}
                </>
            },
        },        
        { title: "정산금액", dataIndex: "address", key: "address" },
        { title: "대리점", dataIndex: "address", key: "address" },
        { title: "주문자", dataIndex: "address", key: "address" },
        { title: "연락처", dataIndex: "address", key: "address" },
        { title: "상품명", dataIndex: "address", key: "address" },

    ];

    // API 데이터 가져오기
    const fetchData = (page = 1, pageSize = pagination.pageSize, sorterField, sorterOrder) => {

        console.log(sorterOrder);

        setLoading(true);

        let bodyPage = {
            nFrom: (page - 1) * pagination.pageSize,
            gListRows: pagination.pageSize,
            searchWord: sWord,
            searchCates: sCate,
            user_id: loginId,
            pg_group: pg_group,
            paymenttype: paymenttype,
            ordertype: ordertype,
            chkdate: chkdate,
            sdate: sdate,
            edate: edate,
            parent_idx1: q_parent_idx1,
            parent_idx2: q_parent_idx2,
            status: status,
            sortField: sorterField,
            sortOrder: sorterOrder === "ascend" ? "asc" : "desc",
        }

        setLoading(true);

        let totalCount = 0;

        axios.post('/api/management/pay/transaction_history_total_list', bodyPage).then(function (res) {

            totalCount = res.data.tcnt;

            setPagination((prev) => ({
                ...prev,
                current: page,
                total: res.data.tcnt, // 총 데이터 개수
            }));

            axios.post('/api/management/pay/transaction_history_list', bodyPage).then(function (res) {

                console.log(res.data);

                const formattedData = res.data.map((item, index) => ({
                    ...item,
                    num: totalCount - (page - 1) * pageSize - index, // 높은 순번 계산    
                    //status: GetPayGubun(item.status)
                    //created_datetime: item.created_datetime.substr(0, 10) + ' ' + item.created_datetime.substr(11, 8)
                }));

                setData(formattedData);

            })


        })



        setLoading(false);


    };

    // 셀 스타일을 직접 적용하는 컴포넌트
    const CustomCell = ({ children }) => {
           
        /*<div style={{ color: Number(children) < 50 ? "red" : "black" }}>{children}</div>*/
        console.log('children : ', children[1]);

        return <td>{children}</td>
    }


    // 페이지 변경 이벤트 핸들러
    const handleTableChange = (pagination, filters, sorter) => {
        setSorter({ field: sorter.field, order: sorter.order });
        fetchData(pagination.current, pagination.pageSize, sorter.field, sorter.order);
    };


    // 체크박스 선택 시 실행되는 함수
    const onSelectChange = (newSelectedRowKeys) => {
        console.log("선택된 행:", newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    // 체크박스 설정
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    // 우클릭 시 컨텍스트 메뉴 표시
    const ContextMenu = ({ x, y, visible, onClose, record }) => {

        console.log('record : ', record);

        useEffect(() => {
            if (visible) {
                const handleClickOutside = () => onClose();
                document.addEventListener("click", handleClickOutside);
                return () => document.removeEventListener("click", handleClickOutside);
            }
        }, [visible, onClose]);

        return (
            visible && (
                <div className="context-menu" style={{ top: `${y}px`, left: `${x}px` }}>
                    <ul>
                        <li>매출전표</li>                                                
                    </ul>
                </div>
            )
        );
    };
    
    const showContextMenu = (event, record) => {    
        event.preventDefault();
        setContextMenu({
            visible: true,
            x: event.clientX, // 마우스 커서의 X 좌표
            y: event.clientY, // 마우스 커서의 Y 좌표
            record,
        });
    };


 

    // 컴포넌트 마운트 시 첫 데이터 로드
    useEffect(() => {
        fetchData(pagination.current, pagination.pageSize, sorter.field, sorter.order);
    }, []);

    // ****************************************************



    const fn_submit = (e) => {
        $("#pg").val("1");
    }

    /**
     * 수기상점코드 리스트 가져오기
     */
    function getSugiList() {
        axios.post('/api/terminal/sugi_sel_list', { user_level: 2 }).then(function (res) {
            setSugiList(res.data);

        })
    }

    /**
     * PG Select Box 리스트
     * @returns
     */
    function rendSugiList() {
        return (
            sugiList.map((v, index) => {
                return (
                    <option value={v.pg} key={v.idx} >{v.sname}</option>
                )
            })
        )
    }

    /**
     * 지사 Select Box
     * @returns
     */
    function rendManagerList() {
        return (
            <select name="parent_idx1" id="parent_idx1" onChange={on_set_parent_idx1_handler} value={parent_idx1}  >
                <option value=''>지사</option>
                {
                    managerList1.map((v, index) => {
                        return (
                            <option value={v.idx} key={v.idx} >{v.branch_name}</option>
                        )
                    })
                }
            </select>
        )
    }
    /**
     * 총판 Select Box
     * @returns
     */
    function rendManagerList2() {
        return (
            <select name="parent_idx2" id="parent_idx2" onChange={on_set_parent_idx2_handler} value={parent_idx2} >
                <option value=''>총판</option>
                {
                    managerList2.map((v, index) => {
                        return (
                            <option value={v.idx} key={v.idx} >{v.branch_name}</option>
                        )
                    })
                }
            </select>
        );
    }


    function getManagerList1() {
        axios.post('/api/management/manager_list', { user_level: 2 }).then(function (res) {
            setManagerList1(res.data);

        });
    }

    /**
     * 총판 내역 가져오기
     * @param {any} temp_idx
     */
    function getManagerList2(temp_idx) {


        let bodyPage = {
            user_level: 3,
            parent_idx: temp_idx,
        }
        axios.post('/api/management/distributor_sel_list', bodyPage).then(function (res) {
            setManagerList2(res.data);
        })
        if (q_parent_idx2 !== "") {
            set_parent_idx2(q_parent_idx2);
        }
    }


    // 총판 내역 가져오기
    useEffect(() => {
        getManagerList2(q_parent_idx1);
    }, [managerList1]);


    useEffect(() => {
        getManagerList1();
        getSugiList();
    }, []);


    return (
        <>

            <div id="wrap">
                <Header />
                <div id="container" data-menu-page="pay_history" className='pay_history'>
                    <h3 className="page_ttl">결제내역</h3>
                    <form name="" id="" action="./transactionHistoryList" method="get" onSubmit={fn_submit} >
                        <input type="hidden" name="pg" id="pg" value={page} />

                        <div className="top_filter">
                            <div className="sel_box">
                                <select name="pg_group" id="pg_group" >
                                    <option value="">PG</option>
                                    {
                                        rendSugiList()
                                    }
                                </select>
                            </div>

                            <div className="sel_box">
                                <select name="paymenttype" id="paymenttype" >
                                    <option value="">결제매체</option>
                                    <option value="1">KEY-IN</option>
                                    <option value="2">SMS</option>
                                    <option value="3">EMAIL</option>
                                </select>
                            </div>

                            <div className="sel_box">
                                <select name="ordertype" id="ordertype" >
                                    <option value="" >거래구분</option>
                                    <option value="Y">승인</option>
                                    <option value="N">취소</option>
                                    <option value="D">보류</option>
                                </select>
                            </div>

                            <div className="sel_box">
                                <select name="chkdate" id="chkdate" >
                                    <option value="authdate">거래일자</option>
                                    <option value="calculatedate">정산일</option>
                                    <option value="canceldate">취소일</option>
                                </select>
                            </div>

                            <div className="date_box datepick_box">
                                <input type="hidden" name="sdate" id="sdate" value={(startDate === null || startDate === "") ? '' : new Date(startDate.toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 10)} />
                                <input type="hidden" name="edate" id="edate" value={(endDate === null || endDate === "") ? '' : new Date(endDate.toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 10)} />

                                <div className="datepick">
                                    <DatePicker locale={ko} dateFormat={'yyyy-MM-dd'} selected={startDate} onChange={(date) => setStartDate(date)} />
                                </div>
                                <span>-</span>
                                <div className="datepick">
                                    <DatePicker locale={ko} dateFormat={'yyyy-MM-dd'} selected={endDate} onChange={(date) => setEndDate(date)} />
                                </div>
                            </div>

                            <div className="sel_box">
                                {
                                    rendManagerList()
                                }
                            </div>

                            <div className="sel_box">
                                {
                                    rendManagerList2()
                                }
                            </div>

                            <div className="sel_box">
                                <select name="status" id="status" >
                                    <option value="">정산상태</option>
                                    <option value="B">정산전</option>
                                    <option value="E">정산완료</option>
                                    <option value="BC">정산전 취소</option>
                                    <option value="EC">정산완료후취소</option>
                                </select>
                            </div>

                            <div className="sel_box">
                                <select name="searchCates" id="searchCates">
                                    <option value="authno">승인번호</option>
                                    <option value="amount">금액</option>
                                    <option value="cardno">카드번호(뒤4자리)</option>
                                    <option value="cpid">터미널코드</option>
                                    <option value="St_branch_name">가맹점명</option>
                                </select>
                            </div>

                            <div className="in_box search_box">
                                <input type="text" name="searchWord" id="searchWord" placeholder='검색해주세요' />
                                <button type="submit" className="btn_st_02 blue search">검색</button>
                            </div>

                            <div className="btn_box">

                            </div>
                        </div>
                    </form>

                    <div className="bot_item">
                        {/*<div className="bot_info">*/}
                        {/*    <div className="left">총 {totalCnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}개</div>*/}
                        {/*    <div className="right">*/}
                        {/*        <button type="button" className="btn_st_02 blue_line " onClick={() => fn_Delay()} >보류설정</button>*/}
                        {/*        <button className="btn_st_02 gray_line " onClick={() => fn_OffDelay()}>보류해제</button>*/}
                        {/*        <button className="els_btn" onClick={handleDownload}><i></i>엑셀 다운로드</button>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="table_box">

                            <div style={{ position: "relative" }}>
                                <Table
                                    columns={columns}
                                    dataSource={data}
                                    onRow={(record) => ({
                                        onContextMenu: (event) => showContextMenu(event, record),
                                    })}


                                    rowKey="idx"
                                    rowSelection={rowSelection} // 체크박스 적용

                                    pagination={{
                                        current: pagination.current,
                                        pageSize: pagination.pageSize,
                                        total: pagination.total,
                                    }}
                                    loading={loading}
                                    onChange={handleTableChange}                                                                   
                                    summary={(pageData) => {
                                        //console.log('pageData : ', pageData);
                                        return (
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell colSpan={2}>
                                                    <strong>총합</strong>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell >
                                                    <strong>{0}</strong>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell>
                                                    <strong>{2}</strong>
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row>
                                        );
                                    }}
                                />

                                <ContextMenu
                                    x={contextMenu.x}
                                    y={contextMenu.y}
                                    visible={contextMenu.visible}
                                    onClose={() => setContextMenu({ ...contextMenu, visible: false })}
                                    record={contextMenu.record} // 선택된 행의 데이터 전달
                                />
                            </div>

                        </div>

                    </div>
                </div>
            </div>





            <Footer />
        </>
    )


}
export default SiteAdminPayAllTransactionHistoryList