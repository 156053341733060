/* eslint-disable eqeqeq */
import * as axios from 'axios';
import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom';



function StoreForm({ pay_type }) {

    //const focusRef = useRef();
  
    const navigate = useNavigate();
    const ref_corp_addr2 = useRef(null);
    const [corp_class, set_corp_class] = useState("");
    const [login_able, set_login_able] = useState("");
    const [double_able, set_double_able] = useState("");
    const [bank, set_bank] = useState("");
    const [bankList, set_bankList] = useState([]);

    const [addr, set_addr] = useState("");
    const [user_hp, set_user_hp] = useState("");
    const [user_phone, set_user_phone] = useState("");

    const [person_num, set_person_num] = useState("");
    const [branch_name, set_branch_name] = useState("");
    const [user_id, set_user_id] = useState("");
    const [user_pw, set_user_pw] = useState("");
    const [user_pw2, set_user_pw2] = useState("");
    const [user_email, set_user_email] = useState("");
    const [representative, set_representative] = useState("");
    const [parent_idx1, set_parent_idx1] = useState("");
    const [parent_idx2, set_parent_idx2] = useState("");
    const [parent_idx3, set_parent_idx3] = useState("");

    //const [manager_phone, set_manager_phone] = useState("");
    const [corp_name, set_corp_name] = useState("");
    const [corp_nun, set_corp_nun] = useState("");
    const [corp_post, set_corp_post] = useState("");
    const [corp_addr, set_corp_addr] = useState("");
    const [corp_addr2, set_corp_addr2] = useState("");
    const [corp_status, set_corp_status] = useState("");
    const [corp_sector, set_corp_sector] = useState("");
    const [commission, set_commission] = useState("");
    const [account_num, set_account_num] = useState("");
    const [account_holder, set_account_holder] = useState("");
    const [id_check, set_id_check] = useState("");
    const [memo, set_memo] = useState("");
    const [managerList1, setManagerList1] = useState([]);
    const [managerList2, setManagerList2] = useState([]);
    const [managerList3, setManagerList3] = useState([]);
    const [sugiList, setSugiList] = useState([]);
    const [terminalList, setTerminalList] = useState([]);
    const [contract_status, set_contract_status] = useState("");

    const [income, set_income] = useState("");
    const [sugi_able, set_sugi_able] = useState("");
    const [cancel_able, set_cancel_able] = useState("");
    const [email, set_email] = useState("");
    const [contract_delay, set_contract_delay] = useState("");
    const [limit_chk, set_limit_chk] = useState("");
    const [limit_price, set_limit_price] = useState("");
    const [month_chk, set_month_chk] = useState("");
    const [month_price, set_month_price] = useState("");
    const [sugi_code1, set_sugi_code1] = useState("");
    //const [sugi_code2, set_sugi_code2] = useState("");
    //const [sugi_code3, set_sugi_code3] = useState("");    
    const [site_logo_file, set_site_logo_file] = useState({ site_logo_file_r: "", site_logo_file: "" });
    const [up_site_logo_file, set_up_site_logo_file] = useState(null);

    //단말기 번호 input 창
    const [terminal_input_list, set_terminal_input_list] = useState([{ id: 0, name: "terminal", value: "" }]);
    const [terminal_num, set_terminal_num] = useState(1);

    const [pg_mid, set_pg_mid] = useState('');
    const [pg_apiKey, set_pg_apiKey] = useState('');
    const [pg_ivValue, set_pg_ivValue] = useState('');

    //터미널 추가 버튼
    const on_terminal_btn_handler = () => {
        //let listCnt = terminal_input_list.length;
        let n = terminal_num + 1;
        set_terminal_num(n);
        let starr = { id: terminal_num + 1, name: 'terminal', value: '', tId: 'tId', tId_value: '' };
        set_terminal_input_list([...terminal_input_list, starr]);
    }

    //터미널 단말기 TEXT onChange
    const on_terminal_handler = (index, e) => {
        let findIndex = terminal_input_list.findIndex(item => item.id === index)
        let copiedItems = [...terminal_input_list];
        copiedItems[findIndex].value = e.target.value;
        set_terminal_input_list(copiedItems);
    }
    //터미널 TID TEXT onChange
    const on_terminal_tId_handler = (index, e) => {
        let findIndex = terminal_input_list.findIndex(item => item.id === index)
        let copiedItems = [...terminal_input_list];
        copiedItems[findIndex].tId_value = e.target.value;
        set_terminal_input_list(copiedItems);
    }

    //터미털 단말기 TEXT BOX 삭제
    const on_terminal_input_Del = (index) => {
        let terminal_list = terminal_input_list.filter((item) => item.id !== index);
        set_terminal_input_list(terminal_list);
    }

    useEffect(() => {
        // 계약상태 기본값 대입
        set_contract_status('Y');

        // 로그인
        set_login_able('Y');

        // 중복결제
        set_double_able('N');

        set_contract_delay('Y');


        return () => {

        };
    }, []);

    const on_contract_status = (event) => {
        set_contract_status(event.currentTarget.value)
    }
    const on_corp_class = (event) => {
        set_corp_class(event.currentTarget.value)
    }
    const on_login_able = (event) => {
        set_login_able(event.currentTarget.value)
    }
    const on_double_able = (event) => {
        set_double_able(event.currentTarget.value)
    }
    const on_bank = (event) => {
        set_bank(event.currentTarget.value)
    }
    const on_branch_name_handler = (event) => {
        set_branch_name(event.currentTarget.value)
    }
    const on_addr_handler = (event) => {
        set_addr(event.currentTarget.value)
    }
    const on_user_hp_handler = (event) => {
        set_user_hp(event.currentTarget.value)
    }
    const on_user_phone_handler = (event) => {
        set_user_phone(event.currentTarget.value)
    }
    const on_user_email_handler = (event) => {
        set_user_email(event.currentTarget.value)
    }
    const on_person_num_handler = (event) => {
        set_person_num(event.currentTarget.value)
    }
    const on_user_id_handler = (event) => {
        set_user_id(event.currentTarget.value)
        set_id_check('');
    }
    const on_user_pw_handler = (event) => {
        set_user_pw(event.currentTarget.value)
    }
    const on_user_pw2_handler = (event) => {
        set_user_pw2(event.currentTarget.value)
    }
    const on_representative_handler = (event) => {
        set_representative(event.currentTarget.value)
    }
    // const on_manager_phone_handler = (event) => {
    //     set_manager_phone(event.currentTarget.value)
    // }
    const on_corp_name_handler = (event) => {
        set_corp_name(event.currentTarget.value)
    }
    const on_corp_nun_handler = (event) => {
        set_corp_nun(event.currentTarget.value)
    }
    const on_corp_post_handler = (event) => {
        set_corp_post(event.currentTarget.value)
    }
    const on_corp_addr_handler = (event) => {
        set_corp_addr(event.currentTarget.value)
    }
    const on_corp_addr2_handler = (event) => {
        set_corp_addr2(event.currentTarget.value)
    }
    const on_corp_status_handler = (event) => {
        set_corp_status(event.currentTarget.value)
    }

    const on_corp_sector_handler = (event) => {
        set_corp_sector(event.currentTarget.value)
    }

    const on_commission_handler = (event) => {
        set_commission(event.currentTarget.value)
    }

    const on_account_num_handler = (event) => {
        set_account_num(event.currentTarget.value)
    }

    const on_account_holder_handler = (event) => {
        set_account_holder(event.currentTarget.value)
    }

    const on_set_parent_idx1_handler = (event) => {
        set_parent_idx1(event.currentTarget.value);
        getManagerList2(event.currentTarget.value);
        //getManagerList3(event.currentTarget.value);
    }

    const on_set_parent_idx2_handler = (event) => {
        set_parent_idx2(event.currentTarget.value);
        getManagerList3(event.currentTarget.value);
    }

    const on_set_parent_idx3_handler = (event) => {
        set_parent_idx3(event.currentTarget.value)
    }

    const on_memo_handler = (event) => {
        set_memo(event.currentTarget.value)
    }

    const on_income_handler = (event) => {
        set_income(event.currentTarget.value)
    }


    const on_sugi_able_handler = (event) => {
        set_sugi_able(event.currentTarget.value)
    }

    const on_cancel_able_handler = (event) => {
        set_cancel_able(event.currentTarget.value)
    }

    const on_email_handler = (event) => {
        set_email(event.currentTarget.value)
    }


    const on_contract_delay = (event) => {
        set_contract_delay(event.currentTarget.value)
    }


    const on_limit_chk_handler = (event) => {
        set_limit_chk(event.currentTarget.value);
    }

    const on_limit_price_handler = (event) => {
        set_limit_price(event.currentTarget.value);
    }

    const on_month_chk_handler = (event) => {
        set_month_chk(event.currentTarget.value);
    }

    const on_month_price_handler = (event) => {
        set_month_price(event.currentTarget.value);
    }

    const on_sugi_code1_handler = (event) => {
        set_sugi_code1(event.currentTarget.value);
    }
    // const on_sugi_code2_handler = (event) => {
    //     set_sugi_code2(event.currentTarget.value);
    // }
    // const on_sugi_code3_handler = (event) => {
    //     set_sugi_code3(event.currentTarget.value);
    // }
    const on_up_site_logo_file_handler = (event) => {
        set_up_site_logo_file(event.target.files[0]);
    }
    const on_pg_mid_handler = (event) => {
        set_pg_mid(event.currentTarget.value);
    }
    const on_pg_apiKey_handler = (event) => {
        set_pg_apiKey(event.currentTarget.value);
    }
    const on_pg_ivValue_handler = (event) => {
        set_pg_ivValue(event.currentTarget.value);
    }

    const on_id_check = (event) => {
        if (user_id === "") {
            alert("아이디를 입력해주세요.");
            return false;
        }
        let body = {
            user_id: user_id,
        }

        axios.post('/api/user/login_chk', body)
            .then(function (response) {
                if (response.data === "NO") {

                    alert("이미 존재하는 아이디입니다.");
                    set_id_check('');
                    return false;

                } else {
                    alert("사용 가능한 아이디입니다.");
                    set_id_check('Y');
                    return false;

                }

            }, [])
        //set_id_check('Y');

    }
    const onSubmitHandler = (event) => {

        event.preventDefault();

        if (id_check === "") {
            alert("아이디 중복검사를 해주세요.");
            return false;
        }

        if (user_pw !== user_pw2) {
            alert("비밀번호 확인이 일치하지 않습니다.");
            return false;
        }


        let body = {
            user_id: user_id,
            user_pw: user_pw,
            parent_idx: parent_idx1,
            parent_idx2: parent_idx2,
            parent_idx3: parent_idx3,
            corp_class: corp_class,
            branch_name: branch_name,
            person_num: person_num,
            addr: addr,
            user_hp: user_hp,
            user_phone: user_phone,
            user_email: user_email,
            corp_name: corp_name,
            corp_nun: corp_nun,
            corp_post: corp_post,
            corp_addr: corp_addr,
            corp_addr2: corp_addr2,
            representative: representative,
            corp_status: corp_status,
            corp_sector: corp_sector,
            contract_status: contract_status,
            contract_delay: contract_delay,
            login_able: login_able,
            double_able: double_able,
            limit_chk: limit_chk,
            limit_price: limit_price,
            month_chk: month_chk,
            month_price: month_price,
            sugi_code1: sugi_code1,
            //sugi_code2: sugi_code2,
            //sugi_code3: sugi_code3,
            commission: commission,
            income: income,
            sugi_able: sugi_able,
            pg_mid: pg_mid,
            pg_apiKey: pg_apiKey,
            pg_ivValue: pg_ivValue,
            cancel_able: cancel_able,
            bank: bank,
            account_num: account_num,
            account_holder: account_holder,
            email: email,
            memo: memo,
            pay_type: (pay_type == 'Terminal' ? 1 : 0),
            admin_idx: sessionStorage.getItem("loginIdx")
        }

        axios.post('/api/management/StoreAdd', body)
            .then(function (response) {

                if (response.data === "AddOk") {

                    terminal_input_list.map((itmes, index) => (
                        putTerminal_Number(itmes)
                    ));

                    //파일 업로드
                    fileUpload(event, user_id);

                    if (pay_type == 'Terminal') {
                        navigate('/SiteAdmin/_management/StoreTerminalList');
                    } else {
                        navigate('/SiteAdmin/_management/StoreList');
                    }   
                    
                } else {
                    alert("시스템 에러");
                }
            }, [])
    }

    const putTerminal_Number = (item) => {

        let body = {
            user_id: user_id,
            t_number: item.value,
            tId: item.tId_value
        }

        axios.post('/api/management/StoreTerminalAdd', body).then(function (response) {
            console.log("response : ", response);
        }, [])

    }

    /**
     * 수기상점코드 리스트 가져오기
     */
    function getSugiList() {
        let bodyPage = {
            user_level: 2,
        }
        axios.post('/api/terminal/sugi_sel_list', bodyPage).then(function (res) {
            setSugiList(res.data);

        })
    }

    /**
     * 터미널 PG 리스트 가져오기
     */
    function getTerminalList() {        
        axios.post('/api/terminal/terminal/sugi_sel_list', {}).then(function (res) {            
            setTerminalList(res.data);
        })
    }

    function getManagerList1() {

        let bodyPage = {
            user_level: 2,
        }
        axios.post('/api/management/manager_list', bodyPage).then(function (res) {
            setManagerList1(res.data);

        })
    }

    function getManagerList2(temp_idx) {

        let bodyPage = {
            user_level: 3,
            parent_idx: temp_idx,
        }
        axios.post('/api/management/distributor_sel_list', bodyPage).then(function (res) {
            setManagerList2(res.data);

        })

    }

    /**
     * 일반 PG select box List 
     * @returns
     */
    function getManagerList3(temp_idx) {

        let bodyPage = {
            user_level: 4,
            parent_idx: temp_idx,
        }
        axios.post('/api/management/agency_sel_list', bodyPage).then(function (res) {
            setManagerList3(res.data);

        });
    }

    /**
     * 터미널 PG select box List 
     * @returns
     */
    function rendSugiList() {
        return (
            sugiList.map((v, index) => {
                return (
                    <option value={v.idx} key={v.idx} >{v.sname}</option>
                )
            })
        )
    }

    function rendTerminalList() {
        return (
            terminalList.map((v, index) => {
                return (
                    <option value={v.idx} key={v.idx} >{v.sname}</option>
                )
            })
        )
    }

    function rendManagerList() {
        return (
            <select name="parent_idx1" id="parent_idx1" required onChange={on_set_parent_idx1_handler} style={{ 'width': 'calc(100/3 - 6.66px) ' }}>
                <option value=''>지사선택</option>
                {
                    managerList1.map((v, index) => {
                        return (
                            <option value={v.idx} key={v.idx} >{v.branch_name}</option>
                        )
                    })
                }
            </select>
        )
    }

    function rendManagerList2() {
        return (
            <select name="parent_idx2" id="parent_idx2" required onChange={on_set_parent_idx2_handler} style={{ 'width': 'calc(100/3 - 6.66px)', 'marginLeft': '10px' }}>
                <option value=''>선택</option>
                {
                    managerList2.map((v, index) => {
                        return (
                            <option value={v.idx} key={v.idx} >{v.branch_name}</option>
                        )
                    })
                }
            </select>
        )
    }

    function rendManagerList3() {
        return (
            <select name="parent_idx3" id="parent_idx3" required onChange={on_set_parent_idx3_handler} style={{ 'width': 'calc(100/3 - 6.66px)', 'marginLeft': '10px' }}>
                <option value=''>선택</option>
                {
                    managerList3.map((v, index) => {
                        return (
                            <option value={v.idx} key={v.idx} >{v.branch_name}</option>
                        )
                    })
                }
            </select>
        )
    }

    // 상위지사 내역 가져오기
    useEffect(() => {
        getManagerList1();
        getSugiList();
        getTerminalList();
        getBankList();
    }, [])


    // 총판 내역 가져오기
    useEffect(() => {
        getManagerList2();
        getManagerList3();
    }, [managerList1])


    useEffect(() => {
        getManagerList3();
    }, [managerList2])


    function getBankList() {
        axios.post('/api/management/bank_list').then(function (res) {

            set_bankList(res.data);

        })

    }

    /********************
     * 파일업로드
     * @param {*} e 
     * @param {*} user_id 
     ********************/
    const fileUpload = async (e, user_id) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('user_id', user_id);
        formData.append('site_logo_file', up_site_logo_file);
        axios.post('/api/fileUpload/storeFileUpload', formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then(function (res) {
            console.log('res : ', res);
        }, []
        );
    };

    /******************************
     * 다음 우편번호 서비스 호출 함수
     ******************************/
    const handlePostcode = () => {

        new window.daum.Postcode({
            oncomplete: (data) => {
                set_corp_post(data.zonecode); // 우편번호
                set_corp_addr(data.address); // 전체 주소  
                ref_corp_addr2.current.focus();
            },
            popupName: "postcodePopup", // 팝업 이름 설정
        }).open({
            // left: (window.innerWidth / 2) - 200, // 중앙에 위치
            // top: (window.innerHeight / 2) - 300,
            // width: 100,
            // height: 600,
        });
    };
    return (
        <>
            <div id="container" data-menu-page="distributor_list">
                <form onSubmit={onSubmitHandler}>
                    <input type="hidden" name="id_check" id="id_check" value={id_check} />

                    <h3 className="page_ttl">가맹점 등록</h3>

                    <div className="form_item">
                        <h3 className="label">* 가맹점 기본정보</h3>

                        <div className="top_table">
                            <table>
                                <colgroup>
                                    <col width="200px" />
                                    <col width="*" />
                                    <col width="200px" />
                                    <col width="*" />
                                </colgroup>

                                <tbody>
                                    <tr>
                                        <th>로그인ID <span className="red">＊</span></th>
                                        <td colSpan="3">
                                            <div className="flex">
                                                <div className="in_box table_cont_1 mr_10" >
                                                    <input type="text" name="user_id" id="user_id" value={user_id} onChange={on_user_id_handler} required />
                                                </div>
                                                <button className="btn_st_02 blue_line" type="button" onClick={on_id_check} >중복검사</button>
                                                {
                                                    (pay_type === 'Terminal') && (
                                                        <>
                                                            &nbsp;&nbsp;&nbsp;<label>(가맹점 MID)</label>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>비밀번호 <span className="red">＊</span></th>
                                        <td>
                                            <div className="in_box">
                                                <input type="password" name="user_pw" id="user_pw" value={user_pw} onChange={on_user_pw_handler} required />
                                            </div>
                                        </td>
                                        <th>비밀번호 확인 <span className="red">＊</span></th>
                                        <td>
                                            <div className="in_box">
                                                <input type="password" name="user_pw2" id="user_pw2" value={user_pw2} onChange={on_user_pw2_handler} required />
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>상위업체 <span className="red">＊</span></th>
                                        <td>
                                            <div className="sel_box flex w_100">
                                                {
                                                    rendManagerList()
                                                }
                                                {
                                                    rendManagerList2()
                                                }
                                                {
                                                    rendManagerList3()
                                                }

                                            </div>
                                        </td>
                                        <th>사업자 구분</th>
                                        <td>
                                            <div className="sel_box">
                                                <select name="corp_class" id="corp_class" required onChange={on_corp_class}>
                                                    <option value="">선택</option>
                                                    <option value="corp">법인사업자</option>
                                                    <option value="solo">개인사업자</option>
                                                    <option value="non">비사업자</option>
                                                </select>
                                            </div>
                                        </td>
                                    </tr>


                                    <tr>
                                        <th>가맹점(회원)명 <span className="red">＊</span></th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" name="branch_name" id="branch_name" value={branch_name} onChange={on_branch_name_handler} required />
                                            </div>
                                        </td>
                                        <th>이메일</th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" name="user_email" id="user_email" value={user_email} onChange={on_user_email_handler} required />
                                            </div>
                                        </td>
                                    </tr>

                                    {/* 사용 안함 */}
                                    <tr style={{ display: 'none' }}>
                                        <th>주소</th>
                                        <td colSpan="3">
                                            <div className="in_box">
                                                <input type="text" name="addr" id="addr" value={addr} onChange={on_addr_handler} />
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>휴대폰 <span className="red">＊</span></th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" name="user_hp" id="user_hp" value={user_hp} onChange={on_user_hp_handler} required />
                                            </div>
                                        </td>

                                        <th>연락처</th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" name="user_phone" id="user_phone" value={user_phone} onChange={on_user_phone_handler} />
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>

                        </div>

                    </div>


                    <div className="form_item">
                        <h3 className="label">* 사업자 정보 (사업자의 경우 등록, 세금계산서 발행을 위한 정보 등록)</h3>

                        <div className="top_table">
                            <table>
                                <colgroup>
                                    <col width="200px" />
                                    <col width="*" />
                                    <col width="200px" />
                                    <col width="*" />
                                </colgroup>

                                <tbody>
                                    <tr>
                                        <th>업체명 (사업자등록상) <span className="red">＊</span></th>
                                        <td colSpan="3">
                                            <div className="in_box">
                                                <input type="text" name="corp_name" id="corp_name" value={corp_name} onChange={on_corp_name_handler} required />
                                            </div>
                                        </td>

                                    </tr>
                                    <tr>
                                        <th>사업자등록번호 <span className="red">＊</span></th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" name="corp_nun" id="corp_nun" value={corp_nun} onChange={on_corp_nun_handler} required />
                                            </div>
                                        </td>
                                        <th>대표자  </th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" name="representative" id="representative" value={representative} onChange={on_representative_handler} />
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>업태 <span className="red">＊</span></th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" name="corp_status" id="corp_status" value={corp_status} onChange={on_corp_status_handler} required />
                                            </div>
                                        </td>
                                        <th>종목 <span className="red">＊</span></th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" name="corp_sector" id="corp_sector" value={corp_sector} onChange={on_corp_sector_handler} required />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>사업자주소<span className="red">＊</span></th>
                                        <td colSpan="3">
                                            <div className="in_box">
                                                <div style={{ marginBottom: '5px', display: 'flex' }}>
                                                    <input type="text" name="post" id="corp_post" value={corp_post} style={{ width: '200px' }} onChange={on_corp_post_handler} readOnly={true} required />
                                                    <button type="button" className="btn_st_01" style={{ margin: '8px 0 0 8px' }} onClick={handlePostcode}>우편번호검색</button>
                                                </div>
                                                <div style={{ marginBottom: '5px' }}><input type="text" id="corp_addr" value={corp_addr} onChange={on_corp_addr_handler} readOnly={true} required /></div>
                                                <div style={{ marginBottom: '5px' }}><input type="text" id="corp_addr2" value={corp_addr2} ref={ref_corp_addr2} onChange={on_corp_addr2_handler} /></div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                    </div>

                    {
                        (pay_type === "Terminal") && (
                            <div className="form_item">
                                <h3 className="label">* 계약정보</h3>

                                <div className="top_table">
                                    <table>
                                        <colgroup>
                                            <col width="200px" />
                                            <col width="*" />
                                            <col width="200px" />
                                            <col width="*" />
                                        </colgroup>

                                        <tbody>
                                            <tr>
                                                <th>계약상태   <span className="red">＊</span></th>
                                                <td>
                                                    <div className="sel_box">
                                                        <select name="contract_status" id="contract_status" value={contract_status} required onChange={on_contract_status}>
                                                            <option value="Y" >정상</option>
                                                            <option value="F">해지</option>
                                                            <option value="A">신청</option>
                                                            <option value="W">미납</option>
                                                        </select>
                                                    </div>
                                                </td>

                                                <th>정산보류구분 <span className="red">＊</span></th>
                                                <td>
                                                    <div className="sel_box">
                                                        <select name="contract_delay" id="contract_delay" value={contract_delay} required onChange={on_contract_delay}>
                                                            <option value="Y" >정상</option>
                                                            <option value="F">보류</option>
                                                        </select>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>로그인 허용 <span className="red">＊</span></th>
                                                <td colSpan={3}>
                                                    <div className="sel_box table_cont_1">
                                                        <select name="login_able" id="login_able" value={login_able} required onChange={on_login_able}>
                                                            <option value="Y">허용</option>
                                                            <option value="N">불가</option>
                                                        </select>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>

                            </div>
                        )
                    }
                    {
                        (pay_type === "") && (
                            <div className="form_item">
                                <h3 className="label">* 계약정보</h3>

                                <div className="top_table">
                                    <table>
                                        <colgroup>
                                            <col width="200px" />
                                            <col width="*" />
                                            <col width="200px" />
                                            <col width="*" />
                                        </colgroup>

                                        <tbody>
                                            <tr>
                                                <th>계약상태   <span className="red">＊</span></th>
                                                <td>
                                                    <div className="sel_box">
                                                        <select name="contract_status" id="contract_status" value={contract_status} required onChange={on_contract_status}>
                                                            <option value="Y" >정상</option>
                                                            <option value="F">해지</option>
                                                            <option value="A">신청</option>
                                                        </select>
                                                    </div>
                                                </td>

                                                <th>정산보류구분 <span className="red">＊</span></th>
                                                <td>
                                                    <div className="sel_box">
                                                        <select name="contract_delay" id="contract_delay" value={contract_delay} required onChange={on_contract_delay}>
                                                            <option value="Y" >정상</option>
                                                            <option value="F">보류</option>
                                                        </select>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>로그인 허용 <span className="red">＊</span></th>
                                                <td>
                                                    <div className="sel_box table_cont_1">
                                                        <select name="login_able" id="login_able" value={login_able} required onChange={on_login_able}>
                                                            <option value="Y">허용</option>
                                                            <option value="N">불가</option>
                                                        </select>
                                                    </div>
                                                </td>

                                                <th>중복결제 <span className="red">＊</span></th>
                                                <td>
                                                    <div className="sel_box table_cont_1">
                                                        <select name="double_able" id="double_able" value={double_able} required onChange={on_double_able}>
                                                            <option value="Y">허용</option>
                                                            <option value="N">불가</option>
                                                        </select>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>건 한도 <span className="red">＊</span></th>
                                                <td>
                                                    <div className="flex">
                                                        <div className="check_box">
                                                            <input type="checkbox" name="limit_chk" id="limit_chk" value="Y" onChange={on_limit_chk_handler} />
                                                            <label htmlFor="limit_chk"><span></span>한도설정(체크)</label>
                                                        </div>
                                                        <div className="in_box" style={{ width: '214px', marginRight: '10px' }}>
                                                            <input type="text" name="limit_price" id="limit_price" value={limit_price} onChange={on_limit_price_handler} />
                                                        </div>
                                                        원
                                                    </div>
                                                </td>
                                                <th>월 한도 <span className="red">＊</span></th>
                                                <td>
                                                    <div className="flex">
                                                        <div className="check_box">
                                                            <input type="checkbox" name="month_chk" id="month_chk" value="Y" onChange={on_month_chk_handler} />
                                                            <label htmlFor="month_chk"><span></span>한도설정(체크)</label>
                                                        </div>
                                                        <div className="in_box" style={{ width: '214px', marginRight: '10px' }}>
                                                            <input type="text" name="month_price" id="month_price" value={month_price} onChange={on_month_price_handler} />
                                                        </div>
                                                        원
                                                    </div>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>

                                </div>

                            </div>
                        )
                    }



                    {
                        (pay_type === "") && (

                            <div className="form_item">
                                <h3 className="label">*  KEY-IN 설정 </h3>

                                <div className="top_table">
                                    <table>
                                        <colgroup>
                                            <col width="200px" />
                                            <col width="*" />
                                            <col width="200px" />
                                            <col width="*" />
                                        </colgroup>

                                        <tbody>
                                            <tr>
                                                <th>PG 선택  </th>
                                                <td>
                                                    <div className="sel_box">
                                                        <select name="sugi_code1" id="sugi_code1" onChange={on_sugi_code1_handler}>
                                                            <option value="">=수기상점코드 선택=</option>
                                                            {
                                                                rendSugiList()
                                                            }
                                                        </select>
                                                    </div>
                                                </td>
                                                <th>수기결제조건   <span className="red">＊</span></th>
                                                <td>
                                                    <div className="flex subs">
                                                        <div>
                                                            <span>수수료</span>
                                                            <div className="in_box" style={{ width: '150px', margin: '0 10px' }} >
                                                                <input type="text" name="commission" id="commission" value={commission} onChange={on_commission_handler} required />
                                                            </div>
                                                            <span>%  (부가세 포함)</span>
                                                        </div>

                                                        <div>
                                                            <span>/ D+</span>
                                                            <div className="in_box" style={{ width: '100px', margin: '0 10px' }} >
                                                                <input type="text" name="income" id="income" value={income} onChange={on_income_handler} required />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>


                                            <tr>
                                                <th>수기결제 <span className="red">＊</span></th>
                                                <td>
                                                    <div className="sel_box">
                                                        <select name="sugi_able" id="sugi_able" onChange={on_sugi_able_handler} >
                                                            <option value="" >선택</option>
                                                            <option value="N" >비인증</option>
                                                            <option value="Y">일반</option>
                                                        </select>
                                                    </div>
                                                </td>
                                                <th>결제취소 권한 <span className="red">＊</span></th>
                                                <td>
                                                    <div className='sel_box'>
                                                        <select name="cancel_able" id="cancel_able" onChange={on_cancel_able_handler} >
                                                            <option value="" >선택</option>
                                                            <option value="N">권한없음</option>
                                                            <option value="D">당일취소</option>
                                                            <option value="F">정산 전 취소</option>
                                                        </select>
                                                    </div>

                                                </td>
                                            </tr>
                                            <tr>
                                                <th>상점ID <span className="red">＊</span></th>
                                                <td colSpan={3}>
                                                    <div className="sel_box">
                                                        <div className="in_box" style={{ width: '234px' }} >
                                                            <input type="text" id="pg_mid" value={pg_mid} onChange={on_pg_mid_handler} required />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>apiKey <span className="red">＊</span></th>
                                                <td colSpan={3}>
                                                    <div className="sel_box">
                                                        <div className="in_box" style={{ width: '100%' }} >
                                                            <input type="text" id="pg_apiKey" value={pg_apiKey} onChange={on_pg_apiKey_handler} required />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>ivValue </th>
                                                <td colSpan={3}>
                                                    <div className="sel_box">
                                                        <div className="in_box" style={{ width: '100%' }} >
                                                            <input type="text" id="pg_ivValue" value={pg_ivValue} onChange={on_pg_ivValue_handler} />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>

                            </div>

                        )
                    }

                    {
                        (pay_type === "Terminal") && (
                            <div className="form_item" >
                                <h3 className="label">*  터미널 결제 </h3>

                                <div className="top_table">
                                    <table>
                                        <colgroup>
                                            <col width="200px" />
                                            <col width="*" />
                                            <col width="200px" />
                                            <col width="*" />
                                        </colgroup>

                                        <tbody>
                                            <tr>
                                                <th>PG 선택  </th>
                                                <td>
                                                    <div className="sel_box">
                                                        <select name="sugi_code1" id="sugi_code1" onChange={on_sugi_code1_handler}>
                                                            <option value="">=수기상점코드 선택=</option>
                                                            {
                                                                rendTerminalList()

                                                            }
                                                        </select>
                                                    </div>
                                                </td>
                                                <th>수기결제조건   <span className="red">＊</span></th>
                                                <td>
                                                    <div className="flex subs">
                                                        <div>
                                                            <span>수수료</span>
                                                            <div className="in_box" style={{ width: '150px', margin: '0 10px' }} >
                                                                <input type="text" name="commission" id="commission" value={commission} onChange={on_commission_handler} required />
                                                            </div>
                                                            <span>%  (부가세 포함)</span>
                                                        </div>

                                                        <div>
                                                            <span>/ D+</span>
                                                            <div className="in_box" style={{ width: '100px', margin: '0 10px' }} >
                                                                <input type="text" name="income" id="income" value={income} onChange={on_income_handler} required />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>


                                            <tr>
                                                <th>단말기 추가 <span className="red">＊</span></th>
                                                <td colSpan={3}>

                                                    <div className="btn_st_01" style={{ float: "left", width: "90px", textAlign: "center", cursor: "pointer", marginRight: "10px", margin: "5px" }}
                                                        onClick={on_terminal_btn_handler} >+추가
                                                    </div>

                                                    <div style={{ float: "left", width: "90%" }}>
                                                        {
                                                            terminal_input_list.map((itmes, index) => (
                                                                <>
                                                                    <div style={{ float: "left", marginLeft: "5px", marginTop: "5px", marginBottom: "5px" }}>
                                                                        <div style={{ float: "left" }}>
                                                                            <input type="text" style={{ width: "200px" }} placeholder={'단말기 번호'} name={itmes.name} value={itmes.value} onChange={(e) => {
                                                                                on_terminal_handler(itmes.id, e);
                                                                            }} />
                                                                        </div>
                                                                        <div style={{ float: "left", 'marginLeft': '5px' }}>
                                                                            <input type="text" style={{ width: "200px" }} placeholder={'TID'} name={itmes.tId} value={itmes.tId_value} onChange={(e) => {
                                                                                on_terminal_tId_handler(itmes.id, e);
                                                                            }} />
                                                                        </div>
                                                                        {
                                                                            (index > 0) && (
                                                                                <div onClick={(e) => { on_terminal_input_Del(itmes.id) }}
                                                                                    style={{
                                                                                        float: "left"
                                                                                        , margin: "5px"
                                                                                        , border: "1px solid #ddd"
                                                                                        , padding: "9px"
                                                                                        , marginTop: "2px"
                                                                                        , cursor: "pointer"
                                                                                    }}>X</div>
                                                                            )
                                                                        }

                                                                        {
                                                                            (index == 0) && (
                                                                                <div style={{ float: "left", margin: "5px", padding: "9px", marginRight: "15px", marginTop: "2px", cursor: "pointer" }}></div>
                                                                            )
                                                                        }

                                                                    </div>
                                                                </>
                                                            ))
                                                        }
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>상점ID <span className="red">＊</span></th>
                                                <td colSpan={3}>
                                                    <div className="sel_box">
                                                        <div className="in_box" style={{ width: '234px' }} >
                                                            <input type="text" id="pg_mid" value={pg_mid} onChange={on_pg_mid_handler} required />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>apiKey <span className="red">＊</span></th>
                                                <td colSpan={3}>
                                                    <div className="sel_box">
                                                        <div className="in_box" style={{ width: '100%' }} >
                                                            <input type="text" id="pg_apiKey" value={pg_apiKey} onChange={on_pg_apiKey_handler} required />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>ivValue <span className="red">＊</span></th>
                                                <td colSpan={3}>
                                                    <div className="sel_box">
                                                        <div className="in_box" style={{ width: '100%' }} >
                                                            <input type="text" id="pg_ivValue" value={pg_ivValue} onChange={on_pg_ivValue_handler} required />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        )
                    }



                    <div className="form_item">
                        <h3 className="label">*  정산정보 </h3>

                        <div className="top_table">
                            <table>
                                <colgroup>
                                    <col width="200px" />
                                    <col width="*" />
                                    <col width="200px" />
                                    <col width="*" />
                                </colgroup>

                                <tbody>
                                    <tr>
                                        <th>은행선택  <span className="red">＊</span></th>
                                        <td>
                                            <div className="sel_box">
                                                <select name="bank" id="bank" required onChange={on_bank}>
                                                    <option value="">선택</option>
                                                    {
                                                        bankList.map((v, index) => {
                                                            return (
                                                                <option value={v.bank_code} key={index}>{v.bank_name}</option>
                                                            )

                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </td>
                                        <th>E-Mail(계산서)</th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" name="email" id="email" value={email} onChange={on_email_handler} required />
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>계좌번호  <span className="red">＊</span></th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" name="account_num" id="account_num" value={account_num} onChange={on_account_num_handler} required />
                                            </div>
                                        </td>
                                        <th>예금주 <span className="red">＊</span></th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" name="account_holder" id="account_holder" value={account_holder} onChange={on_account_holder_handler} required />
                                            </div>
                                        </td>
                                    </tr>


                                    <tr>
                                        <th>기타  </th>
                                        <td colSpan="3">
                                            <div className="text_area">
                                                <textarea name="memo" id="memo" value={memo} onChange={on_memo_handler} ></textarea>
                                            </div>
                                        </td>
                                    </tr>




                                </tbody>
                            </table>

                        </div>
                    </div>

                    <div className="form_item">
                        <h3 className="label">*  로고 설정 </h3>
                        <div className="top_table">
                            <table>
                                <colgroup>
                                    <col width="200px" />
                                    <col width="*" />
                                    <col width="200px" />
                                    <col width="*" />
                                </colgroup>

                                <tbody>
                                    <tr>
                                        <th>관리자 로고</th>
                                        <td colSpan={3}>
                                            <div className="in_box">
                                                <input type="file" id="site_logo_File" onChange={on_up_site_logo_file_handler} />
                                                {site_logo_file.site_logo_file == '' ? '' : <img src={'/uploads/' + site_logo_file.site_logo_file} />}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="top_table_btn">

                        <button type="button" className=" btn_st_01" onClick={() => navigate(-1)}>목록</button>
                        <button className=" blue btn_st_01">등록</button>
                    </div>

                </form>

            </div>


        </>
    )
}

export default StoreForm