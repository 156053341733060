import * as axios from 'axios';
import React, {useEffect, useState, useRef} from 'react'
import { useNavigate } from 'react-router-dom';



import Header from "../../../include/MbranchHeader";
import Footer from "../../../include/SiteAdminFooter";
import queryString from 'query-string';

function MbranchManagementStoreTerminalForm() {

    
    const pay_type="";


    const navigate = useNavigate();

    const qs = queryString.parse(window.location.search);
    const sIdx = qs.idx ? qs.idx : '';

    if(sIdx === ''){
        alert("잘못된 접근입니다.");
        navigate(-1);
    }



    const [corp_class, set_corp_class] = useState("");
    const [login_able, set_login_able] = useState("");
    const [double_able, set_double_able] = useState("");
    const [bank, set_bank] = useState("");
    const [bankList, set_bankList] = useState([]);
    const [addr, set_addr] = useState("");
    const [user_hp, set_user_hp] = useState("");
    const [user_phone, set_user_phone] = useState("");
    const [person_num, set_person_num] = useState("");
    const [branch_name, set_branch_name] = useState("");
    const [user_id, set_user_id] = useState("");
    const [user_pw, set_user_pw] = useState("");
    const [user_pw2, set_user_pw2] = useState("");
    const [user_email, set_user_email] = useState("");
    const [representative, set_representative] = useState("");
    const [parent_idx1, set_parent_idx1] = useState("");
    const [parent_idx2, set_parent_idx2] = useState("");
    const [parent_idx3, set_parent_idx3] = useState("");

    const [manager_phone, set_manager_phone] = useState("");
    const [corp_name, set_corp_name] = useState("");
    const [corp_nun, set_corp_nun] = useState("");
    const [corp_post, set_corp_post] = useState("");
    const [corp_addr, set_corp_addr] = useState("");
    const [corp_addr2, set_corp_addr2] = useState("");
    const [corp_status, set_corp_status] = useState("");
    const [corp_sector, set_corp_sector] = useState("");
    const [commission, set_commission] = useState("");
    const [account_num, set_account_num] = useState("");
    const [account_holder, set_account_holder] = useState("");
    const [id_check, set_id_check] = useState("");
    const [memo, set_memo] = useState("");
    const [managerList1, setManagerList1] = useState([]);
    const [managerList2, setManagerList2] = useState([]);
    const [managerList3, setManagerList3] = useState([]);
    const [sugiList, setSugiList] = useState([]);
    const [contract_status, set_contract_status] = useState("");

    const [income, set_income] = useState("");
    const [sugi_able, set_sugi_able] = useState("");
    const [cancel_able, set_cancel_able] = useState("");
    const [email, set_email] = useState("");
    const [contract_delay, set_contract_delay] = useState("");
    const [limit_chk, set_limit_chk] = useState("");
    const [limit_price, set_limit_price] = useState("");
    const [month_chk, set_month_chk] = useState("");
    const [month_price, set_month_price] = useState("");
    const [sugi_code1, set_sugi_code1] = useState("");
    const [sugi_code2, set_sugi_code2] = useState("");
    const [sugi_code3, set_sugi_code3] = useState("");    
    const [site_logo_file, set_site_logo_file] = useState({ site_logo_file_r: "", site_logo_file: "" });

    //단말기 번호 input 창
    const [terminal_input_list, set_terminal_input_list] = useState([]);
    const [terminal_num, set_terminal_num] = useState(1);

    //터미널 추가 버튼
    const on_terminal_btn_handler =()=>{        
        //let listCnt = terminal_input_list.length;
        let n = terminal_num + 1;    
        set_terminal_num(n); 
        let starr = {id: terminal_num + 1, name: "terminal", value: ""};
        set_terminal_input_list([...terminal_input_list, starr]);
    }

    //터미널 단말기 TEXT onChange
    const on_terminal_handler=(index, e)=>{                
        let findIndex = terminal_input_list.findIndex(item => item.id === index)
        let copiedItems = [...terminal_input_list];                
        copiedItems[findIndex].value = e.target.value;    
        set_terminal_input_list(copiedItems);
    }

    //터미털 단말기 TEXT BOX 삭제
    const on_terminal_input_Del=(index)=>{ 
        let terminal_list = terminal_input_list.filter((item) => item.id !== index);        
        set_terminal_input_list(terminal_list);
    }


    const GetTerminal =(user_id)=>{
        let bodyTml = {
            user_id: user_id
        }      
        axios.post('/api/management/storeTerminalMod', bodyTml).then(function(res){            
            let terminal_list = res.data;
            let terminal_list_arr = [];
            let n = 0;
            terminal_list.map((itmes, index) => (
                terminal_list_arr.push({id: (n = n + 1), name: "terminal", value: itmes.t_number}) 
            ));                                        
            set_terminal_num(n);            
            set_terminal_input_list(terminal_list_arr)
        });
    }


    const putTerminal_Number_Del =()=>{
        
        let body = {
            user_id: user_id
        }
    
        axios.post('/api/management/StoreTerminalDel', body).then(function(response){                            
            //console.log("response : ", response);
        },[])
       
    }

    const putTerminal_Number =(t_number)=>{
        //console.log("t_number : ", t_number);
        let body = {
            user_id: user_id,
            t_number: t_number
        }
    
        axios.post('/api/management/StoreTerminalAdd', body).then(function(response){                            
            //console.log("response : ", response);
        },[])
       
    }


    useEffect(() => {

        let bodyPage = {
            idx: sIdx
        }

        axios.post('/api/management/storeMod', bodyPage).then(function(res){
            console.log(res.data.map);
            res.data.map((v, index) => {
                set_user_id(v.user_id);
                getManagerList1();
                set_parent_idx1(v.parent_idx);
                getManagerList2(v.parent_idx);
                set_parent_idx2(v.parent_idx2);
                getManagerList3(v.parent_idx2);
                set_parent_idx3(v.parent_idx3);
                set_corp_class(v.corp_class);
                set_branch_name(v.branch_name);
                set_person_num(v.person_num);
                set_addr(v.addr);
                set_user_hp(v.user_hp);
                set_user_phone(v.user_phone);
                set_user_email(v.user_email);
                set_corp_name(v.corp_name);
                set_corp_nun(v.corp_nun);
                set_corp_post(v.corp_post);
                set_corp_addr(v.corp_addr);
                set_corp_addr2(v.corp_addr2);
                set_representative(v.representative);
                set_corp_status(v.corp_status);
                set_corp_sector(v.corp_sector);
                set_contract_status(v.contract_status);
                set_contract_delay(v.contract_delay);
                set_login_able(v.login_able);
                set_double_able(v.double_able);
                set_limit_chk(v.limit_chk);
                set_limit_price(v.limit_price);
                set_month_chk(v.month_chk);
                set_month_price(v.month_price);
                set_sugi_code1(v.sugi_code1);
                set_sugi_code2(v.sugi_code2);
                set_sugi_code3(v.sugi_code3);
                set_commission(v.commission);
                set_income(v.income);
                set_sugi_able(v.sugi_able);
                set_cancel_able(v.cancel_able);
                set_bank(v.bank);
                set_account_num(v.account_num);
                set_account_holder(v.account_holder);
                set_email(v.email);
                set_memo(v.memo);                       
                set_site_logo_file({
                    site_logo_file_r: (v.site_logo_file_r == null ? '' : v.site_logo_file_r),
                    site_logo_file: (v.site_logo_file == null ? '' : v.site_logo_file),
                }); 

                if(pay_type === "Terminal"){
                    GetTerminal(v.user_id);
                }                
            })            
        });


        return () => {
          
        };
    }, []);





    useEffect(() => {
        // 계약상태 기본값 대입
        set_contract_status('Y');
        
        // 로그인
        set_login_able('Y');
        set_double_able('Y');
        return () => {
          
        };
    }, []);
  
    
    const on_contract_status = (event) => {
        set_contract_status(event.currentTarget.value)
    }
    const on_corp_class = (event) => {
        set_corp_class(event.currentTarget.value)
    }
    const on_login_able = (event) => {
        set_login_able(event.currentTarget.value)
    }
    const on_double_able = (event) => {
        set_double_able(event.currentTarget.value)
    }
    const on_bank = (event) => {
        set_bank(event.currentTarget.value)
    }

    const on_branch_name_handler = (event) => {
        set_branch_name(event.currentTarget.value)
    }
    const on_addr_handler = (event) => {
        set_addr(event.currentTarget.value)
    }

    const on_user_hp_handler = (event) => {
        set_user_hp(event.currentTarget.value)
    }

    const on_user_phone_handler = (event) => {
        set_user_phone(event.currentTarget.value)
    }

    const on_person_num_handler = (event) => {
        set_person_num(event.currentTarget.value)
    }

    const on_user_id_handler = (event) => {
        set_user_id(event.currentTarget.value)
        set_id_check('');
    }

    const on_user_pw_handler = (event) => {
        set_user_pw(event.currentTarget.value)
    }

    const on_user_pw2_handler = (event) => {
        set_user_pw2(event.currentTarget.value)
    }

    const on_representative_handler = (event) => {
        set_representative(event.currentTarget.value)
    }

    const on_manager_phone_handler = (event) => {
        set_manager_phone(event.currentTarget.value)
    }

    const on_corp_name_handler = (event) => {
        set_corp_name(event.currentTarget.value)
    }

    const on_corp_nun_handler = (event) => {
        set_corp_nun(event.currentTarget.value)
    }

    const on_corp_post_handler = (event) => {
        set_corp_post(event.currentTarget.value)
    }
    const on_corp_addr_handler = (event) => {
        set_corp_addr(event.currentTarget.value)
    }
    const on_corp_addr2_handler = (event) => {
        set_corp_addr2(event.currentTarget.value)
    }
    
    const on_corp_status_handler = (event) => {
        set_corp_status(event.currentTarget.value)
    }

    const on_corp_sector_handler = (event) => {
        set_corp_sector(event.currentTarget.value)
    }

    const on_commission_handler = (event) => {
        set_commission(event.currentTarget.value)
    }

    const on_account_num_handler = (event) => {
        set_account_num(event.currentTarget.value)
    }

    const on_account_holder_handler = (event) => {
        set_account_holder(event.currentTarget.value)
    }

    const on_set_parent_idx1_handler = (event) => {
        set_parent_idx1(event.currentTarget.value);
        getManagerList2(event.currentTarget.value);
        //getManagerList3(event.currentTarget.value);
    }

    const on_set_parent_idx2_handler = (event) => {
        set_parent_idx2(event.currentTarget.value);
        getManagerList3(event.currentTarget.value);
    }

    const on_set_parent_idx3_handler = (event) => {
        set_parent_idx3(event.currentTarget.value)
    }

    const on_memo_handler = (event) => {
        set_memo(event.currentTarget.value)
    }

    const on_income_handler = (event) => {
        set_income(event.currentTarget.value)
    }


    const on_sugi_able_handler = (event) => {
        set_sugi_able(event.currentTarget.value)
    }

    const on_cancel_able_handler = (event) => {
        set_cancel_able(event.currentTarget.value)
    }

    const on_email_handler = (event) => {
        set_email(event.currentTarget.value)
    }


    const on_contract_delay = (event) => {
        set_contract_delay(event.currentTarget.value)
    }


    const on_limit_chk_handler = (event) => {
        set_limit_chk(event.currentTarget.value);
    }

    const on_limit_price_handler = (event) => {
        set_limit_price(event.currentTarget.value);
    }

    const on_month_chk_handler = (event) => {
        set_month_chk(event.currentTarget.value);
    }

    const on_month_price_handler = (event) => {
        set_month_price(event.currentTarget.value);
    }

    const on_sugi_code1_handler = (event) => {
        set_sugi_code1(event.currentTarget.value);
    }

    const on_sugi_code2_handler = (event) => {
        set_sugi_code2(event.currentTarget.value);
    }

    const on_sugi_code3_handler = (event) => {
        set_sugi_code3(event.currentTarget.value);
    }






    
    const onSubmitHandler = (event) => {
        event.preventDefault();

        let body = {
            idx: qs.idx,
            user_pw: user_pw,
            parent_idx: parent_idx1,
            parent_idx2: parent_idx2,
            parent_idx3: parent_idx3,
            corp_class: corp_class,
            branch_name: branch_name,
            person_num: person_num,
            addr: addr,
            user_hp: user_hp,
            user_phone: user_phone,            
            corp_name: corp_name,
            corp_nun: corp_nun,
            corp_post: corp_post,
            corp_addr: corp_addr,
            corp_addr2: corp_addr2,
            representative: representative,
            corp_status: corp_status,
            corp_sector: corp_sector,
            contract_status: contract_status,
            contract_delay: contract_delay,
            login_able: login_able,
            double_able: double_able,
            limit_chk: limit_chk,
            limit_price: limit_price,
            month_chk: month_chk,
            month_price: month_price,
            sugi_code1: sugi_code1,
            sugi_code2: sugi_code2,
            sugi_code3: sugi_code3,
            commission: commission,
            income: income,
            sugi_able: sugi_able,
            cancel_able: cancel_able,
            bank: bank,
            account_num: account_num,
            account_holder: account_holder,
            email: email,
            memo: memo
        }

        axios.post('/api/management/StoreUpdate', body)
        .then(function(response){
            

            
            if(response.data === "ModOk"){    


                if(pay_type === "Terminal"){
                                
                    putTerminal_Number_Del();

                    terminal_input_list.map((itmes, index) => (                    
                        putTerminal_Number(itmes.value)
                    ));
                    
                }


                alert("정상적으로 변경되었습니다.");                
                window.location.reload();
            
            }else{
                alert("시스템 에러");
                
            }        
        },[])


    }




    // 수기상점코드 리스트 가져오기
    function getSugiList(){
        let bodyPage = {
            user_level: 2,
        }
        axios.post('/api/terminal/sugi_sel_list',bodyPage).then(function(res){            
            setSugiList(res.data);
            
        })

    }

    function getManagerList1(){
        
        let bodyPage = {
            user_level: 2,
        }
        axios.post('/api/management/manager_list',bodyPage).then(function(res){            
            setManagerList1(res.data);
            
        })
    }

    function getManagerList2(temp_idx){

        
        let bodyPage = {
            user_level: 3,
            parent_idx: temp_idx,
        }
        axios.post('/api/management/distributor_sel_list',bodyPage).then(function(res){            
            setManagerList2(res.data);
            
        })
    
    }

    function getManagerList3(temp_idx){

        
        let bodyPage = {
            user_level: 4,
            parent_idx: temp_idx,
        }
        axios.post('/api/management/agency_sel_list',bodyPage).then(function(res){            
            setManagerList3(res.data);
            
        })
    
    }

    function rendSugiList(){
        return (
                sugiList.map((v, index) => {
                    return (
                        <option value={v.idx} key={v.idx} >{v.sname}</option>
                    )
                })
            

        )
    }
//disabled
    function rendManagerList(){
        return (
            <select name="parent_idx1" id="parent_idx1" value={parent_idx1} disabled="true" required 
                style={{'width':'200px'}}>
                <option value=''>==지사선택==</option>
                    {
                        managerList1.map((v, index) => {
                            return (
                                <option value={v.idx} key={v.idx} >{v.branch_name}</option>
                            )
                        })
                    }
            </select>
        )
    }

    function rendManagerList2(){
        return (
            <select name="parent_idx2" id="parent_idx2" value={parent_idx2} required  disabled="true" style={{'width':'200px','marginLeft':'10px'}}>
                <option value=''>==선택==</option>
            {
                managerList2.map((v, index) => {
                    return (
                        <option value={v.idx} key={v.idx} >{v.branch_name}</option>
                    )
                })
            }
            </select>
        )
    }

    function rendManagerList3(){
        return (
            <select name="parent_idx3" id="parent_idx3" value={parent_idx3} required disabled="true" style={{'width':'200px','marginLeft':'10px'}}>
                <option value=''>==선택==</option>
            {
                managerList3.map((v, index) => {
                    return (
                        <option value={v.idx} key={v.idx} >{v.branch_name}</option>
                    )
                })
            }
            </select>
        )
    }

    // 상위지사 내역 가져오기
    useEffect(() => {
        //getManagerList1()
        getSugiList()
        getBankList()
    },[])


    // 총판 내역 가져오기
    /*
    useEffect(() => {
        getManagerList2();
        getManagerList3();
    },[managerList1])


    useEffect(() => {
        getManagerList3();
    },[managerList2])
    */

    function getBankList(){
        axios.post('/api/management/bank_list').then(function(res){         
            
            set_bankList(res.data);
            
        })

    }


    return (
        <>
        <div id="wrap">
            <Header/>

        
         
                <div id="container" data-menu-page="distributor_list">
                    <form onSubmit={onSubmitHandler}>
                        <input type="hidden" name="id_check" id="id_check" value={id_check} />

                        <h3 className="page_ttl">가맹점 등록</h3>

                        <div className="form_item">
                            <h3 className="label">* 가맹점 기본정보</h3>

                            <div className="top_table">
                                <table>
                                    <colgroup>
                                        <col width="200px" />
                                        <col width="*" />
                                        <col width="200px" />
                                        <col width="*" />
                                    </colgroup>

                                    <tbody>
                                        <tr>
                                            <th>로그인ID</th>
                                            <td>
                                                <div className="flex">
                                                    {user_id}
                                                </div>
                                            </td>
                                            <th>가맹점 코드	</th>
                                            <td>
                                                <div className="flex">
                                                    {qs.idx}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>가맹점(회원)명 <span className="red">＊</span></th>
                                            <td >
                                                <div className="in_box">
                                                {branch_name}
                                                </div>
                                            </td>
                                            <th>이메일</th>
                                            <td>
                                                <div className="in_box">
                                                    {user_email}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>상위업체 <span className="red">＊</span></th>
                                            <td>
                                                <div className="sel_box flex">
                                                    {
                                                        rendManagerList()
                                                    }
                                                    {
                                                        rendManagerList2()
                                                    }
                                                    {
                                                        rendManagerList3()
                                                    }
                                                    
                                                </div>
                                            </td>
                                            <th>사업자 구분</th>
                                            <td>
                                                <div className="sel_box">
                                                    <select name="corp_class" id="corp_class" value={corp_class} required disabled="true" >
                                                        <option value="">선택</option>
                                                        <option value="corp">법인사업자</option>
                                                        <option value="solo">개인사업자</option>
                                                        <option value="non">비사업자</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>


                                

                                        <tr>
                                            <th>주소</th>
                                            <td colSpan="3">
                                                <div className="in_box">
                                                {addr}
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <th>휴대폰 <span className="red">＊</span></th>
                                            <td>
                                                <div className="in_box">
                                                {user_hp}
                                                </div>
                                            </td>

                                            <th>연락처</th>
                                            <td>
                                                <div className="in_box">
                                                {user_phone}
                                                </div>
                                            </td>
                                        </tr>
                                    
                                    </tbody>
                                </table>

                            </div>

                        </div>


                        <div className="form_item">
                            <h3 className="label">* 사업자 정보 (사업자의 경우 등록, 세금계산서 발행을 위한 정보 등록)</h3>

                            <div className="top_table">
                                <table>
                                    <colgroup>
                                        <col width="200px" />
                                        <col width="*" />
                                        <col width="200px" />
                                        <col width="*" />
                                    </colgroup>

                                    <tbody>
                                        <tr>
                                            <th>업체명 (사업자등록상) <span className="red">＊</span></th>
                                            <td>
                                                <div className="in_box">
                                                {corp_name}
                                                </div>
                                            </td>
                                            <th>사업자등록번호 <span className="red">＊</span></th>
                                            <td>
                                                <div className="in_box">
                                                {corp_nun}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>사업자주소 <span className="red">＊</span></th>
                                            <td>                                                
                                                ({corp_post}) {corp_addr} {corp_addr2}                                               
                                            </td>
                                            <th>대표자  </th>
                                            <td>
                                                <div className="in_box">
                                                {representative}
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <th>업태 <span className="red">＊</span></th>
                                            <td>
                                                <div className="in_box">
                                                {corp_status}
                                                </div>
                                            </td>
                                            <th>종목 <span className="red">＊</span></th>
                                            <td>
                                                <div className="in_box">
                                                {corp_sector}
                                                </div>
                                            </td>
                                        </tr>
                                        
                                    </tbody>
                                </table>

                            </div>

                        </div>

                        <div className="form_item">
                            <h3 className="label">* 계약정보</h3>

                            <div className="top_table">
                                <table>
                                    <colgroup>
                                        <col width="200px" />
                                        <col width="*" />
                                        <col width="200px" />
                                        <col width="*" />
                                    </colgroup>

                                    <tbody>
                                        <tr>
                                            <th>계약상태   <span className="red">＊</span></th>
                                            <td>
                                                <div className="sel_box">
                                                    <select name="contract_status" id="contract_status" value={contract_status} required disabled={true}>
                                                        <option value="Y" >정상</option>
                                                        <option value="F">해지</option>
                                                        <option value="A">신청</option>
                                                        <option value="W">미납</option>
                                                    </select>
                                                </div>
                                            </td>

                                            <th>정산보류구분 <span className="red">＊</span></th>
                                            <td>
                                                <div className="sel_box">
                                                    <select name="contract_delay" id="contract_delay" value={contract_delay} required disabled={true}>
                                                        <option value="Y" >정상</option>
                                                        <option value="F">보류</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <th>로그인 허용 <span className="red">＊</span></th>
                                            <td>
                                                <div className="sel_box table_cont_1">
                                                    <select name="login_able" id="login_able" value={login_able} required disabled={true}>
                                                        <option value="Y">허용</option>
                                                        <option value="N">불가</option>
                                                    </select>
                                                </div>
                                            </td>
                                            <th>중복결제 <span className="red">＊</span></th>
                                            <td>
                                                <div className="sel_box table_cont_1">
                                                    <select name="double_able" id="double_able" value={double_able} required disabled={true}>
                                                        <option value="Y">허용</option>
                                                        <option value="N">불가</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                        <th>건 한도 <span className="red">＊</span></th>
                                            <td>
                                                <div className="flex">
                                                    <div className="check_box">
                                                        <input type="checkbox" name="limit_chk" id="limit_chk" value="Y" disabled={true} checked={limit_chk==='Y'}  />
                                                        <label htmlFor="limit_chk"><span></span>한도설정(체크)</label>
                                                    </div>
                                                    <div className="in_box" style={{width:'100px',marginRight:'10px'}}>
                                                    {limit_price} 원
                                                    </div>
                                                    
                                                </div>
                                            </td>
                                            <th>월 한도 <span className="red">＊</span></th>
                                            <td>
                                                <div className="flex">
                                                    <div className="check_box">
                                                        <input type="checkbox" name="month_chk" id="month_chk" value="Y" disabled={true} checked={month_chk==='Y'} />
                                                        <label htmlFor="month_chk"><span></span>한도설정(체크)</label>
                                                    </div>
                                                    <div className="in_box" style={{width:'214px',marginRight:'10px'}}>
                                                    {month_price} 원
                                                    </div>
                                                    
                                                </div>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>

                            </div>

                        </div>

                        {
                            (pay_type === "") && (
                                <div className="form_item">
                                    <h3 className="label">*  KEY-IN 설정 </h3>

                                    <div className="top_table">
                                        <table>
                                            <colgroup>
                                                <col width="200px" />
                                                <col width="*" />
                                                <col width="200px" />
                                                <col width="*" />
                                            </colgroup>

                                            <tbody>
                                                <tr>
                                                    <th>수기상점코드 선택</th>
                                                    <td>
                                                        <div className="sel_box">
                                                            <select name="sugi_code1" id="sugi_code1" value={sugi_code1} disabled={true} >
                                                                <option value="">=수기상점코드 선택=</option>
                                                                {
                                                                    rendSugiList()
                                                                }
                                                            </select>
                                                        </div>
                                                    </td>
                                                    <th>수기결제조건   <span className="red">＊</span></th>
                                                    <td >
                                                        <div className="flex subs">
                                                            <div>
                                                                <span>수수료</span>
                                                                <div className="in_box" style={{width:'150px',margin:'0 10px'}} >
                                                                    <input type="text" name="commission" id="commission" value={commission} disabled={true} />
                                                                </div>
                                                                <span>%  (부가세 포함)</span>
                                                            </div>

                                                            <div>
                                                                <span>/ D+</span>
                                                                <div className="in_box" style={{width:'100px',margin:'0 10px'}} >
                                                                    <input type="text" name="income" id="income" value={income} disabled={true} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                

                                            
                                                <tr>
                                                    <th>수기결제 <span className="red">＊</span></th>
                                                    <td>
                                                        <div className="sel_box">
                                                            <select name="sugi_able" id="sugi_able" value={sugi_able} disabled={true} >
                                                                <option value="" >선택</option>
                                                                <option value="N" >비인증</option>
                                                                <option value="Y">일반</option>
                                                            </select>
                                                        </div>
                                                    </td>
                                                    <th>결제취소 권한 <span className="red">＊</span></th>
                                                    <td>
                                                        <div className="sel_box">
                                                            <select name="cancel_able" id="cancel_able" value={cancel_able} disabled={true} >
                                                                <option value="" >선택</option>
                                                                <option value="N">권한없음</option>
                                                                <option value="D">당일취소</option>
                                                                <option value="F">정산 전 취소</option>
                                                            </select>
                                                        </div>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>

                                    </div>

                                </div>
                            )
                        }


                        {
                            (pay_type === "Terminal") && ( 

                                <div className="form_item" >
                                    <h3 className="label">*  터미널 결제 </h3>

                                    <div className="top_table">
                                        <table>
                                            <colgroup>
                                                <col width="200px" />
                                                <col width="*" />
                                                <col width="200px" />
                                                <col width="*" />
                                            </colgroup>

                                            <tbody>
                                                <tr>
                                                    <th>PG 선택  </th>
                                                    <td>
                                                        <div className="sel_box">
                                                            <select name="sugi_code1" id="sugi_code1"  value={sugi_code1} disabled={true}>
                                                                <option value="">=수기상점코드 선택=</option>
                                                                {
                                                                    rendSugiList()
                                                                }
                                                            </select>
                                                        </div>
                                                    </td>
                                                    <th>수기결제조건   <span className="red">＊</span></th>
                                                    <td>
                                                        <div className="flex subs">
                                                            <div>
                                                                <span>수수료</span>
                                                                <div className="in_box" style={{width:'150px',margin:'0 10px'}} >
                                                                {commission}
                                                                </div>
                                                                <span>%  (부가세 포함)</span>
                                                            </div>

                                                            <div>
                                                                <span>/ D+</span>
                                                                <div className="in_box" style={{width:'100px',margin:'0 10px'}} >
                                                                {income}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>


                                                <tr>
                                                    <th>단말기 추가 <span className="red">＊</span></th>
                                                    <td colSpan={3}>
                                                        
                                                        <div className="btn_st_01" style={{float:"left", width:"90px", textAlign:"center", cursor:"pointer", marginRight:"10px", margin:"5px"}} 
                                                                onClick={on_terminal_btn_handler} >+추가
                                                        </div>

                                                        <div style={{float:"left", width:"90%"}}> 
                                                            {
                                                                terminal_input_list.map((itmes, index) => (   
                                                                    
                                                                    <div key={index} style={{float:"left", marginLeft:"5px", marginTop:"5px", marginBottom:"5px"}}>
                                                                        <div style={{float:"left"}}>
                                                                        {itmes.name}
                                                                        </div>
            
                                                                    </div>
                                                                
                                                                ))
                                                            }      
                                                        </div>
                                                    </td>                                            
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>                        
                            )                                        
                        }


                        <div className="form_item">
                            <h3 className="label">*  정산정보 </h3>

                            <div className="top_table">
                                <table>
                                    <colgroup>
                                        <col width="200px" />
                                        <col width="*" />
                                        <col width="200px" />
                                        <col width="*" />
                                    </colgroup>

                                    <tbody>
                                        <tr>
                                            <th>은행선택  <span className="red">＊</span></th>
                                            <td >
                                                <div className="sel_box">
                                                    <select name="bank" id="bank" value={bank} disabled={true} >
                                                        <option value="">선택</option>
                                                        {
                                                            bankList.map((v, index)=> {
                                                                return (
                                                                    <option value={v.bank_code} key={index}>{v.bank_name}</option>
                                                                )

                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </td>
                                            <th>E-Mail(계산서)</th>
                                            <td >
                                                <div className="in_box">
                                                {email}
                                                </div>
                                            </td>

                                        </tr>
                                    
                                        <tr>
                                            <th>계좌번호  <span className="red">＊</span></th>
                                            <td>
                                                <div className="in_box">
                                                {account_num}
                                                </div>
                                            </td>
                                            <th>예금주 <span className="red">＊</span></th>
                                            <td>
                                                <div className="in_box">
                                                {account_holder}
                                                </div>
                                            </td>
                                        </tr>

                                        
                                        <tr>
                                            <th>기타  </th>
                                            <td colSpan="3">
                                                <div className="text_area">
                                                {memo}
                                                </div>
                                            </td>
                                        </tr>


                        

                                    </tbody>
                                </table>

                            </div>

                        </div>

                        <div className="form_item">
                            <h3 className="label">*  로고 설정 </h3>
                            <div className="top_table">
                                <table>
                                    <colgroup>
                                        <col width="200px" />
                                        <col width="*" />
                                        <col width="200px" />
                                        <col width="*" />
                                    </colgroup>

                                    <tbody>
                                        <tr>                                            
                                            <th>관리자 로고</th>
                                            <td colSpan={3}>
                                                <div className="in_box">                                                    
                                                    {site_logo_file.site_logo_file == '' ? '' : <img src={'/uploads/'+ site_logo_file.site_logo_file} />}
                                                </div>
                                            </td>
                                        </tr>                                                            
                                    </tbody>
                                </table>
                            </div>
                        </div>  
                        <div className="top_table_btn">
                
                            <button type="button" className=" btn_st_01" onClick={  () => navigate(-1) }>목록</button>
                         
                        </div>

                    </form>

                </div>            



                    
        </div>

        <Footer/>
        </>
    )
}

export default MbranchManagementStoreTerminalForm