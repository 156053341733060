/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import * as axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";

import Header from "../../../include/SiteAdminHeader";
import Footer from "../../../include/SiteAdminFooter";
import queryString from 'query-string';
import $ from 'jquery';
import { PgName, GetPayGubun, GetIp } from "../../../include/ConfigSet";


function SiteAdminPayTransactionHistoryCancel() {

    const navigate = useNavigate();

    const qs = queryString.parse(window.location.search);
    const idx = qs.idx ? qs.idx : '';


    if (idx === '') {
        alert("잘못된 접근입니다.");
        navigate(-1);
    }

    const [created_datetime, set_created_datetime] = useState("");
    const [pg_group, set_pg_group] = useState("");
    const [St_branch_name, set_St_branch_name] = useState("");
    const [cardno, set_cardno] = useState("");
    const [authno, set_authno] = useState("");
    const [amount, set_amount] = useState("");
    const [quota, set_quota] = useState("");
    const [cpid, set_cpid] = useState("");
    const [status, set_status] = useState("");
    const [daoutrx, set_daoutrx] = useState("");
    const [calculate_date_re, set_calculate_date_re] = useState("");
    const [orderno, set_orderno] = useState("");
    const [store_id, set_store_id] = useState("");
    const [userIp, set_userIp] = useState("");

    const cancelBtn1 = () => {
        if (window.confirm("정말 취소하시겠습니까?")) {
            fn_cancel();
            return;
        }
    }

    const cancelBtn2 = () => {
        if (window.confirm("정말 취소하시겠습니까?")) {
            fn_cancel2();
            return;
        }
    }


    function cancel_out(status) {
        if (status === "B") {
            return (
                <>
                    <button type="button" className="blue btn_st_01 cancelBtn" onClick={cancelBtn1} >전체취소 실행</button>
                </>
            )
        }

        if (status === "E") {
            return (
                <>
                    <button type="button" className="blue btn_st_01 cancelBtn2" onClick={cancelBtn2}>전체취소 실행</button>
                </>
            )
        }
    }


    function fn_cancel() {

        let body = {
            idx: idx,
            trxid: daoutrx

        }

        if (pg_group.indexOf("welcomepay") !== -1) {
            welcomePay_cancel(body);
        } else if (pg_group.indexOf("wonsung") !== -1) {
            //진행중
            //wonsungPay_cancel(body);
        }


    }


    function fn_cancel2() {
        let body = {
            idx: idx,
            trxid: daoutrx

        }

        // 정산후취소
        axios.post('/payment/payjoa_cancel2.php', body, { withCredentials: true }).then(function (res2) {

            if (res2.data.RESULTCODE === "0000") {
                alert("취소되었습니다.");
                navigate('/SiteAdmin/_pay/transactionHistoryList');
            } else {
                alert("취소에 실패했습니다.");
            }

        })
    }

    /**
     * 결제 정보 출력
     */
    const transaction_history_view = () => {

        let bodyPage = {
            idx: idx
        }

        axios.post('/api/management/pay/transaction_history_view', bodyPage).then(function (res) {

            console.log('res : ', res.data);

            res.data.map((v, index) => {
                set_created_datetime(v.created_datetime);
                set_pg_group(v.pg_group);
                set_St_branch_name(v.St_branch_name);
                set_cardno(v.cardno);
                set_authno(v.authno);
                set_amount(v.amount);
                set_quota(v.quota);
                set_cpid(v.cpid);
                set_status(v.status);
                set_daoutrx(v.daoutrx);
                set_calculate_date_re(v.calculate_date_re);
                set_orderno(v.orderno);
                set_store_id(v.store_id);

            })

        })

    }

    /* ***************************
        * PG 웰컴페이먼츠 결제 취소
        * @param {*} body 
        * ***************************/
    const welcomePay_cancel = (body) => {

        let param = {            
            payment_idx: body.idx,
            store_id: store_id,
            trxid: body.trxid,
            pay_type: 'CREDIT_CARD',
            transaction_type: 'CANCEL',
            millis: Date.now(),
            ip_address: userIp      
        }

        axios.post('/api/welcomePay/welcomePayCancel', param).then(function (res) {

            if (res.data.result_code === '0000') {
                alert("취소되었습니다.");
                navigate('/SiteAdmin/_pay/transactionHistoryList');
            } else {
                alert("취소 실패! 사유 : " + res.data.result_message);
            }

        }, {
            headers: { 'Content-Type': 'application/json' }
        })

    }

    /* ***************************
        * PG 원성글로벌 결제 취소
        * @param {*} body 
        * ***************************/
    const wonsungPay_cancel = (body) => {

        let param = {
            payment_idx: body.idx,
            store_id: store_id,
            trxid: body.trxid,
            pay_type: 'CREDIT_CARD',
            transaction_type: 'CANCEL',
            millis: Date.now(),
            ip_address: userIp
        }

        axios.post('/api/wonsungPay/wonsungPayCancel', param).then(function (res) {

            if (res.data.result_code === '0000') {
                alert("취소되었습니다.");
                navigate('/SiteAdmin/_pay/transactionHistoryList');
            } else {
                alert("취소 실패! 사유 : " + res.data.result_message);
            }

        }, {
            headers: { 'Content-Type': 'application/json' }
        })

    }
    

    useEffect(() => {

        transaction_history_view();

        GetIp().then((ipInfo) => {            
            set_userIp(ipInfo.IPv4);
        });

    }, []);



    const onSubmitHandler = (event) => {
        event.preventDefault();

        let body = {
            idx: qs.idx,

        }

        axios.post('/api/management/update', body)
            .then(function (response) {

                if (response.data === "ModOk") {
                    //navigate('/SiteAdmin/_management/list');
                    window.location.reload();

                } else {
                    alert("시스템 에러");

                }

                //console.log(response.data);

            }, [])

    }

    return (
        <>
            <div id="wrap">
                <Header />


                <div id="container" data-menu-page="management_list">
                    <form onSubmit={onSubmitHandler}>
                        <h3 className="page_ttl">결제내역</h3>
                        <div className="form_item">


                            <div className="top_table">
                                <table>
                                    <colgroup>
                                        <col width="150px" />
                                        <col width="*" />
                                        <col width="150px" />
                                        <col width="*" />
                                    </colgroup>

                                    <tbody>
                                        <tr>
                                            <th>거래일자</th>
                                            <td className="created_date_td">{created_datetime}</td>
                                            <th>PG</th>
                                            <td className="pg_name_td">{PgName(pg_group)}</td>
                                        </tr>
                                        <tr>
                                            <th>구분</th>
                                            <td>{GetPayGubun(status)}</td>
                                            <th>결제구분</th>
                                            <td className="sortation_td">신용</td>
                                        </tr>
                                        <tr>
                                            <th>가맹점</th>
                                            <td className="st_branch_td">{St_branch_name}</td>
                                            <th>터미널코드</th>
                                            <td className="cpid_td">{cpid}</td>
                                        </tr>
                                        <tr>
                                            <th>카드번호</th>
                                            <td>{cardno.substr(0, cardno.length - 7) + "****" + cardno.substr(cardno.length - 3, 3)}</td>
                                            <th>승인번호</th>
                                            <td className="authno_td">{authno}</td>
                                        </tr>

                                        <tr>
                                            <th>금액</th>
                                            <td className="amount_td">{amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원</td>
                                            <th>할부</th>
                                            <td className="quota_td">{(quota == "00" ? '일시불' : quota)}</td>
                                        </tr>



                                        <tr>
                                            <th>PG정산예정</th>
                                            <td className="calculate_date_re_td">{calculate_date_re}</td>
                                            <th>가맹점정산예정</th>
                                            <td className="calculate_date_re_td">{calculate_date_re}</td>
                                        </tr>

                                        <tr>
                                            <th>취소할 금액</th>
                                            <td colSpan="3">
                                                <span className="red" style={{ "fontWeight": "700" }}>{amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원</span>
                                            </td>
                                        </tr>


                                    </tbody>
                                </table>

                            </div>

                        </div>


                        <div className="top_table_btn">

                            <button type="button" className="btn_st_01" onClick={() => navigate(-1)}>이전으로</button>
                            {
                                cancel_out(status)
                            }
                        </div>
                    </form>
                </div>

            </div>

            <Footer />
        </>
    )


}
export default SiteAdminPayTransactionHistoryCancel