import * as axios from 'axios';
import React, {useEffect, useState} from 'react'
import Header from "../../../include/SiteAdminHeader";
import Footer from "../../../include/SiteAdminFooter";
import readXlsxFile from "read-excel-file";
import OpenLogComment from "../../../include/OpenLogComment";
import { GetIp } from "../../../include/ConfigSet";

function SiteAdminSettlementDataUploadTerminal() {

    const [viewContent , setViewContent] = useState([]);    
    const [order_no, set_order_no] = useState("");
    const [userIp, setUserIp] = useState("");
    const [cardcode, set_cardcode] = useState("00");                // 카드코드
    const [charging_type, set_charging_type] = useState("18");      // 인증방식   
    const [pg_group, set_pg_group] = useState('');   
    const [sugiList, setSugiList] = useState([]);
    const on_pg_group_handler = (event) => {
        set_pg_group(event.currentTarget.value)
    }

    //운영로그 상태
    const [log_set, set_log_set] = useState(false);
    const [log_set_msg, set_log_set_msg] = useState('');
    const [pageReload, set_pageReload] = useState('');    

    const handleupload = (e) => {        
        readXlsxFile(e.target.files[0]).then((rows) => {
          //console.log(rows);
          setViewContent(rows);


            // let rowsdt = [];
            // let i = 0;
            // eslint-disable-next-line array-callback-return
            // rowsdt = rows.map(el => {                                
            //     if(i > 0) {
            //         return { ...el, key: i}
            //     }
            //     i++;
            // })
            // setViewContent(rowsdt);

        });
    };

    const FnSampleDown =()=>{        
        window.location.href = "/data/매출데이터.xlsx";
    }

    const Fn_Excel_Date_Upload =()=>{
        // eslint-disable-next-line array-callback-return
        if(pg_group === ""){
            alert("PG사를 선택해주세요.");
            return;
        }


        if(viewContent.length <= 1){
            alert("업로드할 데이터가 없습니다.");
            return;
        }

        if(viewContent.length > 1){
            // eslint-disable-next-line no-restricted-globals
            if(!confirm("정말로 등록하시겠습니까?")){
                return;
            }
        }

        viewContent.map((row, index)=>{            
            if(index > 0){             
                
                let excelObj = { 
                    pg_group: pg_group,
                    store_id: (row[0] === null ? '' : row[0]),
                    user_name: row[1],
                    user_hp: row[2],
                    user_email: row[3],
                    goods_name: row[4],
                    price: row[5],
                    cardcode: row[6],
                    card_num: row[7],
                    month: row[8],
                    year: row[9],
                    installment: row[10],
                    birth: row[11],
                    pws: row[12],
                    charging_type: charging_type,
                    order_no: order_no,
                    //regid: sessionStorage.getItem("loginId"),
                    regid: (row[0] === null ? '' : row[0]),
                    userIp: userIp         
                }  

                let retmsg = '';
                if(excelObj.card_num){

                    console.log('excelObj : ', excelObj);

                    //on_pay_handler(excelObj);

                    //console.log('excelObj : ', excelObj);
                                        
                    axios.post('/api/store/pay/payAdd_Auto', excelObj).then(function(response){                                    
            
                        // console.log(' response : ', response);
                        if(response.data == "-1"){    
                            retmsg = `가맹점 아이디가 없습니다. [${excelObj.user_name}]\r\n`;
                            console.log('retmsg : ', retmsg);
                        }
                        if(response.data != "NO"){        
                                                                                
                            let body = {
                                order_no: response.data
                            }
                                            
                            axios.post('/api/pg/notPayAdd', body).then(function(res){
                                // console.log(res);
                                if(res.data.result_code != '0000'){
                                    console.log("결제 실패! 사유 : " + res.data.result_message + "\n코드 : " + res.data.result_message);
                                }
            
                            },[]);                
            
                        }else{
                            
                        }    
                    },[]);                    
                }
                
                


            }
        });

        alert("등록이 완료되었습니다.");
        set_log_set_msg('엑셀 업로드');
        set_log_set(true);
        set_pageReload('reload');
    }

    // const on_pay_handler = (row) => {

    //     let body = {
    //         pg_group: pg_group,
    //         store_id: (row[0] === null ? '' : row[0]),
    //         user_name: row[1],
    //         user_hp: row[2],
    //         user_email: row[3],
    //         goods_name: row[4],
    //         price: row[5],
    //         cardcode: row[6],
    //         card_num: row[7],
    //         month: row[8],
    //         year: row[9],
    //         installment: row[10],
    //         birth: row[11],
    //         pws: row[12],
    //         charging_type: charging_type,
    //         order_no: order_no,
    //         regid: sessionStorage.getItem("loginId"),
    //         userIp: userIp
    //         // charging_type: charging_type                       
    //     }
    
    //     axios.post('/api/terminal/store/pay/payAdd_Auto', body)
    //     .then(function(response){                                    
    //         console.log(response.data);              
    //         if(response.data !== "NO"){                       
    //             Fn_PayJoaNoPay(response.data);                             
    //         }        
    //     },[])                
    // }

    // const Fn_PayJoaNoPay =(pOrder_no)=>{
                
    //     let body = {
    //         order_no: pOrder_no
    //     }

    //     axios.post('/payment/payjoaNoPay_terminal.php', body,{ withCredentials: true }).then(function(res2){
    //         //console.log(res2);
    //         if(res2.data.RESULTCODE === "0000"){
    //             console.log(res2.data.ERRORMESSAGE);                
    //         }else{
    //             if(res2.data.RESULTCODE !== null){                   
    //                 console.log("결제 실패! 사유 : " + res2.data.ERRORMESSAGE + "\n코드 : " + res2.data.RESULTCODE);
    //             }else{                   
    //                 console.log("결제에 실패했습니다.");                    
    //             }
    //         }
        
    //     })   
    // }


    const Fn_PayJoaNoPay_Cancel =(pOrder_no)=>{
        
        console.log('pOrder_no : ', pOrder_no);

        let body = {
            order_no: pOrder_no
        }
                
        axios.post('/payment/payjoaNoPay_terminal_cancel.php', body,
        { withCredentials: true }
        ).then(function(res2){
            //console.log(res2);
            if(res2.data.RESULTCODE === "0000"){
                console.log(res2.data.ERRORMESSAGE);                
            }else{
                if(res2.data.RESULTCODE !== null){                   
                    console.log("결제 실패! 사유 : " + res2.data.ERRORMESSAGE + "\n코드 : " + res2.data.RESULTCODE);
                }else{                   
                    console.log("결제에 실패했습니다.");                    
                }
            }
        
        })   
    }

    // 수기상점코드 리스트 가져오기
    function getSugiList(){
             
        axios.post('/api/terminal/terminal/sugi_sel_list',{}).then(function(res){            
            setSugiList(res.data);
        })

    }

    function rendSugiList(){
        return (
                sugiList.map((v, index) => {
                    return (
                        <option value={v.pg} key={v.idx} >{v.sname}</option>
                    )
                })
        )
    }

    useEffect(() => {              
        if(log_set === true){
            set_log_set(false);            
        }
    }, [log_set])

    useEffect(() => {
       
        getSugiList();

        GetIp().then((ipInfo) => {            
            setUserIp(ipInfo.IPv4);
        });

    },[]);

   
    const Fn_Api_Test =()=>{


        let body = {
            remainAmt:'', 
            cancelYN:'N', 
            mid:'ttttt1027m', 
            amt:'5000', 
            appNo:'42922431', 
            ccDnt:'test01', 
            buyerId:'', 
            cardNo:'942520******1493', 
            tid:'fna501027m01032107130030110322', 
            tPhone:'', 
            ordNm:'이동호', 
            catId:'test01', 
            connCD:'0003', 
            //ordNo:'20210713002724128507', 
            ordNo:generateRandomCode(20),             
            ediNo:generateRandomCode(10), 
            payMethod:'CARD', 
            quota:'00', 
            appDtm:'20210713002724', 
            goodsName:'상품명2', 
            appCardCd:'01', 
            notiDnt:'20210714104710',
            socHpNo:'01044522280'
            
        }


        
        let qdata = 'gid=onon53000g';
        qdata = qdata + '&remainAmt=0';
        qdata = qdata + '&cancelYN=N';
        qdata = qdata + '&mid=onon57000m';
        qdata = qdata + '&amt=5000';
        qdata = qdata + '&appNo=43238578';
        qdata = qdata + '&ccDnt=';
        qdata = qdata + '&buyerId=';
        qdata = qdata + '&cardNo=53704400****974*';
        qdata = qdata + '&tid=onon57000m01032305051254171111';
        qdata = qdata + '&vid=onon13001a';
        qdata = qdata + '&tPhone=';
        qdata = qdata + '&ordNm=';
        qdata = qdata + '&catId=1046872724';
        qdata = qdata + '&connCd=0003';
        qdata = qdata + '&ordNo=18914720230505121111';
        qdata = qdata + '&ediNo=2023050512C1411111';
        qdata = qdata + '&payMethod=CARD';
        qdata = qdata + '&quota=00';
        qdata = qdata + '&appDtm=20230505125417';
        qdata = qdata + '&goodsName=1046872724';
        qdata = qdata + '&appCardCd=01';
        qdata = qdata + '&notiDnt=20230505125905';
        
        axios({
            method: "POST",
            url: '/api/korpay/receive',
            mode: "cors",
            headers: {
              "Content-Type": "multipart/form-data", // Content-Type을 반드시 이렇게 하여야 한다.
            },
            data: qdata, // data 전송시에 반드시 생성되어 있는 formData 객체만 전송 하여야 한다.
        }).then(function(response){                                    
            console.log(response.data);                                
            //Fn_PayJoaNoPay(body.ordNo);
        },[]);
     


        /*
        axios.post('/api/korpay/receive', body)
        .then(function(response){                                    
            console.log(response.data);                                
            Fn_PayJoaNoPay(body.ordNo);
        },[])  
        */

    }




    


    const Fn_Api_Test_Cancel =()=>{


        let qdata = 'gid=onon53000g';
        qdata = qdata + '&remainAmt=0';
        qdata = qdata + '&cancelYN=Y';
        qdata = qdata + '&mid=onon57000m';
        qdata = qdata + '&amt=10000';
        qdata = qdata + '&appNo=43238578';
        qdata = qdata + '&ccDnt=20230505141036';
        qdata = qdata + '&buyerId=';
        qdata = qdata + '&cardNo=53704400****974*';
        qdata = qdata + '&tid=onon57000m01032305051254170073';
        qdata = qdata + '&vid=onon13001a';
        qdata = qdata + '&tPhone=';
        qdata = qdata + '&ordNm=';
        qdata = qdata + '&catId=1046872724';
        qdata = qdata + '&connCd=0003';
        qdata = qdata + '&ordNo=18914720230505125417';
        qdata = qdata + '&ediNo=2023050512C1412944';
        qdata = qdata + '&payMethod=CARD';
        qdata = qdata + '&quota=00';
        qdata = qdata + '&appDtm=20230505125417';
        qdata = qdata + '&goodsName=1046872724';
        qdata = qdata + '&appCardCd=01';
        qdata = qdata + '&notiDnt=20230505141249';

      
        axios({
            method: "POST",
            url: '/api/korpay/receive',
            mode: "cors",
            headers: {
              "Content-Type": "multipart/form-data",
            },
            data: qdata,
        }).then(function(response){                                    
            console.log(response.data);                                
         
        },[]);


    }
    

    function generateRandomCode(n) {
        let str = ''
        for (let i = 0; i < n; i++) {
          str += Math.floor(Math.random() * 10)
        }
        return str
    }

    return (
        <>

        {(log_set === true) && ( <OpenLogComment pageMode={`정산관리(터미널) > 매출데이터업로드 > ${log_set_msg}`} pageReload={pageReload} /> )} {/* 운영로그 기록 */} 

        <div id="wrap">
            <Header/>
            
            <div id="container" data-menu-page="data_upload">            
                <h3 className="page_ttl">매출데이터업로드</h3>
                <div className="form_item">

                    {/* <button className="btn_st_02" onClick={Fn_Api_Test}> <i></i> Korpay api 결제</button>
                    <button className="btn_st_02" onClick={Fn_Api_Test_Cancel}> <i></i> Korpay api 결제 취소</button> */}

                    <h3 className="label">* 매출데이터업로드</h3>                        
                    <div className="top_table">
                        <table>
                            <colgroup>
                                <col width="200px" />
                                <col width="*" />
                                <col width="200px" />
                                <col width="*" />
                            </colgroup>
                            <tbody>                                                       
                                {/* <tr>
                                    <th>거래구분 선택</th>
                                    <td colSpan="3">
                                        <div className="flex">
                                            <div className="btn_wrap">
                                                <Link to="/SiteAdmin/_settlement_terminal/data_upload_ins" className="btn_st_02 gray_line" >정산결제</Link>
                                                <Link to="/SiteAdmin/_settlement_terminal/data_upload_form" className="btn_st_02 blue_line" >[KEY-IN] 수기결제 거래생성</Link>
                                               
                                                <Link to="/SiteAdmin/_settlement_terminal/data_upload_home" className="btn_st_02 blue_bg" >[홈페이지결제창] 인증결제 거래생성</Link>
                                            </div>
                                        </div>
                                    </td>
                                </tr> */}

                                <tr>
                                    <th>PG사 선택</th>
                                    <td colSpan="3">
                                        <div className="flex">
                                            <div className="sel_box" style={{"width": "200px"}}>
                                                <select name="pg_group" id="pg_group" required onChange={on_pg_group_handler} >
                                                    <option value="" >=선택=</option>
                                                        {
                                                            rendSugiList()
                                                        }
                                                </select>
                                            </div>
                                            <div className="in_box" style={{"width": "260px"}}>                                                
                                                <input type="file" id="file" onChange={handleupload} />                                                
                                            </div>
                                            <div className='btn_wrap'>
                                                <button className="btn_st_02 blue_line" onClick={FnSampleDown}>샘플양식 파일 다운로드</button>
                                                <button className="btn_st_02 els_btn" onClick={Fn_Excel_Date_Upload}> <i></i> 엑셀 업로드</button>
                                            </div>
                                            <span style={{marginLeft:'6px'}}>[ 엑셀업로드시 정산완료 항목으로 추가됩니다 ]</span>
                                        </div>
                                    </td>
                                </tr>
                               
                            </tbody>
                        </table>
                    </div>

                                          
                </div>



                <div className="bot_item">
                   
                    <div className="table_box">                        
                        <div className="bot_info">
                            <div className="left">엑셀 업로드파일</div>
                        </div>
                        <table>

                            <thead>

                                <tr>                                    
                                    <th>가맹점아이디</th>
                                    <th>고객성명</th>
                                    <th>휴대폰번호</th>
                                    <th>이메일</th>
                                    <th>상품명</th>
                                    <th>결제금액</th>
                                    <th>카드사</th>
                                    <th>카드번호</th>
                                    <th>유효기간(월)</th>
                                    <th>유효기간(년)</th>
                                    <th>할부기간</th>
                                    <th>생년월일</th>
                                    <th>비번(앞2자리)</th>
                                </tr>

                            </thead>

                            <tbody>

                                {
                                    viewContent.map((v, index) => {
                                        if(index === 0){
                                            return(<></>)
                                        }
                                        return (
                                            <>
                                            <tr>                                                
                                                <td>{v[0]}</td>
                                                <td>{v[1]}</td>
                                                <td>{v[2]}</td>
                                                <td>{v[3]}</td>
                                                <td>{v[4]}</td>
                                                <td>{v[5]}</td>
                                                <td>{v[6]}</td>
                                                <td>{v[7]}</td>
                                                <td>{v[8]}</td>
                                                <td>{v[9]}</td>
                                                <td>{v[10]}</td>   
                                                <td>{v[11]}</td>  
                                                <td>{v[12]}</td>
                                                <td>{v[13]}</td>                                         
                                            </tr>
                                            </>
                                        );
                                    })
                                }


                            </tbody>
                        </table>
                    </div>

                   
                </div>


            </div>
            


            
        </div>

        <Footer/>
        </>
    )

    
}
export default SiteAdminSettlementDataUploadTerminal