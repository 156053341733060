import * as axios from 'axios';
import React, {useEffect, useState, useRef } from 'react'
import {
    Link, useLocation, useNavigate
  } from "react-router-dom";

import HeaderMo from "../../../include/StoreHeaderM";

import queryString from 'query-string';
import PropTypes from 'prop-types';
import jquery from 'jquery';
import $ from 'jquery';
import { DoublePayChk, GetIp } from "../../../include/ConfigSet";


function StoreManagementPayFormStep2() {

    const navigate = useNavigate();
    const location = useLocation();

    // 파라메터 정보 저장
    const queryParam = location.search.substring(1, location.search.length);
 
    const qs = queryString.parse(window.location.search);
    const sIdx = qs.idx ? qs.idx : '';

    if(sIdx === ''){
        alert("잘못된 접근입니다.");
        navigate(-1);
    }
    
    // 문자열의 쿼리스트링을 Object로 변환
    // const query = queryString.parse(location.search);    
    
    // const [viewContent , setViewContent] = useState([]);                // 게시판 내역    
    // const [totalCnt , setTotalCnt] = useState(0);                       // 게시판 총 수    
    // const [nums , setNums] = useState(0);                               // 게시판 넘버    
    // const [pageMax, setPageMax] = useState(10);                         // 페이징 페이징에 한번에 보일 수량    
    // const [gListRows, set_gListRows] = useState(10);                    // 페이징 한번에 나오는 리스트 수    
    // const [url , setUrl] = useState(queryParam);                        // 페이징 url 정보    
    // const [page, setPage] = useState(1);                                // 페이징 현재 페이지
    // const [load, setLoad] = useState(false);                            // 사전 처리 완료 체크    
    // const [loading, setLoading] = useState(false);                      // 로딩 제어관련        
    // const [searchCates, setSearchCates] = useState('goods_name');       // 검색 카테고리        
    // const [searchWord, setSearchWord] = useState('');                   // 검색 키워드    

    const [birth, set_birth] = useState("");                            // 생년월일    
    const [pws, set_pws] = useState("");                                // 비밀번호    
    //const [user_name, set_user_name] = useState("");                    // 고객성명    
    const [user_hp, set_user_hp] = useState("");                        // 휴대폰번호    
    const [user_email, set_user_email] = useState("");                  // 고객이메일    
    const [card_num, set_card_num] = useState("");                      // 카드번호    
    const [month, set_month] = useState("");                            // 유효기간 월    
    const [year, set_year] = useState("");                              // 유효기간 년도    
    const [installment, set_installment] = useState("00");              // 할부기간
    const [limitDay, set_limitDay] = useState("");
    const [limitChk, set_limitChk] = useState("");
    const [paytype, set_paytype] = useState("");
    const [reg_id, set_reg_id] = useState("");
    const [goods_name, set_goods_name] = useState("");
    const [customer_name, set_customer_name] = useState("");
    const [customer_hp, set_customer_hp] = useState("");
    const [price, set_price] = useState("");
    const [memo, set_memo] = useState("");

    const [sellerName, set_sellerName] = useState("");                  //판매자 정보 관련
    const [sellerHp, set_sellerHp] = useState("");
    const [sellerAddr, set_sellerAddr] = useState("");
    
    const [charging_type, set_charging_type] = useState("18");          // 인증방식    
    const [order_no, set_order_no] = useState("");                      // 주문번호    
    // const [regid, set_regid] = useState("");                            // 로그인 아이디
    const [userIp, set_userIp] = useState("");
    const [pg, set_pg] = useState(""); 
    const [pg_mid, set_pg_mid] = useState("");
    const [pg_apiKey, set_pg_apiKey] = useState("");
    const [pg_ivValue, set_pg_ivValue] = useState("");
    const [corp_class, set_corp_class] = useState("");          // 사업자 구분
    const [cardType, set_cardType] = useState("01");
    // // 카테고리 변경시
    // const onSearchCates = (event) => {
    //     setSearchCates(event.currentTarget.value);
    // }
    // // 키워드 입력시
    // const onSearchWord = ((event)=>{
    //     setSearchWord(event.currentTarget.value);
    // });
    const on_birth_handler = (event) => {
        set_birth(event.currentTarget.value)
    }
    const on_pws_handler = (event) => {
        set_pws(event.currentTarget.value)
    }
    const on_user_hp_handler = (event) => {
        set_user_hp(event.currentTarget.value)
    }
    const on_card_num_handler = (event) => {
        set_card_num(event.currentTarget.value)
    }
    const on_cardType_handler = (event) => {
        set_cardType(event.currentTarget.value)
    }
    const on_month_handler = (event) => {
        set_month(event.currentTarget.value)
    }
    const on_year_handler = (event) => {
        set_year(event.currentTarget.value)
    }
    const on_installment_handler = (event) => {
        set_installment(event.currentTarget.value)
    }
    const on_goods_name_handler = (event) => {
        set_goods_name(event.currentTarget.value)
    }
    const on_charging_type_handler = (event) => {
        set_charging_type(event.currentTarget.value)
    }


    function getOrderNo(){
        
       
        axios.post('/api/store/pay/getOrderNo').then(function(res){            

            if(res.data){
                set_order_no(res.data);  
            }            

        });
    }

    useEffect(() => {
        getOrderNo();
    },[]);

    function charging_out(charging_type){
        if(charging_type === "13"){
            return (
                <>
                    <dl>
                        <dt>생년월일 6자리 <span className="blue">＊</span></dt>
                        <dd> 
                            <div className='flex'>
                                <div className="in_box">
                                    <input type="text" maxLength="6" name="birth" id="birth" value={birth} onChange={on_birth_handler} required />
                                </div>
                                <p> (예 : 880704)</p>
                            </div>
                        </dd>
                    </dl>
                    <dl>
                        <dt>비밀번호 <span className="blue">＊</span></dt>
                        <dd> 
                            <div className='flex'>
                                <div className="in_box">
                                    <input type="password" maxLength="10" name="pws" id="pws" value={pws} onChange={on_pws_handler} required />
                                </div>
                                <p>**</p>
                            </div>
                        </dd>
                    </dl>
                  
                
                </>
            )

        }

    }

    const on_pay_handler = (event) => {

        event.preventDefault();
        
        if(reg_id===""){
            alert("필수값 누락! 로그인이 필요합니다.");
            return false;
        }


        const today = new Date();
        const nYear = today.getFullYear();
        const nMonth = ('0' + (today.getMonth() + 1)).slice(-2); // 1월이 0부터 시작하므로 +1을 해줌
        const nDay = ('0' + today.getDate()).slice(-2);

        const dateYmd = `${nYear}${nMonth}${nDay}`;

        DoublePayChk(dateYmd, card_num, reg_id, price).then((result) => {

            if(result === 'E1'){
                alert("당일 한도 금액이 초과 되었습니다.");
                return;
            }
            if(result === 'E2'){
                alert("이번달 한도 금액이 초과 되었습니다.");
                return;
            }
            if(result === 'E3'){
                alert("금일 해당카드로 결제내역이 있습니다.");
                return;
            }
            // if(result === "T"){
            //     alert("금일 해당카드로 결제내역이 있습니다.");
            // }else{

                let body = {
                    user_name: customer_name,
                    user_hp: user_hp,
                    user_email: user_email,
                    goods_name: goods_name,
                    price: price,
                    card_num: card_num,
                    month: month,
                    year: year,
                    installment: installment,
                    birth: birth,
                    pws: pws,
                    charging_type: charging_type,
                    order_no: order_no,
                    regid: reg_id
                }
                
                axios.post('/api/store/pay/payAdd', body)
                .then(function(response){
                    
                    let paymenttype = "";
                    
                    if(response.data === "AddOk"){

                        if(paytype==="s"){
                            paymenttype = "2";

                        }else if(paytype==="e"){
                            paymenttype = "3";

                        }
                        
                        
                        body.order_no = order_no;
                        body.sIdx = sIdx;
                        body.paymenttype = paymenttype;

                        
                        if (pg.includes('welcomepay')){
                            //PG 웰컴페이먼츠
                            welcomePay_send(body);
                        } else if (pg === 'wonsung') {
                            //PG 원성글로벌
                            wonsungPay_send(body);
                        } else if(pg === 'payjoa'){
                            //PG 페이조아
                            payjoa_send(body);
                        }
                    
                    }else if(response.data === "Err1"){    
                        alert("이미 등록된 주문번호입니다.");
                        return false;
                    
                    }else{
                        alert("시스템 에러");
                        
                    }
                    
                    console.log(response.data);
                    
                },[])

            // }
        
        })

    }


    const goodsModChk =()=>{

        let bodyPage = {
            idx: sIdx
        }
        //console.log("bodyPage : " + bodyPage);

        axios.post('/api/store/management/goodsModChk', bodyPage).then(function(res){

            if(res.data.length < 1){
                alert("이미 결제되었거나, 주문 가능한 일자가 초과되었습니다.");
                window.close();
            }
            
            console.log('res : ', res);

            res.data.map((v, index) => {
                set_goods_name(v.goods_name);
                set_customer_name(v.customer_name);
                set_customer_hp(v.customer_hp);
                set_price(v.price);
                set_memo(v.memo);
                set_reg_id(v.reg_id);
                set_limitDay(v.limitDay);
                set_limitChk(v.limitChk);
                set_paytype(v.paytype);            
                set_pg(v.pg);
                set_pg_mid(v.pg_mid);
                set_pg_apiKey(v.pg_apiKey);
                set_pg_ivValue(v.pg_ivValue);  
                set_corp_class(v.corp_class);

                let bodyPage2 = {
                    loginId: v.reg_id
                }
                                    
                axios.post('/api/store/userInfo', bodyPage2).then(function(response){    
                  
                    // 등록자 ID
                    set_sellerName(response.data[0].branch_name);
                    set_sellerHp(response.data[0].user_phone);
                    set_sellerAddr(response.data[0].corp_addr);

                    let body3 = {
                        user_id: v.reg_id
                    }
    
                    axios.post('/api/store/pay/getPgInfo', body3).then(function(res){            
                        if(res.data[0].cerify==="Y"){
                            set_charging_type("13");
                        }else{
                            set_charging_type("18");
                        }
                    },[])



                });
                
            
            })
            
        }) 

    }


    useEffect(() => {

        goodsModChk();
        
        GetIp().then((ipInfo) => {            
            set_userIp(ipInfo.IPv4);
        });

    }, []);

    function pays(){
        let lastNum = customer_hp.slice(-4);
        if( $("#chkPhone").val() == ""){
            alert("본인 확인을 위해서 휴대폰 뒷자리 4자리를 입력해주세요.");
            $("#chkPhone").focus();
            return false;
        }

        if( lastNum != $("#chkPhone").val()){
            alert("인증번호가 일치하지 않습니다.");
            $("#chkPhone").focus();
            return false;
        }
        navigate('/Store/_management/payFormStep2?idx='+sIdx);
        
    }


    
    /* ***************************
     * PG 웰컴페이먼츠
     * @param {*} body 
     * ***************************/
    const welcomePay_send =(body) =>{

        let param = {
            pg: pg,
            pg_mid: pg_mid,
            pg_apiKey: pg_apiKey,
            pg_ivValue: pg_ivValue,
            regid: reg_id,
            pay_type: 'CREDIT_CARD',
            pay_method: 'CREDIT_UNAUTH_API',
            card_no: card_num,
            card_expiry_ym: year.substr(2, 2) + month,
            order_no: body.order_no,
            user_name: customer_name,
            amount: price,
            product_name: goods_name,
            card_sell_mm: installment,
            millis: Date.now(),            
            userIp: userIp,
            paymenttype: body.paymenttype
        }

        axios.post('/api/welcomePay/welcomePaySend', param).then(function(res){                        
            //console.log('res : ', res);                
            if(res.data){                
                if(res.data.result_code === '0000'){
                    alert(res.data.result_message);         
                    navigate('/Store/_management/payFormStep3?idx=' + body.sIdx);           
                }else{
                    alert(res.data.result_message);  
                    getOrderNo();                    
                }                            
            }else{
                getOrderNo();
                alert("결제에 실패했습니다.");  
            }
        },{
            headers: {'Content-Type': 'application/json'}
        })

    }


    /* ***************************
     * PG 원성글로벌
     * @param {*} body 
     * ***************************/
    const wonsungPay_send = (body) => {

        let param = {
            pg: pg,
            pg_mid: pg_mid,
            pg_apiKey: pg_apiKey,
            pg_ivValue: pg_ivValue,
            regid: reg_id,
            card_no: card_num,
            card_expiry_ym: year.substr(2, 2) + month,
            order_no: body.order_no,
            user_name: customer_name,
            user_hp: user_hp,
            amount: price,
            product_name: goods_name,
            card_sell_mm: installment,
            millis: Date.now(),
            corp_class: corp_class,
            cardType: cardType,
            userIp: userIp,
            paymenttype: body.paymenttype
        }

        axios.post('/api/wonsungPay/wonsungPaySend', param).then(function (res) {

            console.log('res : ', res);
        
            if (res.data) {
                if (res.data.result_code === '0000') {
                    alert(res.data.result_message);
                    navigate('/Store/_management/payFormStep3?idx=' + body.sIdx);
                } else {
                    alert(res.data.result_message);
                    getOrderNo();
                }
            } else {
                getOrderNo();
                alert("결제에 실패했습니다.");
            }

        }, {
            headers: { 'Content-Type': 'application/json' }
        });
    }

    /* ***************************
     * PG payjoa 결제 
     * @param {*} body 
     * ***************************/    
    const payjoa_send =(body) => {

        axios.post('/payment/payjoa.php', body,{ withCredentials: true }).then(function(res2){
            //console.log(res2);
            if(res2.data.RESULTCODE === "0000"){
                alert(res2.data.ERRORMESSAGE);
                navigate('/Store/_management/payFormStep3?idx='+sIdx);
                
            }else{
                if(res2.data.RESULTCODE !== null){
                    getOrderNo();
                    alert("결제 실패! 사유 : " + res2.data.ERRORMESSAGE + "\n코드 : " + res2.data.RESULTCODE);
                }else{
                    getOrderNo();
                    alert("결제에 실패했습니다.");
                    
                }
            }                        
        });
    }

    return(
        <>
            <div id="wrap_mo">
                <HeaderMo />

                <form onSubmit={on_pay_handler}>
                
                    <div id="container" data-menu-page="easy_pay">
                        <h3 className='mo_page_ttl'>간편결제</h3>

                        <input type="hidden" name="order_no" id="order_no" value={order_no} />
                        <input type="hidden" name="regid" id="regid" value={reg_id}  />
                        <input type="hidden" name="user_name" id="user_name" value={customer_name} />
                        <input type="hidden" name="user_hp" id="user_hp" value={customer_hp}  />
                        <input type="hidden" name="goods_name" id="goods_name" value={goods_name} />
                        <input type="hidden" name="price" id="price" value={price}  />

                        <div className='pay_st_item'>
                            <div className='pay_totoal'>
                                <h3 className="label">결제금액 (원)</h3>
                                <p><strong>{price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong>원</p>
                            </div>
                        </div>

                        <div className='pay_st_item'>
                            <h3 className="label">주문자 정보</h3>
                            <div className='pay_st_list'>
                                <dl>
                                    <dt>고객성명</dt>
                                    <dd> 
                                        <input type="text" value={customer_name} disabled />
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>휴대폰번호</dt>
                                    <dd> 
                                        <input type="text" value={customer_hp} disabled />
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>상품명</dt>
                                    <dd> 
                                        <input type="text" value={goods_name} disabled />
                                    </dd>
                                </dl>
                               
                            </div>
                        </div>

                        <div className='pay_st_item'>
                            <h3 className="label">신용카드정보 입력</h3>
                            <div className='pay_st_list'>
                                <dl>
                                    <dt>카드번호 <span className="blue">＊</span></dt>
                                    <dd> 
                                        <input type="text" maxLength="16" name="card_num" id="card_num" value={card_num} onChange={on_card_num_handler} required />
                                        <select id="cardType" style={{ "width": "100px", "marginLeft": "5px" }} onChange={on_cardType_handler}>
                                            <option value="01">개인</option>
                                            <option value="02">법인</option>
                                        </select>
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>유효기간 (월)  <span className="blue">＊</span></dt>
                                    <dd> 
                                        <div className="in_box">
                                            <select name="month" id="month" className="wd100" onChange={on_month_handler} required>
                                                <option value="">MONTH</option>
                                                <option value="01">01</option>
                                                <option value="02">02</option>
                                                <option value="03">03</option>
                                                <option value="04">04</option>
                                                <option value="05">05</option>
                                                <option value="06">06</option>
                                                <option value="07">07</option>
                                                <option value="08">08</option>
                                                <option value="09">09</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                            </select>
                                        </div>
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>유효기간 (년도) <span className="blue">＊</span></dt>
                                    <dd> 
                                        <div className="in_box">
                                            <select name="year" id="year" className="wd100" onChange={on_year_handler} required>
                                                <option value="">YEAR</option>
                                                <option value="2022">2022</option>
                                                <option value="2023">2023</option>
                                                <option value="2024">2024</option>
                                                <option value="2025">2025</option>
                                                <option value="2026">2026</option>
                                                <option value="2027">2027</option>
                                                <option value="2028">2028</option>
                                                <option value="2029">2029</option>
                                                <option value="2030">2030</option>
                                                <option value="2031">2031</option>
                                                <option value="2032">2032</option>
                                                <option value="2033">2033</option>
                                                <option value="2034">2034</option>
                                                <option value="2035">2035</option>
                                                <option value="2036">2036</option>
                                                <option value="2037">2037</option>
                                                <option value="2038">2038</option>
                                                <option value="2039">2039</option>
                                                <option value="2040">2040</option>
                                                <option value="2041">2041</option>
                                                <option value="2042">2042</option>
                                                <option value="2043">2043</option>
                                                <option value="2044">2044</option>
                                                <option value="2045">2045</option>
                                                <option value="2046">2046</option>
                                                <option value="2047">2047</option>
                                                <option value="2048">2048</option>
                                                <option value="2049">2049</option>
                                                <option value="2050">2050</option>
                                                <option value="2051">2051</option>
                                                <option value="2052">2052</option>
                                            </select>
                                        </div>
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>할부기간<span className="blue">＊</span> (5만원이상 할부 가능) </dt>
                                    <dd> 
                                        <div className="in_box">
                                            <select name="installment" id="installment" className="wd100" onChange={on_installment_handler} required>  
                                                <option value="00">일시불</option>
                                                <option value="02">02</option>
                                                <option value="03">03</option>
                                                <option value="04">04</option>
                                                <option value="05">05</option>
                                                <option value="06">06</option>
                                                <option value="07">07</option>
                                                <option value="08">08</option>
                                                <option value="09">09</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                            </select>
                                        </div>
                                    </dd>
                                </dl>
                                {
                                    charging_out(charging_type)
                                }
                                <dl>
                                    <dt>과금유형<span className="blue">＊</span></dt>
                                    <dd> 
                                        <div className="radio_wrap">
                                            <div className="radio_box" style={{display:'none'}}>
                                                <input type="radio" name="charging_type" id="general" value="13" checked={charging_type==='13'} onChange={on_charging_type_handler} />
                                                <label htmlFor="general">수기일반</label>
                                            </div>
                                            <div className="radio_box">
                                                <input type="radio" name="charging_type" id="non-certification" value="18" checked={charging_type==='18'} onChange={on_charging_type_handler} />
                                                <label htmlFor="non-certification">수기비인증</label>
                                            </div>
                                        </div>
                                    </dd>
                                </dl>
                            </div>
                        </div>

                        <div className="mo_page_btn">
                            {/* <button className="btn_st_03" onClick="location.href='../_pay/complete_mo.php' ">결제실행</button> */}
                            <button className="btn_st_03" >결제실행</button>
                        </div>
                    </div>
                </form>
            </div>
        </>

    )
}
export default StoreManagementPayFormStep2