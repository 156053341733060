import * as axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import Header from "../../../include/StoreHeader";
import Footer from "../../../include/SiteAdminFooter";
import { DoublePayChk, GetIp } from "../../../include/ConfigSet";
import OpenLogComment from '../../../include/OpenLogComment';

axios.defaults.withCredentials = true;

function StorePayPayEasyPaymentList() {

    //운영로그 상태
    const [log_set, set_log_set] = useState(false);
    const [log_set_msg, set_log_set_msg] = useState('');
    const [pageReload, set_pageReload] = useState('');
    const [cardType, set_cardType] = useState("01");
    const [corp_class, set_corp_class] = useState("");          // 사업자 구분

    const navigate = useNavigate();

    // 고객성명
    const [user_name, set_user_name] = useState("");

    const on_user_name_handler = (event) => {
        set_user_name(event.currentTarget.value)
    }

    // 휴대폰번호
    const [user_hp, set_user_hp] = useState("");

    const on_user_hp_handler = (event) => {
        set_user_hp(event.currentTarget.value)
    }

    // 고객이메일
    const [user_email, set_user_email] = useState("");

    const on_user_email_handler = (event) => {
        set_user_email(event.currentTarget.value)
    }

    // 상품명
    const [goods_name, set_goods_name] = useState("");

    const on_goods_name_handler = (event) => {
        set_goods_name(event.currentTarget.value)
    }


    // 결제금액
    const [price, set_price] = useState("");

    const on_price_handler = (event) => {
        set_price(event.currentTarget.value)
    }


    // 카드번호
    const [card_num, set_card_num] = useState("");

    const on_card_num_handler = (event) => {
        set_card_num(event.currentTarget.value)
    }
    const on_cardType_handler = (event) => {
        set_cardType(event.currentTarget.value)
    }

    // 유효기간 월
    const [month, set_month] = useState("");

    const on_month_handler = (event) => {
        set_month(event.currentTarget.value)
    }


    // 유효기간 년도
    const [year, set_year] = useState("");

    const on_year_handler = (event) => {
        set_year(event.currentTarget.value)
    }


    // 할부기간
    const [installment, set_installment] = useState("00");

    const on_installment_handler = (event) => {
        set_installment(event.currentTarget.value)
    }


    // 생년월일
    const [birth, set_birth] = useState("");

    const on_birth_handler = (event) => {
        set_birth(event.currentTarget.value)
    }


    // 비밀번호
    const [pws, set_pws] = useState("");

    const on_pws_handler = (event) => {
        set_pws(event.currentTarget.value)
    }

    // 인증방식
    const [charging_type, set_charging_type] = useState("");

    const on_charging_type_handler = (event) => {
        set_charging_type(event.currentTarget.value)
    }

    // 주문번호
    const [order_no, set_order_no] = useState("");

    // 로그인 아이디
    const [regid, set_regid] = useState("");

    const [userIp, set_userIp] = useState("");
    const [pg, set_pg] = useState('');
    const [pg_mid, set_pg_mid] = useState('');
    const [pg_apiKey, set_pg_apiKey] = useState('');
    const [pg_ivValue, set_pg_ivValue] = useState('');


    useEffect(() => {
        if (log_set === true) {
            set_log_set(false);
        }
    }, [log_set])

    useEffect(() => {
        getOrderNo();

        GetIp().then((ipInfo) => {
            set_userIp(ipInfo.IPv4);
        });

    }, []);

    function getOrderNo() {

        axios.post('/api/store/pay/getOrderNo').then(function (res) {
            if (res.data) {
                set_order_no(res.data);
            }

            const loginId = sessionStorage.getItem("loginId");

            if (loginId === null) {
                navigate('/Store/');
                return;
            }

            let bodyPage = {
                loginId: loginId
            }

            axios.post('/api/store/userInfo', bodyPage).then(function (res) {

                if (res.status === 401 || res.data.length === 0) {
                    alert("토큰이 만료 되었습니다. 다시 로그인해주세요.");
                    navigate('/Store/');
                    return;
                }

                set_regid(loginId);
                set_pg(res.data[0].pg);
                set_pg_mid(res.data[0].pg_mid);
                set_pg_apiKey(res.data[0].pg_apiKey);
                set_pg_ivValue(res.data[0].pg_ivValue);
                set_corp_class(res.data[0].corp_class);

                let body3 = {
                    user_id: loginId
                }

                axios.post('/api/store/pay/getPgInfo', body3).then(function (res) {
                    if (res.data[0].cerify === "Y") {
                        set_charging_type("13");
                    } else {
                        set_charging_type("18");

                    }
                }, [])


            });





        }, [])
    }

    function charging_out(charging_type) {
        if (charging_type === "13") {
            return (
                <>
                    <tr>
                        <th>생년월일 6자리 <span className="red">＊</span></th>
                        <td>
                            <div className="flex">
                                <div className="in_box mr_10">
                                    <input type="text" maxLength="6" name="birth" id="birth" value={birth} onChange={on_birth_handler} required />
                                </div>
                                <p> (예 : 880704)</p>
                            </div>

                        </td>
                        <th>비밀번호 <span className="red">＊</span></th>
                        <td>
                            <div className="flex">
                                <div className="in_box mr_10">
                                    <input type="password" maxLength="10" name="pws" id="pws" value={pws} onChange={on_pws_handler} required />
                                </div>
                                <p>**</p>
                            </div>
                        </td>
                    </tr>

                </>
            )

        }

    }

    const on_pay_handler = (event) => {
        event.preventDefault();

        if (regid === "") {
            alert("필수값 누락! 로그인이 필요합니다.");
            return false;
        }


        const today = new Date();
        const nYear = today.getFullYear();
        const nMonth = ('0' + (today.getMonth() + 1)).slice(-2); // 1월이 0부터 시작하므로 +1을 해줌
        const nDay = ('0' + today.getDate()).slice(-2);

        const dateYmd = `${nYear}${nMonth}${nDay}`;


        DoublePayChk(dateYmd, card_num, regid, price).then((result) => {

            if (result === 'E1') {
                alert("당일 한도 금액이 초과 되었습니다.");
                return;
            }
            if (result === 'E2') {
                alert("이번달 한도 금액이 초과 되었습니다.");
                return;
            }
            if (result === 'E3') {
                alert("금일 해당카드로 결제내역이 있습니다.");
                return;
            }
            // if(result === "T"){
            //     alert("금일 해당카드로 결제내역이 있습니다.");
            // }else{

            let body = {
                user_name: user_name,
                user_hp: user_hp,
                user_email: user_email,
                goods_name: goods_name,
                price: price,
                card_num: card_num,
                month: month,
                year: year,
                installment: installment,
                birth: birth,
                pws: pws,
                charging_type: charging_type,
                order_no: order_no,
                regid: regid
            }

            axios.post('/api/store/pay/payAdd', body).then(function (response) {
                if (response.data === "AddOk") {
                    let body = {
                        order_no: order_no
                    }

                    if (!pg) {
                        alert("등록된 pg가 없습니다.");
                        return;
                    } else if (!pg_mid) {
                        alert("등록된 mid 정보가 없습니다.");
                        return;
                    }
                 
                    if (pg.includes('welcomepay')) {
                        //PG 월컴페이먼츠
                        welcomePay_send(body);
                    } else if (pg.includes('wonsung')) {
                        //PG 원성글로벌
                        wonsungPay_send(body);
                    }


                    // axios.post('/payment/payjoa.php', body,
                    // { withCredentials: true }
                    // ).then(function(res2){
                    //     //console.log(res2);
                    //     if(res2.data.RESULTCODE === "0000"){
                    //         alert(res2.data.ERRORMESSAGE);

                    //         set_log_set_msg('결제실행');
                    //         set_log_set(true);

                    //     }else{
                    //         if(res2.data.RESULTCODE !== null){
                    //             getOrderNo();
                    //             alert("결제 실패! 사유 : " + res2.data.ERRORMESSAGE + "\n코드 : " + res2.data.RESULTCODE);

                    //             set_log_set_msg('결제실패 : ' + res2.data.ERRORMESSAGE);
                    //             set_log_set(true);

                    //         }else{
                    //             getOrderNo();
                    //             alert("결제에 실패했습니다.");

                    //         }
                    //     }

                    // })


                } else if (response.data === "Err1") {
                    alert("이미 등록된 주문번호입니다.");
                    return false;

                } else {
                    alert("시스템 에러");

                }

                console.log(response.data);

            }, [])

            // }
        })

    }


    /* ***************************
     * PG 웰컴페이먼츠
     * @param {*} body 
     * ***************************/
    const welcomePay_send = (body) => {

        let param = {
            pg: pg,
            pg_mid: pg_mid,
            pg_apiKey: pg_apiKey,
            pg_ivValue: pg_ivValue,
            regid: regid,
            pay_type: 'CREDIT_CARD',
            pay_method: 'CREDIT_UNAUTH_API',
            card_no: card_num,
            card_expiry_ym: year.substr(2, 2) + month,
            order_no: body.order_no,
            user_name: user_name,
            amount: price,
            product_name: goods_name,
            card_sell_mm: installment,
            millis: Date.now(),
            userIp: userIp,
            paymenttype: 1
        }

        axios.post('/api/welcomePay/welcomePaySend', param).then(function (res) {
            if (res.data) {

                //console.log('res : ', res);

                if (res.data.result_code === '0000') {
                    alert(res.data.result_message);
                    window.location.reload();
                } else {
                    alert(res.data.result_message);
                    getOrderNo();
                    alert("결제 실패! 사유 : " + res.data.result_message);
                    set_log_set_msg('결제실패 : ' + res.data.ERRORMESSAGE);
                    set_log_set(true);
                }
            } else {
                getOrderNo();
                alert("결제에 실패했습니다.");
            }
        }, {
            headers: { 'Content-Type': 'application/json' }
        })

    }


    /* ***************************
     * PG 원성글로벌
     * @param {*} body 
     * ***************************/
    const wonsungPay_send = (body) => {

        let param = {
            pg: pg,
            pg_mid: pg_mid,
            pg_apiKey: pg_apiKey,
            pg_ivValue: pg_ivValue,
            regid: regid,
            card_no: card_num,
            card_expiry_ym: year.substr(2, 2) + month,
            order_no: body.order_no,
            user_name: user_name,
            user_hp: user_hp,
            amount: price,
            product_name: goods_name,
            card_sell_mm: installment,
            millis: Date.now(),
            corp_class: corp_class,
            cardType: cardType,
            userIp: userIp,
            paymenttype: 1
        }

        axios.post('/api/wonsungPay/wonsungPaySend', param).then(function (res) {

            console.log('res : ', res);

            if (res.data) {                
                if (res.data.result_code === '0000') {
                    alert(res.data.result_message);
                    window.location.reload();
                } else {
                    alert(res.data.result_message);
                    getOrderNo();
                    alert("결제 실패! 사유 : " + res.data.result_message);
                    set_log_set_msg('결제실패 : ' + res.data.ERRORMESSAGE);
                    set_log_set(true);
                }
            } else {
                getOrderNo();
                alert("결제에 실패했습니다.");
            }

        }, {
            headers: { 'Content-Type': 'application/json' }
        });
    }

    return (
        <>

            {(log_set === true) && (<OpenLogComment pageMode={`간편결제 > ${log_set_msg}`} pageReload={pageReload} />)} {/* 운영로그 기록 */}

            <div id="wrap">
                <Header />


                <div id="container" data-menu-page="easy_pay">
                    <h3 className="page_ttl">간편결제</h3>

                    <form onSubmit={on_pay_handler}>
                        <div className="form_item">
                            <h3 className="label">* 주문자 정보</h3>

                            <div className="top_table">
                                <input type="hidden" name="order_no" id="order_no" value={order_no} />
                                <input type="hidden" name="regid" id="regid" value={regid} />

                                <table>
                                    <colgroup>
                                        <col width="200px" />
                                        <col width="*" />
                                        <col width="200px" />
                                        <col width="*" />
                                    </colgroup>

                                    <tbody>
                                        <tr>
                                            <th>고객성명 <span className="red">＊</span></th>
                                            <td>
                                                <div className="in_box">
                                                    <input type="text" name="user_name" id="user_name" value={user_name} onChange={on_user_name_handler} required />
                                                </div>
                                            </td>
                                            <th>휴대폰번호 <span className="red">＊</span></th>
                                            <td>
                                                <div className="in_box">
                                                    <input type="text" name="user_hp" id="user_hp" value={user_hp} onChange={on_user_hp_handler} required />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>이메일 <span className="red">＊</span></th>
                                            <td>
                                                <div className="in_box">
                                                    <input type="text" name="user_email" id="user_email" value={user_email} onChange={on_user_email_handler} required />
                                                </div>
                                            </td>
                                            <th>상품명 <span className="red">＊</span></th>
                                            <td>
                                                <div className="in_box">
                                                    <input type="text" name="goods_name" id="goods_name" value={goods_name} onChange={on_goods_name_handler} required />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                        <div className="form_item">
                            <h3 className="label">* 결제금액</h3>

                            <div className="top_table">
                                <table>
                                    <colgroup>
                                        <col width="200px" />
                                        <col width="*" />
                                    </colgroup>

                                    <tbody>
                                        <tr>
                                            <th>결제금액 (원) <span className="red">＊</span></th>
                                            <td>
                                                <div className="in_box">
                                                    <input type="text" name="price" id="price" value={price} onChange={on_price_handler} required />
                                                </div>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>

                            </div>

                        </div>
                        <div className="form_item">
                            <h3 className="label">* 신용카드정보 입력</h3>

                            <div className="top_table">
                                <table>
                                    <colgroup>
                                        <col width="200px" />
                                        <col width="*" />
                                        <col width="200px" />
                                        <col width="*" />
                                    </colgroup>

                                    <tbody>
                                        <tr>
                                            <th>카드번호 <span className="red">＊</span></th>
                                            <td>

                                                <div className="flex">
                                                    <div className="in_box">
                                                        <input type="text" maxLength="16" name="card_num" id="card_num" value={card_num} onChange={on_card_num_handler} required />
                                                    </div>

                                                    <select id="cardType" style={{ "width": "100px", "marginLeft": "5px" }} onChange={on_cardType_handler}>
                                                        <option value="01">개인</option>
                                                        <option value="02">법인</option>
                                                    </select>

                                                </div>


                                            </td>
                                            <th>유효기간<span className="red">＊</span></th>
                                            <td>
                                                <div className='flex table_cont_1'>
                                                    <div className="sel_box">
                                                        <select name="month" id="month" className="wd100" onChange={on_month_handler} required>
                                                            <option value="">MONTH</option>
                                                            <option value="01">01</option>
                                                            <option value="02">02</option>
                                                            <option value="03">03</option>
                                                            <option value="04">04</option>
                                                            <option value="05">05</option>
                                                            <option value="06">06</option>
                                                            <option value="07">07</option>
                                                            <option value="08">08</option>
                                                            <option value="09">09</option>
                                                            <option value="10">10</option>
                                                            <option value="11">11</option>
                                                            <option value="12">12</option>
                                                        </select>
                                                    </div>
                                                    <div className="sel_box">
                                                        <select name="year" id="year" className="wd100" onChange={on_year_handler} required>
                                                            <option value="">YEAR</option>
                                                            <option value="2022">2022</option>
                                                            <option value="2023">2023</option>
                                                            <option value="2024">2024</option>
                                                            <option value="2025">2025</option>
                                                            <option value="2026">2026</option>
                                                            <option value="2027">2027</option>
                                                            <option value="2028">2028</option>
                                                            <option value="2029">2029</option>
                                                            <option value="2030">2030</option>
                                                            <option value="2031">2031</option>
                                                            <option value="2032">2032</option>
                                                            <option value="2033">2033</option>
                                                            <option value="2034">2034</option>
                                                            <option value="2035">2035</option>
                                                            <option value="2036">2036</option>
                                                            <option value="2037">2037</option>
                                                            <option value="2038">2038</option>
                                                            <option value="2039">2039</option>
                                                            <option value="2040">2040</option>
                                                            <option value="2041">2041</option>
                                                            <option value="2042">2042</option>
                                                            <option value="2043">2043</option>
                                                            <option value="2044">2044</option>
                                                            <option value="2045">2045</option>
                                                            <option value="2046">2046</option>
                                                            <option value="2047">2047</option>
                                                            <option value="2048">2048</option>
                                                            <option value="2049">2049</option>
                                                            <option value="2050">2050</option>
                                                            <option value="2051">2051</option>
                                                            <option value="2052">2052</option>
                                                        </select>
                                                    </div>
                                                </div>

                                            </td>
                                        </tr>
                                        <tr>
                                            <th>할부기간<span className="red">＊</span><br /> (5만원이상 할부 가능) </th>
                                            <td>
                                                <div className="in_box">
                                                    <select name="installment" id="installment" className="wd100" onChange={on_installment_handler} required>
                                                        <option value="00">일시불</option>
                                                        <option value="02">02</option>
                                                        <option value="03">03</option>
                                                        <option value="04">04</option>
                                                        <option value="05">05</option>
                                                        <option value="06">06</option>
                                                        <option value="07">07</option>
                                                        <option value="08">08</option>
                                                        <option value="09">09</option>
                                                        <option value="10">10</option>
                                                        <option value="11">11</option>
                                                        <option value="12">12</option></select>
                                                </div>
                                            </td>
                                            <th>인증방식 <span className="red">＊</span></th>
                                            <td>
                                                <div className="radio_wrap">
                                                    <div className="radio_box" style={{ "display": "none" }}>
                                                        <input type="radio" name="charging_type" id="general" value="13" checked={charging_type === '13'} onChange={on_charging_type_handler} />
                                                        <label htmlFor="general">수기일반</label>
                                                    </div>
                                                    <div className="radio_box">
                                                        <input type="radio" name="charging_type" id="non-certification" value="18" checked={charging_type === '18'} onChange={on_charging_type_handler} />
                                                        <label htmlFor="non-certification">수기비인증</label>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>


                                        {
                                            charging_out(charging_type)
                                        }

                                    </tbody>
                                </table>

                            </div>
                        </div>

                        <div className="top_table_btn">
                            <button type="submit" className=" blue btn_st_01" >결제실행</button>

                        </div>
                    </form>
                </div>


            </div>

            <Footer />
        </>

    )
}
export default StorePayPayEasyPaymentList